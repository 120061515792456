import {Middleware, applyMiddleware, createStore} from 'redux';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import {routerMiddleware} from 'connected-react-router';
import {createBrowserHistory} from 'history';

import {rootSaga} from '../saga';
import {createRootReducerWithRouter} from '../reducers';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  createRootReducerWithRouter(history),
  process.env.NODE_ENV === 'development'
    ? applyMiddleware(logger as Middleware<any, any, any>, sagaMiddleware, routerMiddleware(history))
    : applyMiddleware(sagaMiddleware, routerMiddleware(history)),
);

sagaMiddleware.run(rootSaga);
