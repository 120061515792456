import {TEventObject} from './dataTypes';
import {apiURL, baseURL, fetchGet, TApiResult} from './baseapi';

export type TEventsResult = TApiResult<TEventObject[]>;

export async function getEvents(companySlug: string) {
  console.log('API, getEvents, request');

  const response: TEventsResult = await fetchGet({
    url: `${baseURL}${apiURL}companies/${companySlug}/events`,
  });

  if (response.error) {
    console.log('API, getEvents, error', response);
  } else {
    console.log('API, getEvents, response', response);
  }

  return response;
}

export async function getEventsOndemand(companySlug: string) {
  console.log('API, getEventsOndemand, request');

  const response: TEventsResult = await fetchGet({
    url: `${baseURL}${apiURL}companies/${companySlug}/events/ondemand`,
  });

  if (response.error) {
    console.log('API, getEventsOndemand, error', response);
  } else {
    console.log('API, getEventsOndemand, response', response);
  }

  return response;
}
