export const TermsOfService = () => (
  <>
    <h1>K&auml;ytt&ouml;ehdot</h1>

    <p>K&auml;ytt&auml;ess&auml;si UMO Helsinki -sovellusta hyv&auml;ksyt n&auml;m&auml; k&auml;ytt&ouml;ehdot.</p>
    <p>UMO-s&auml;&auml;ti&ouml; sr (myöhemmin UMO Helsinki) 2202299-9 myy tuotteita yksityishenkil&ouml;ille [Suomessa ja ulkomailla]. Pid&auml;t&auml;mme oikeuden muuttaa ehtoja ja hintoja. Kaikki hinnat sis&auml;lt&auml;v&auml;t arvonlis&auml;veron.</p>
    
    <h2>Ota yhteytt&auml;</h2>
    
    <p>s&auml;hk&ouml;posti: <a href="mailto:jazz@umohelsinki.fi">jazz@umohelsinki.fi</a><br />
    osoite: Tallberginkatu 1 / 139, 00180 Helsinki</p>
    
    <h2>Ik&auml;raja</h2>
    
    <p>Alkoholituotteiden tilaaminen UMO Helsinki -sovelluksessa on sallittua vain henkil&ouml;ille, jotka ovat yli 18-vuotiaita ja t&auml;ytt&auml;v&auml;t muiltakin osin alkoholilain vaatimukset.</p>

    <h2>Tilaukset</h2>
    
    <p>K&auml;ytt&auml;j&auml; valitsee tilauksen sis&auml;ll&ouml;n ostoskoriin ja tekee tilauksen UMO Helsinki -sovelluksessa. Tilaus vahvistetaan k&auml;ytt&auml;m&auml;ll&auml; UMO Helsinki -sovelluksen ostotoimintoa ostoksen maksamiseen. K&auml;ytt&auml;j&auml; hyv&auml;ksyy n&auml;m&auml; ehdot ja tuotteen hinnoittelun tekem&auml;ll&auml; tilauksen.</p>
    
    <h2>Tilauksen vahvistus ja kuitti</h2>
    
    <p>Tilauksen ja maksun j&auml;lkeen kaikki kuitit ovat saatavilla UMO Helsinki -sovelluksen ostohistoriassa. Kuiteissa luetellaan tilatut tuotteet ja hintojen erittely. P&auml;&auml;sylipputilauksen yhteydess&auml; l&auml;hetet&auml;&auml;n s&auml;hk&ouml;postivahvistus. S&auml;hk&ouml;postivahvistus sis&auml;lt&auml;&auml; ostetun lipun ja tilauksen kuitin.</p>
    
    <h2>Tilauksen maksaminen, peruuttaminen ja hyvitt&auml;minen</h2>
    
    <p>Tilauksen maksaminen suoritetaan UMO Helsinki -sovelluksessa. Tilaus veloitetaan automaattisesti sovelluksen k&auml;ytt&auml;j&auml;lt&auml; heti, kun tilaus on tehty. Tilauksen tekemisen j&auml;lkeen k&auml;ytt&auml;j&auml; ei voi peruuttaa tilausta. Tilauksen tekemisen yhteydess&auml; UMO Helsinki pid&auml;tt&auml;&auml; oikeuden tehd&auml; maksuj&auml;rjestely k&auml;ytt&auml;j&auml;n kanssa. Jos korttimaksu ep&auml;onnistuu, k&auml;ytt&auml;j&auml;lle voidaan l&auml;hett&auml;&auml; lasku tilauksesta. Jos toimitus tai toimituksen osa ep&auml;onnistuu, joko tuotteen loppuessa varastosta tai muusta,  UMO Helsinki:sta riippuvasta syyst&auml;, UMO Helsinki on vastuussa hyvityksen maksamisesta n&auml;iltä osin k&auml;ytt&auml;j&auml;lle. Hyvitys maksetaan yleens&auml; 1-3 viikon kuluessa.</p>
    <p>UMO Helsinki ei tallenna tai k&auml;sittele omassa j&auml;rjestelm&auml;ss&auml;&auml;n sellaisia &#8203;&#8203;maksukorttitietoja, joita voitaisiin k&auml;ytt&auml;&auml; luvattomien maksujen tekemiseen. Kaikki maksut k&auml;sitell&auml;&auml;n PCI-sertifioidun maksukumppanin palvelussa. Katso maksuehdot ja tiedot &ldquo; Maksaminen&rdquo;-kappaleista, n&auml;iden ehtojen lopussa.</p>
    
    <h2>Asiakastietojen tallentaminen</h2>
    
    <p>Kaikki asiakastiedot k&auml;sitell&auml;&auml;n GDPR-vaatimusten mukaisesti. Tietojen k&auml;sittely- ja suojausperiaatteet on kuvattu tietosuojak&auml;yt&auml;nn&ouml;ss&auml;.</p>
    
    <h2>Tilauksen kokoaminen, toimittaminen ja vastaanottaminen</h2>
    
    <p>Tilaus toimitetaan k&auml;ytt&auml;j&auml;n valitsemaan toimituspisteeseen (esim. P&ouml;yt&auml;, istumapaikka tai muu m&auml;&auml;r&auml;tty paikka). UMO Helsinki on velvollinen s&auml;ilytt&auml;m&auml;&auml;n UMO Helsinki -sovelluksen kautta suoritetut toimittamattomat tilaukset 30 minuutin ajan ensimm&auml;isen toimitusyrityksen j&auml;lkeen.</p>
    
    <h2>Tapahtuman peruutus</h2>
    
    <p>Jos tapahtuma peruutetaan, k&auml;ytt&auml;j&auml; voi pyyt&auml;&auml; p&auml;&auml;sylipun ostamiseen k&auml;ytettyjen rahojen korvaamista k&auml;ytetyn maksutavan mukaan.</p>
    
    <h2>Valitukset</h2>
    
    <p>UMO Helsinki ja sen kumppanit ovat sitoutuneet toimittamaan tuotteet ja palvelut tilauksen mukaisesti. Jos tilauksessa tai huollossa ilmenee virheit&auml;, pyyd&auml;mme ottamaan yhteytt&auml; henkil&ouml;kuntaamme.</p>

    <h2>K&auml;ytt&auml;j&auml;n poistaminen palvelusta</h2>
    
    <p>Jos k&auml;ytt&auml;j&auml; k&auml;ytt&auml;&auml; v&auml;&auml;rin UMO Helsinki -sovellusta tai aiheuttaa haittaa UMO Helsinki -sovelluksen k&auml;ytt&ouml;&ouml;n tai k&auml;ytt&auml;jille tai UMO Helsinki -sovelluksen kumppaneille, UMO Helsinki -omistaja pid&auml;tt&auml;&auml; oikeuden poistaa k&auml;ytt&auml;j&auml; UMO Helsinki -sovelluksesta v&auml;litt&ouml;m&auml;sti.</p>
    
    <h2>Palveluehtojen muutokset</h2>
    
    <p>UMO Helsinki -sovelluksen k&auml;ytt&ouml;ehtoihin voidaan soveltaa muutoksia. UMO Helsinki -sovelluksen k&auml;ytt&auml;j&auml;n tulee lopettaa UMO Helsinki -sovelluksen k&auml;ytt&ouml;, jos h&auml;n ei hyv&auml;ksy muutettuja k&auml;ytt&ouml;ehtoja.</p>
    
    <h2>Maksaminen</h2>
    
    <p>UMO Helsinki -sovelluksen maksuv&auml;litt&auml;j&auml;n&auml; toimii Visma Pay (Paybyway Oy, y-tunnus 2486559-4), joka on rekister&ouml;ity Finanssivalvonnan yll&auml;pit&auml;m&auml;&auml;n maksulaitosrekisteriin. Maksamiseen siirryt&auml;&auml;n Visma Payn UMO Helsinki -sovelluksen kautta ja tiliotteella ja laskulla maksun saajana n&auml;kyy Visma Pay tai Paybyway Oy. Visma Pay v&auml;litt&auml;&auml; maksut verkkokauppiaalle. Maksaminen on turvallista, sill&auml; kaikki maksutapahtumaa koskevat tiedot v&auml;litet&auml;&auml;n salattua yhteytt&auml; k&auml;ytt&auml;en niin ettei kukaan ulkopuolinen taho n&auml;e maksutapahtuman tietoja.</p>
    
    <p>Kauppa syntyy UMO Helsinki -sovelluksen asiakkaan ja verkkokaupan v&auml;lille. UMO Helsinki:n vastuulla ovat kaikki kauppaan liittyv&auml;t velvoitteet.</p>
    
    <p>Lue lis&auml;&auml; Visma Paysta: https://www.visma.fi/vismapay/</p>
    
    <h3>Maksutavat</h3>
    
    <p>Visma Pay -palvelun kautta voit maksaa verkkopankkitunnuksilla, lompakolla, maksukorteilla (credit/debit), laskulla tai osamaksulla. UMO Helsinki -sovelluksen asiakkaan k&auml;ytett&auml;viss&auml; ovat seuraavat maksutavat: Visa-, Visa Debit-, Visa Electron-, MasterCard- ja Debit MasterCard -kortit.</p>
    
    <h3>Visma Pay -maksupalvelun yhteystiedot</h3>
    
    <p>Visma Pay, Paybyway Oy (Y-tunnus 2486559-4)<br />
    S&auml;hk&ouml;posti: helpdesk@vismapay.com<br />
    Puhelin: 09 315 42 037 (arkisin klo 8-16)<br />
    Postiosoite: Laserkatu 6, 53850 Lappeenranta</p>
  </>
);
