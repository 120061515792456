import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Button, Image, Radio} from 'semantic-ui-react';
import {
  enduserPaymentSettingsPay,
  enduserPaymentSettingsSetDefaultCard,
  TPaymentMethodSelection,
} from '../actions/payment';
import { vismaLogo } from '../configs';
import {t} from '../i18n';
import {useLoggedIn} from '../redux/reduxHooks';
import {useTypedSelector} from '../redux/reduxUtil';
import {PageWithLoginComplaint} from './LoginRequiredComplaint';
import {Topbar} from './Topbar';

export function PaymentSettingsPage() {
  const dispatch = useDispatch();
  const {receiptInput, userProfile} = useTypedSelector((state) => ({
    receiptInput: state.payment.receiptInput,
    userProfile: state.auth.userProfile,
  }));
  const loggedIn = useLoggedIn();

  const [paymentMethodSelection, setPaymentMethodSelection] =
    useState<TPaymentMethodSelection | undefined>(undefined);

  const cards = (userProfile && userProfile.cards) || [];

  let effectivePaymentMethodSelection: TPaymentMethodSelection = 'newCard';

  if (cards.length > 0) {
    if (paymentMethodSelection === undefined) {
      effectivePaymentMethodSelection = 'existingCard';
    }
    if (paymentMethodSelection === 'existingCard') {
      effectivePaymentMethodSelection = 'existingCard';
    }
  }

  const onPayButton = () => {
    if (receiptInput) {
      dispatch(
        enduserPaymentSettingsPay({
          paymentMethod: effectivePaymentMethodSelection,
          receiptInput: receiptInput,
        }),
      );
    }
  };

  if (!loggedIn) {
    return <PageWithLoginComplaint />;
  }

  return (
    <div className="PaymentSettingsPage">
      <Topbar />
      <p className="PaymentSettingsPageCardsTitle PaymentSettingsPageContainer">
        {t('PAYMENT_SETTINGS_PAGE.CARDS_LIST_TITLE')}
      </p>
      <div className="PaymentSettingsPageMainScroll PaymentSettingsPageContainer">
        <div className="PaymentSettingsPageMainScrollContent">
          <div className="PaymentSettingsPageCardsContainer">
            {cards.map((card, cardIndex) => {
              return (
                <div key={cardIndex} className="PaymentSettingsPageCard">
                  <div className="PaymentSettingsPageCardRadioContainer">
                    <Radio
                      onChange={() => {
                        dispatch(
                          enduserPaymentSettingsSetDefaultCard({
                            cardIndex: cardIndex,
                          }),
                        );
                        setPaymentMethodSelection('existingCard');
                      }}
                      checked={
                        card.isDefault === true &&
                        effectivePaymentMethodSelection === 'existingCard'
                      }
                    />
                  </div>
                  <div className="PaymentSettingsPageCardLeft">
                    <p className="PaymentSettingsPageCardAnyLabel">
                      {card.type}
                    </p>
                    <p className="PaymentSettingsPageCardAnyLabel">
                      {`**** **** **** ${card.partialPan}`}
                    </p>
                  </div>
                  <div className="PaymentSettingsPageCardRight">
                    <p className="PaymentSettingsPageCardAnyLabel">{`${card.expireMonth}/${card.expireYear}`}</p>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="PaymentSettingsPageDivider" />

          <div className="PaymentSettingsPageCardsContainer">
            <div className="PaymentSettingsPageCard">
              <div className="PaymentSettingsPageCardRadioContainer">
                <Radio
                  onChange={() => {
                    setPaymentMethodSelection('newCard');
                  }}
                  checked={effectivePaymentMethodSelection === 'newCard'}
                />
              </div>
              <div className="PaymentSettingsPageCardLeft">
                <p className="PaymentSettingsPageCardAnyLabel">
                  {t('PAYMENT_SETTINGS_PAGE.NEW_CARD_BUTTON_LABEL')}
                </p>
              </div>
            </div>
          </div>

          <div className="PaymentSettingsPageDivider" />
        </div>
      </div>
      <div className="PaymentSettingsPageBottom PaymentSettingsPageContainer">
        <Button inverted primary fluid onClick={onPayButton}>
          {t('PAYMENT_SETTINGS_PAGE.BUTTON_PAY')}
        </Button>
        <Image className="VismaLogo" src={vismaLogo} />
      </div>
    </div>
  );
}
