import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Input, Button, Form, Grid} from 'semantic-ui-react';
import {
  enduserRegisterInputCode,
  enduserRegisterResendCode,
} from '../actions/auth';
import {t} from '../i18n';
import {Topbar} from './Topbar';

export function RegisterCodeInputPage() {
  const dispatch = useDispatch();

  const [code, setCode] = useState('');

  return (
    <div className="AuthPageVerticalRoot">
      <Topbar />

      <div className="CenterContainer CenterContainerLoginForm">
        <Grid columns={1}>
          <Grid.Row>
            <Grid.Column>
              <p>{t('PAGE_REGISTER_CODE_INPUT.MAIN_MESSAGE')}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form>
                <Form.Field>
                  <Input
                    value={code}
                    placeholder={t('PAGE_REGISTER_CODE_INPUT.FIELD_CODE_HINT')}
                    onChange={(changeEvent) => {
                      setCode(changeEvent.target.value);
                    }}
                  />
                </Form.Field>
                <Button
                  inverted
                  primary
                  onClick={() => {
                    dispatch(
                      enduserRegisterInputCode({
                        code: code,
                      }),
                    );
                  }}
                  fluid>
                  {t('PAGE_REGISTER_CODE_INPUT.BUTTON_CONTINUE')}
                </Button>
              </Form>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row></Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Button
                inverted
                primary
                onClick={() => {
                  dispatch(enduserRegisterResendCode({}));
                }}
                fluid>
                {t('PAGE_REGISTER_CODE_INPUT.BUTTON_RESEND_CODE')}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
}
