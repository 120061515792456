import {
  rerenderRouter,
  setUsingMobileStyles,
  showHeavyLoadingScreen,
  showInitialLoadingScreen,
  showLightLoadingScreen,
} from '../actions/loading';
import {reducerBuilder} from '../redux/reduxUtil';

export const loading = reducerBuilder<{
  initialLoadingScreenShown: boolean;
  lightLoadingScreenShown: boolean;
  heavyLoadingScreenShown: boolean;
  routerRerenderCounter: number;
  usingMobileStyles: boolean;
}>({
  initialLoadingScreenShown: true,
  lightLoadingScreenShown: false,
  heavyLoadingScreenShown: false,
  routerRerenderCounter: 1,
  usingMobileStyles: false,
})
  .matchAction(showInitialLoadingScreen, (state, action) => {
    return {
      ...state,
      initialLoadingScreenShown: action.payload.show,
    };
  })
  .matchAction(showLightLoadingScreen, (state, action) => {
    return {
      ...state,
      lightLoadingScreenShown: action.payload.show,
    };
  })
  .matchAction(showHeavyLoadingScreen, (state, action) => {
    return {
      ...state,
      heavyLoadingScreenShown: action.payload.show,
    };
  })
  .matchAction(rerenderRouter, (state, action) => {
    return {
      ...state,
      routerRerenderCounter: state.routerRerenderCounter + 1,
    };
  })
  .matchAction(setUsingMobileStyles, (state, action) => {
    return {
      ...state,
      usingMobileStyles: action.payload.usingMobileStyles,
    };
  })
  .finish();
