import {push} from 'connected-react-router';
import {call, put, takeEvery} from 'typed-redux-saga';
import {
  enduserLogin,
  enduserRegister,
  enduserRegisterInputCode,
  enduserRegisterResendCode,
  setOngoingRegister,
} from '../actions/auth';
import {showLightLoadingScreen} from '../actions/loading';
import {alert} from '../alert';
import {TRegisterRequestObject} from '../api/dataTypes';
import {
  doRegister,
  doRegisterSendConfirmationCode,
  doResendRegisterCode,
} from '../api/login';
import {getCurrentLanguage, t} from '../i18n';
import {ActionOfActionCreator, typedSelect} from '../redux/reduxUtil';
import {onEnduserLogin} from './auth';
import {companyInfos} from '../configs';
import {routes} from '../routes';

export function* onEnduserRegister(
  action: ActionOfActionCreator<typeof enduserRegister>,
) {
  let success = false;

  let errorAlert = {
    title: t('ERROR_GENERIC.ERROR_TITLE_GENERIC'),
    message: t('PAGE_REGISTER.ERROR_REGISTER_REQUEST_FAILED'),
  };

  yield* put(showLightLoadingScreen({show: true}));

  try {
    const firstCompanySlug = companyInfos[0].slug;

    const registerRequest: TRegisterRequestObject = {
      firstName: action.payload.firstName,
      lastName: action.payload.lastName,
      email: action.payload.email,
      slug: firstCompanySlug,
      acceptedTerms: true,
      shortCode: true,
      language: getCurrentLanguage(),
      canSendVendorEmail: action.payload.canSendVendorEmail,
      canSendVendorSms: false,
    };

    const registerResult = yield* call(doRegister, registerRequest);

    yield* put(
      setOngoingRegister({
        email: action.payload.email,
        password: action.payload.password,
      }),
    );

    if (registerResult.error === 0) {
      success = true;
    } else if (registerResult.error !== 0 && registerResult.data.code === 703) {
      errorAlert = {
        title: t(
          'PAGE_REGISTER.ERROR_REGISTER_REQUEST_FAILED_EMAIL_ALREADY_USED_TITLE',
        ),
        message: t(
          'PAGE_REGISTER.ERROR_REGISTER_REQUEST_FAILED_EMAIL_ALREADY_USED_MESSAGE',
        ),
      };
    }
  } catch (_e) {}

  yield* put(showLightLoadingScreen({show: false}));

  if (success) {
    yield put(push(routes.registerCodeInput()));
  } else {
    alert(errorAlert);
  }
}

export function* onEnduserRegisterInputCode(
  action: ActionOfActionCreator<typeof enduserRegisterInputCode>,
) {
  const ongoingRegister = yield* typedSelect(
    (state) => state.auth.ongoingRegister,
  );

  if (ongoingRegister) {
    let success = false;

    yield* put(showLightLoadingScreen({show: true}));

    try {
      const registerResult = yield* call(
        doRegisterSendConfirmationCode,
        {
          email: ongoingRegister.email,
          password: ongoingRegister.password,
          password2: ongoingRegister.password,
        },
        action.payload.code,
      );

      if (registerResult.error === 0) {
        success = true;
      }
    } catch (_e) {}

    yield* put(showLightLoadingScreen({show: false}));

    if (success) {
      yield* call(
        onEnduserLogin,
        enduserLogin({
          username: ongoingRegister.email,
          password: ongoingRegister.password,
        }),
      );
    } else {
      alert({
        title: t('ERROR_GENERIC.ERROR_TITLE_GENERIC'),
        message: t('PAGE_REGISTER_CODE_INPUT.ERROR_CODE_INPUT_FAILED_MESSAGE'),
      });
    }
  }
}

export function* onEnduserRegisterResendCode() {
  const ongoingRegisterEmail = yield* typedSelect(
    (state) => state.auth.ongoingRegister?.email,
  );

  if (ongoingRegisterEmail) {
    yield* put(showLightLoadingScreen({show: true}));

    try {
      yield* call(doResendRegisterCode, {
        email: ongoingRegisterEmail,
        shortCode: true,
      });
    } catch (_e) {}

    yield* put(showLightLoadingScreen({show: false}));

    alert({
      title: t('PAGE_REGISTER_CODE_INPUT.ALERT_CODE_RESEND_DONE_TITLE'),
      message: t('PAGE_REGISTER_CODE_INPUT.ALERT_CODE_RESEND_DONE_MESSAGE'),
    });
  }
}

export function* watchRegister() {
  yield* takeEvery(enduserRegister.actionType, onEnduserRegister);
  yield* takeEvery(
    enduserRegisterInputCode.actionType,
    onEnduserRegisterInputCode,
  );
  yield* takeEvery(
    enduserRegisterResendCode.actionType,
    onEnduserRegisterResendCode,
  );
}
