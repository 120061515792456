export const ROUTE_BASE = process.env.PUBLIC_URL;

export const routes = {
  login: () => `${ROUTE_BASE}/login`,
  companySelection: () => `${ROUTE_BASE}/select_company`,
  loading: () => `${ROUTE_BASE}/loading`,
  watchStream: (streamId: string) => `${ROUTE_BASE}/watch_stream/${streamId}`,
  event: (eventId: string) => `${ROUTE_BASE}/event/${eventId}`,
  cart: () => `${ROUTE_BASE}/cart`,
  payment: () => `${ROUTE_BASE}/payment`,
  forgotPassword: () => `${ROUTE_BASE}/forgot_password`,
  forgotPasswordReset: () => `${ROUTE_BASE}/forgot_password_reset`,
  register: () => `${ROUTE_BASE}/register`,
  registerCodeInput: () => `${ROUTE_BASE}/register_code_input`,
  userProfileCards: () => `${ROUTE_BASE}/user_profile/cards`,
  userProfile: () => `${ROUTE_BASE}/user_profile`,
  tabHome: (subPage?: 'live' | 'ondemand') =>
    `${ROUTE_BASE}/events/${subPage ?? ''}`,
  tabTickets: () => `${ROUTE_BASE}/tickets`,
  root: () => `${ROUTE_BASE}/`,
  tabContact: () => `${ROUTE_BASE}/contact`,
  tabAbout: () => `${ROUTE_BASE}/about`,
  tabIntroduction: () => `${ROUTE_BASE}/introduction`,
  privacyPolicy: () => `${ROUTE_BASE}/privacy`,
  termsOfService: () => `${ROUTE_BASE}/terms`,
};

export const routeMatchers = {
  watchStream: `${ROUTE_BASE}/watch_stream/:event`,
  event: `${ROUTE_BASE}/event/:event`,
  tabHome: `${ROUTE_BASE}/events/:sublist`,
};
