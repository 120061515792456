import {apiURL, baseURL, fetchGet, TApiResult} from './baseapi';
import {
  TStreamOndemandPlaybackObject,
  TStreamPlaybackObject,
} from './dataTypes';

export type TStreamPlaybackUrlResult = TApiResult<TStreamPlaybackObject>;
export type TStreamOndemandPlaybackUrlsResult = TApiResult<
  TStreamOndemandPlaybackObject[]
>;

export async function getStreamPlaybackUrl(
  companySlug: string,
  authToken: string,
  streamId: string,
) {
  console.log('API, getStreamPlaybackUrl, request');

  const response: TStreamPlaybackUrlResult = await fetchGet({
    url: `${baseURL}${apiURL}companies/${companySlug}/streams/${streamId}`,
    token: authToken,
  });

  if (response.error) {
    console.log('API, getStreamPlaybackUrl, error', response);
  } else {
    console.log('API, getStreamPlaybackUrl, response', response);
  }

  return response;
}

export async function getStreamOndemandPlaybackUrls(
  companySlug: string,
  authToken: string,
  streamId: string,
) {
  console.log('API, getStreamOndemandPlaybackUrls, request');

  const response: TStreamOndemandPlaybackUrlsResult = await fetchGet({
    url: `${baseURL}${apiURL}companies/${companySlug}/streams/${streamId}/ondemand`,
    token: authToken,
  });

  if (response.error) {
    console.log('API, getStreamOndemandPlaybackUrls, error', response);
  } else {
    console.log('API, getStreamOndemandPlaybackUrls, response', response);
  }

  return response;
}
