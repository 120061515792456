import React from 'react';
import Moment from 'moment';
import {Grid} from 'semantic-ui-react';

import {TEventObject} from '../api/dataTypes';
import {
  getEventURL,
  getNameObject,
  isAnnouncementEvent,
  isGiftEvent,
} from '../util';
import {Link} from 'react-router-dom';
import {routes} from '../routes';
import {defaultEventImage} from '../configs';

export function EventElement(props: {event: TEventObject}) {
  const {event} = props;
  const usingBannerStyle = isGiftEvent(event._id) || isAnnouncementEvent(event);

  const eventImageUrl = getEventURL(event.urls);
  const usingDefaultImage = !Boolean(eventImageUrl);
  const imageUrl = usingDefaultImage ? defaultEventImage : eventImageUrl;
  const eventName = getNameObject(event.names);
  const eventDescription = getNameObject(event.descriptions);

  const dateDate = Moment(event.gigStartDate).format('D.M.');
  const dateWeekday = Moment(event.gigStartDate).format('ddd');
  const dateTime = Moment(event.gigStartDate).format('HH:mm');

  const eventNavpageUrl = routes.event(event._id);

  if (usingBannerStyle) {
    return (
      <Grid.Column width={16}>
        <Link className="EventElementBanner" to={eventNavpageUrl}>
          <div
            className={`EventElementBannerImage ${
              usingDefaultImage ? 'UsingDefaultImage' : ''
            }`}
          />
          <div className="EventElementBannerTextContainer">
            <p className="EventElementBannerText">{eventName}</p>
          </div>
        </Link>
      </Grid.Column>
    );
  } else {
    return (
      <Grid.Column
        mobile={16}
        tablet={8}
        computer={8}
        largeScreen={8}
        widescreen={8}
      >
        <Link className="EventListElement" to={eventNavpageUrl}>
          <div
            className={
              usingDefaultImage
                ? 'EventElementBannerBorderImageContainer'
                : 'EventElementBannerImageContainer'
            }>
            <img className="EventElementMainImage" src={imageUrl} alt="" />
          </div>
          <div className="EventElementBottomHorizontal">
            <div className="EventElementBottomLeft">
              <p className="EventElementBottomLeftDate">{dateDate}</p>
              <p className="EventElementBottomLeftWeekday">{dateWeekday}</p>
              <p className="EventElementBottomLeftHour">{dateTime}</p>
            </div>
            <div className="EventElementBottomLeft">
              <p className="EventElementBottomLongName">{eventName}</p>
              <p className="EventElementBottomDescription">{eventDescription}</p>
            </div>
          </div>
        </Link>
      </Grid.Column>
    );
  }
}
