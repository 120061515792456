import {Topbar} from './Topbar';
import {getCurrentLanguage} from '../i18n';
import {TermsOfService as TermsOfServiceFi} from '../templates/fi/TermsOfService';
import {TermsOfService as TermsOfServiceEn} from '../templates/en/TermsOfService';

export const TermsOfServicePage = () => {
  return (
    <div className="PrivacyTermsPage">
      <Topbar />
      <div className="PrivacyTermsPageContainer">
        {getCurrentLanguage() === 'fi'
        ? <TermsOfServiceFi />
        : <TermsOfServiceEn />}
      </div>
    </div>
  );
};
