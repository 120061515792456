import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Input, Button, Form, Checkbox} from 'semantic-ui-react';
import {enduserRegister} from '../actions/auth';
import {alert} from '../alert';
import {t} from '../i18n';
import {routes} from '../routes';
import {Topbar} from './Topbar';

export function RegisterPage() {
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [canSendVendorEmail, setCanSendVendorEmail] = useState(false);

  const onButtonRegister = () => {
    let errorMessage;

    if (errorMessage === undefined && firstName.length === 0) {
      errorMessage = 'PAGE_REGISTER.ERROR_MISSING_FIELD_FIRST_NAME';
    }

    if (errorMessage === undefined && lastName.length === 0) {
      errorMessage = 'PAGE_REGISTER.ERROR_MISSING_FIELD_LAST_NAME';
    }

    if (errorMessage === undefined && email.length === 0) {
      errorMessage = 'PAGE_REGISTER.ERROR_MISSING_FIELD_EMAIL';
    }

    if (errorMessage === undefined && password.length < 6) {
      errorMessage = 'PAGE_REGISTER.ERROR_PASSWORD_TOO_SHORT';
    }

    if (errorMessage === undefined && password !== passwordAgain) {
      errorMessage = 'PAGE_REGISTER.ERROR_PASSWORD_MISMATCH';
    }

    if (errorMessage === undefined && termsAccepted !== true) {
      errorMessage = 'PAGE_REGISTER.ERROR_MUST_ACCEPT_TERMS';
    }

    if (errorMessage === undefined) {
      dispatch(
        enduserRegister({
          firstName: firstName,
          lastName: lastName,
          email: email,
          password: password,
          canSendVendorEmail: canSendVendorEmail,
        }),
      );
    } else {
      alert({
        title: t('ERROR_GENERIC.ERROR_TITLE_GENERIC'),
        message: t(errorMessage),
      });
    }
  };

  return (
    <div className="AuthPageVerticalRoot">
      <Topbar />

      <div className="CenterContainer CenterContainerLoginForm">
        <Form>
          <Form.Field>
            <label>{t('PAGE_REGISTER.FIELD_FIRST_NAME_HINT')}</label>
            <Input
              value={firstName}
              placeholder={t('PAGE_REGISTER.FIELD_FIRST_NAME_HINT')}
              onChange={(changeEvent) => {
                setFirstName(changeEvent.target.value);
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>{t('PAGE_REGISTER.FIELD_LAST_NAME_HINT')}</label>
            <Input
              value={lastName}
              placeholder={t('PAGE_REGISTER.FIELD_LAST_NAME_HINT')}
              onChange={(changeEvent) => {
                setLastName(changeEvent.target.value);
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>{t('PAGE_REGISTER.FIELD_EMAIL_HINT')}</label>
            <Input
              value={email}
              placeholder={t('PAGE_REGISTER.FIELD_EMAIL_HINT')}
              onChange={(changeEvent) => {
                setEmail(changeEvent.target.value);
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>{t('PAGE_REGISTER.FIELD_PASSWORD_HINT')}</label>
            <Input
              value={password}
              placeholder={t('PAGE_REGISTER.FIELD_PASSWORD_HINT')}
              type={'password'}
              onChange={(changeEvent) => {
                setPassword(changeEvent.target.value);
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>{t('PAGE_REGISTER.FIELD_PASSWORD_AGAIN_HINT')}</label>
            <Input
              value={passwordAgain}
              placeholder={t('PAGE_REGISTER.FIELD_PASSWORD_AGAIN_HINT')}
              type={'password'}
              onChange={(changeEvent) => {
                setPasswordAgain(changeEvent.target.value);
              }}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label={
                <label>
                  <span>{t('PAGE_REGISTER.FIELD_TERMS_LABEL_PART_1')}</span>
                  <a
                    href={routes.termsOfService()}
                    target="_blank"
                    rel="noopener noreferrer">
                    {t('PAGE_REGISTER.FIELD_TERMS_LABEL_PART_TERMS')}
                  </a>
                  <span>{t('PAGE_REGISTER.FIELD_TERMS_LABEL_PART_2')}</span>
                  <a
                    href={routes.privacyPolicy()}
                    target="_blank"
                    rel="noopener noreferrer">
                    {t('PAGE_REGISTER.FIELD_TERMS_LABEL_PART_PRIVACY_POLICY')}
                  </a>
                </label>
              }
              checked={termsAccepted}
              onChange={(_changeEvent) => {
                setTermsAccepted(!termsAccepted);
              }}
            />
          </Form.Field>
          <Form.Field>
            <Checkbox
              label={t('PAGE_REGISTER.FIELD_CAN_SEND_VENDOR_EMAIL_LABEL')}
              checked={canSendVendorEmail}
              onChange={(_changeEvent) => {
                setCanSendVendorEmail(!canSendVendorEmail);
              }}
            />
          </Form.Field>
          <Button inverted primary onClick={onButtonRegister} fluid>
            {t('PAGE_REGISTER.BUTTON_REGISTER')}
          </Button>
        </Form>
      </div>
    </div>
  );
}
