import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import {LocationState, History} from 'history';

import {auth} from './auth';
import {company} from './company';
import {loading} from './loading';
import {events} from './events';
import {ticket} from './ticket';
import {stream} from './stream';
import {cart} from './cart';
import {payment} from './payment';
import {discount} from './discount';

import {resetReduxStateToInitial} from '../actions/auth';

export const rootReducers = {
  auth,
  company,
  loading,
  events,
  ticket,
  stream,
  cart,
  payment,
  discount,
};

export function createRootReducerWithRouter<S = LocationState>(
  history: History<S>,
) {
  const rootReducerWithoutReset = combineReducers({
    router: connectRouter(history),
    ...rootReducers,
  });

  const rootReducerWithReset = (state: any, action: any) => {
    if (
      typeof action === 'object' &&
      action.type === resetReduxStateToInitial.actionType
    ) {
      return rootReducerWithoutReset(undefined, action);
    } else {
      return rootReducerWithoutReset(state, action);
    }
  };

  return rootReducerWithReset as typeof rootReducerWithoutReset;
}

export type TStoreState = ReturnType<
  ReturnType<typeof createRootReducerWithRouter>
>;
