import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Input, Button, Form, Grid} from 'semantic-ui-react';
import {
  enduserForgotPasswordInputResetCodeAndPassword,
  enduserForgotPasswordResendCode,
} from '../actions/auth';
import {t} from '../i18n';
import {Topbar} from './Topbar';

export function ForgotPasswordResetPage() {
  const dispatch = useDispatch();

  const [resetCode, setResetCode] = useState('');
  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');

  const onButtonReset = () => {
    let errorMessage;

    if (errorMessage === undefined && resetCode.length === 0) {
      errorMessage =
        'PAGE_FORGOT_PASSWORD_RESET.ERROR_MISSING_FIELD_RESET_CODE';
    }

    if (errorMessage === undefined && password.length < 6) {
      errorMessage = 'PAGE_REGISTER.ERROR_PASSWORD_TOO_SHORT';
    }

    if (errorMessage === undefined && password !== passwordAgain) {
      errorMessage = 'PAGE_REGISTER.ERROR_PASSWORD_MISMATCH';
    }

    if (errorMessage === undefined) {
      dispatch(
        enduserForgotPasswordInputResetCodeAndPassword({
          resetCode: resetCode,
          password: password,
        }),
      );
    } else {
      alert({
        title: t('ERROR_GENERIC.ERROR_TITLE_GENERIC'),
        message: t(errorMessage),
      });
    }
  };

  return (
    <div className="AuthPageVerticalRoot">
      <Topbar />

      <div className="CenterContainer">
        <Grid columns={1}>
          <Grid.Row>
            <Grid.Column>
              <Form>
                <Form.Field>
                  <label>
                    {t('PAGE_FORGOT_PASSWORD_RESET.FIELD_RESETCODE_LABEL')}
                  </label>
                  <Input
                    value={resetCode}
                    placeholder={t(
                      'PAGE_FORGOT_PASSWORD_RESET.FIELD_RESETCODE_HINT',
                    )}
                    onChange={(changeEvent) => {
                      setResetCode(changeEvent.target.value);
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>
                    {t('PAGE_FORGOT_PASSWORD_RESET.FIELD_PASSWORD_HINT')}
                  </label>
                  <Input
                    value={password}
                    placeholder={t(
                      'PAGE_FORGOT_PASSWORD_RESET.FIELD_PASSWORD_HINT',
                    )}
                    type={'password'}
                    onChange={(changeEvent) => {
                      setPassword(changeEvent.target.value);
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>
                    {t('PAGE_FORGOT_PASSWORD_RESET.FIELD_PASSWORD_AGAIN_HINT')}
                  </label>
                  <Input
                    value={passwordAgain}
                    placeholder={t(
                      'PAGE_FORGOT_PASSWORD_RESET.FIELD_PASSWORD_AGAIN_HINT',
                    )}
                    type={'password'}
                    onChange={(changeEvent) => {
                      setPasswordAgain(changeEvent.target.value);
                    }}
                  />
                </Form.Field>
                <Button inverted primary onClick={onButtonReset} fluid>
                  {t('PAGE_FORGOT_PASSWORD_RESET.BUTTON_RESET')}
                </Button>
              </Form>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row></Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Button
                inverted
                primary
                onClick={() => {
                  dispatch(enduserForgotPasswordResendCode({}));
                }}
                fluid>
                {t('PAGE_FORGOT_PASSWORD_RESET.BUTTON_RESEND_CODE')}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
}
