import {TNameObject} from './api/dataTypes';
import {pictureURL} from './api/baseapi';
export const defaultCompanyLogo = `${pictureURL}files/icons/g-livelab-logo-min.png`;
export const defaultEventImage = `${pictureURL}files/icons/poster.jpg`;
export const vismaLogo = `${pictureURL}files/icons/visma.png`;
export const mapLogo = require('./img/map.jpg').default;
export const poweredIcon = require('./img/powered.png').default;

export const getListedCompanySlugs = () => ['umo'];

export type TPerson = {
  name: string;
  title: string;
  phone: string;
  email: string;
};

export type TCompanyContact = {
  slug: string;
  name: string;
  street: string;
  post: string;
  city: string;
  phone?: string;
  email?: string;
  mapQuery: string;
  mapUrl: string;
  persons: TPerson[];
  posterUrl?: string;
};

export const companyInfos: TCompanyContact[] = [
  {
    slug: 'umo',
    name: 'UMO Helsinki',
    street: 'Tallberginkatu 1/139',
    post: '00180',
    city: 'Helsinki',
    mapQuery: 'UMO Helsinki Jazz Orchestra office, Tallberginkatu 1',
    posterUrl: `${pictureURL}files/icons/poster.jpg`,
    mapUrl: '',
    /* eslint-enable */
    persons: [
      {
        name: 'Yhteys / Contact',
        title: '',
        phone: '',
        email: 'jazz@umohelsinki.fi',
      },
    ],
  },
];

export const getCompanyContactInfo = (slug: string) =>
  companyInfos.find((info: TCompanyContact) => info.slug === slug);

export type TCompanyAbout = {
  slug: string;
  about: TNameObject[];
};

const aboutInfos = [
  {
    slug: 'umo',
    about: [
      {
        language: 'fi',
        value: '',
      },
      {
        language: 'en',
        value: '',
      },
    ],
  },
];

export const getCompanyAboutInfo = (slug: string) =>
  aboutInfos.find((info: TCompanyAbout) => info.slug === slug);

export const SUBSCRIPTIONS_IDS = {};

export const localStorageKeys = {
  authToken: 'authToken',
  selectedCompanySlug: 'selectedCompanySlug',
  paymentProcess: 'gapp.paymentProcess',
};

export const giftTicketEventIds: string[] = [];
export const giftTicketTicketseriesIds: string[] = [];
