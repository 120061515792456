import {Topbar} from './Topbar';
import {getCurrentLanguage} from '../i18n';
import {PrivacyPolicy as PrivacyPolicyEn} from '../templates/en/PrivacyPolicy';
import {PrivacyPolicy as PrivacyPolicyFi} from '../templates/fi/PrivacyPolicy';

export const PrivacyPolicyPage = () => {
  return (
    <div className="PrivacyTermsPage">
      <Topbar />
      <div className="PrivacyTermsPageContainer">
        {getCurrentLanguage() === 'fi'
        ? <PrivacyPolicyFi />
        : <PrivacyPolicyEn />}
      </div>
    </div>
  );
};
