import {uniqBy} from 'lodash';
import {put, call, all} from 'typed-redux-saga';

import {setEvents} from '../actions/events';
import {TEventObject} from '../api/dataTypes';
import {getEvents, getEventsOndemand} from '../api/events';
import {typedSelect} from '../redux/reduxUtil';

export function* refreshEvents() {
  const currentCompanySlug = yield* typedSelect(
    (state) => state.company.currentCompanySlug,
  );

  if (currentCompanySlug) {
    const [eventsResult, eventsOndemandResult] = yield* all([
      call(getEvents, currentCompanySlug),
      call(getEventsOndemand, currentCompanySlug),
    ]);

    let liveEvents: TEventObject[] = [];
    let ondemandEvents: TEventObject[] = [];

    if (!eventsResult.error) {
      liveEvents = eventsResult.data.context;
    }

    if (!eventsOndemandResult.error) {
      ondemandEvents = eventsOndemandResult.data.context;
    }

    const mergedEventsAndOndemand = uniqBy(
      [...liveEvents, ...ondemandEvents],
      '_id',
    );

    yield put(
      setEvents({
        events: mergedEventsAndOndemand,
      }),
    );
  }
}
