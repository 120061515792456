import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Input, Button, Form, Grid} from 'semantic-ui-react';
import {enduserForgotPasswordInputEmail} from '../actions/auth';
import {t} from '../i18n';
import {Topbar} from './Topbar';

export function ForgotPasswordPage() {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');

  const onButtonContinue = () => {
    let errorMessage;

    if (errorMessage === undefined && email.length === 0) {
      errorMessage = 'PAGE_FORGOT_PASSWORD.ERROR_INVALID_EMAIL';
    }

    if (errorMessage === undefined) {
      dispatch(
        enduserForgotPasswordInputEmail({
          email: email,
        }),
      );
    } else {
      alert({
        title: t('ERROR_GENERIC.ERROR_TITLE_GENERIC'),
        message: t(errorMessage),
      });
    }
  };

  return (
    <div className="AuthPageVerticalRoot">
      <Topbar />

      <div className="CenterContainer">
        <Grid columns={1}>
          <Grid.Row>
            <Grid.Column>
              <p>{t('PAGE_FORGOT_PASSWORD.MAIN_MESSAGE')}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form>
                <Form.Field>
                  <label>{t('PAGE_FORGOT_PASSWORD.FIELD_EMAIL_LABEL')}</label>
                  <Input
                    value={email}
                    placeholder={t('PAGE_FORGOT_PASSWORD.FIELD_EMAIL_HINT')}
                    onChange={(changeEvent) => {
                      setEmail(changeEvent.target.value);
                    }}
                  />
                </Form.Field>
                <Button inverted primary onClick={onButtonContinue} fluid>
                  {t('BUTTON_GENERIC.BUTTON_CONTINUE')}
                </Button>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
}
