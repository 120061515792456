import React from 'react';
import {useDispatch} from 'react-redux';
import {Button, Icon, Label, Modal} from 'semantic-ui-react';
import {
  enduserOndemandClipSelectionModalChoice,
  setShowOndemandClipSelectionModal,
} from '../actions/stream';
import {useTypedSelector} from '../redux/reduxUtil';
import Moment from 'moment';
import {sortBy} from 'lodash';
import {TOndemandStreamWithFullProps} from '../commonTypes';
import {t} from '../i18n';

export function OndemandClipSelectionModal() {
  const dispatch = useDispatch();

  const {show, choices} = useTypedSelector((state) => ({
    show: state.stream.ondemandClipSelectionModalShow,
    choices: state.stream.ondemandClipSelectionModalChoices,
  }));

  const sortedChoices = Array.isArray(choices) && sortBy(choices, 'startDate');

  const onChoiceSelected = (choice: TOndemandStreamWithFullProps) => {
    dispatch(
      setShowOndemandClipSelectionModal({
        show: false,
      }),
    );

    dispatch(enduserOndemandClipSelectionModalChoice({choice: choice}));
  };

  return (
    <Modal
      size={'mini'}
      onClose={() => {
        dispatch(
          setShowOndemandClipSelectionModal({
            show: false,
          }),
        );
      }}
      open={show}
      className="GappModalBackground">
      <Modal.Header>
        {t('STREAM.ONDEMAND_CLIP_SELECTION_MODAL_TITLE')}
      </Modal.Header>
      <Modal.Content>
        <div className="OndemandClipSelectionModalChoiceContainer">
          {Array.isArray(sortedChoices) &&
            sortedChoices.map((ondemandWithVideoProps) => {
              const formattedStartDate = Moment(
                ondemandWithVideoProps.startDate,
              ).format('HH:mm');
              const formattedEndDate = Moment(
                ondemandWithVideoProps.endDate,
              ).format('HH:mm');

              const formattedLabel = `${formattedStartDate} - ${formattedEndDate}`;

              return (
                <div
                  key={ondemandWithVideoProps.startDate}
                  className="OndemandClipSelectionModalChoice">
                  <Button
                    inverted
                    primary
                    icon
                    labelPosition="left"
                    onClick={() => {
                      onChoiceSelected(ondemandWithVideoProps);
                    }}>
                    <Label>{formattedLabel}</Label>
                    <Icon name="play" />
                  </Button>
                </div>
              );
            })}
        </div>
      </Modal.Content>
    </Modal>
  );
}
