import {push} from 'connected-react-router';
import {call, put, takeEvery} from 'typed-redux-saga';
import {
  enduserForgotPasswordInputEmail,
  enduserForgotPasswordInputResetCodeAndPassword,
  enduserForgotPasswordResendCode,
  enduserLogin,
  setOngoingForgotPassword,
} from '../actions/auth';
import {showLightLoadingScreen} from '../actions/loading';
import {getCurrentLanguage, t} from '../i18n';
import {ActionOfActionCreator, typedSelect} from '../redux/reduxUtil';
import {alert} from '../alert';
import {
  doForgotPasswordRequestReset,
  doForgotPasswordReset,
} from '../api/login';
import {onEnduserLogin} from './auth';
import {routes} from '../routes';

export function* onEnduserForgotPasswordInputEmail(
  action: ActionOfActionCreator<typeof enduserForgotPasswordInputEmail>,
) {
  let success = false;

  yield* put(showLightLoadingScreen({show: true}));

  try {
    const requestResetResult = yield* call(
      doForgotPasswordRequestReset,
      action.payload.email,
      getCurrentLanguage(),
      true,
    );

    if (requestResetResult.error === 0) {
      yield* put(
        setOngoingForgotPassword({
          email: action.payload.email,
        }),
      );

      success = true;
    }
  } catch (_e) {}

  yield* put(showLightLoadingScreen({show: false}));

  if (success) {
    yield put(push(routes.forgotPasswordReset()));
  } else {
    alert({
      title: t('ERROR_GENERIC.ERROR_TITLE_GENERIC'),
      message: t('PAGE_FORGOT_PASSWORD.ERROR_MAIN'),
    });
  }
}

export function* onEnduserForgotPasswordInputResetCodeAndPassword(
  action: ActionOfActionCreator<
    typeof enduserForgotPasswordInputResetCodeAndPassword
  >,
) {
  const ongoingForgotPasswordEmail = yield* typedSelect(
    (state) => state.auth.ongoingForgotPassword?.email,
  );

  if (ongoingForgotPasswordEmail) {
    let success = false;

    yield* put(showLightLoadingScreen({show: true}));

    try {
      const resetResult = yield* call(
        doForgotPasswordReset,
        {
          email: ongoingForgotPasswordEmail,
          password: action.payload.password,
          password2: action.payload.password,
        },
        action.payload.resetCode,
      );

      if (resetResult.error === 0) {
        success = true;
      }
    } catch (_e) {}

    yield* put(showLightLoadingScreen({show: false}));

    if (success) {
      yield* call(
        onEnduserLogin,
        enduserLogin({
          username: ongoingForgotPasswordEmail,
          password: action.payload.password,
        }),
      );
    } else {
      alert({
        title: t('ERROR_GENERIC.ERROR_TITLE_GENERIC'),
        message: t('PAGE_FORGOT_PASSWORD.ERROR_MAIN'),
      });
    }
  }
}

export function* onEnduserForgotPasswordResendCode() {
  const ongoingForgotPasswordEmail = yield* typedSelect(
    (state) => state.auth.ongoingForgotPassword?.email,
  );

  if (ongoingForgotPasswordEmail) {
    yield* put(showLightLoadingScreen({show: true}));

    try {
      yield* call(
        doForgotPasswordRequestReset,
        ongoingForgotPasswordEmail,
        getCurrentLanguage(),
        true,
      );
    } catch (_e) {}

    yield* put(showLightLoadingScreen({show: false}));

    alert({
      title: t('PAGE_FORGOT_PASSWORD_RESET.ALERT_CODE_RESEND_DONE_TITLE'),
      message: t('PAGE_FORGOT_PASSWORD_RESET.ALERT_CODE_RESEND_DONE_MESSAGE'),
    });
  }
}

export function* watchForgotPassword() {
  yield* takeEvery(
    enduserForgotPasswordInputEmail.actionType,
    onEnduserForgotPasswordInputEmail,
  );
  yield* takeEvery(
    enduserForgotPasswordInputResetCodeAndPassword.actionType,
    onEnduserForgotPasswordInputResetCodeAndPassword,
  );
  yield* takeEvery(
    enduserForgotPasswordResendCode.actionType,
    onEnduserForgotPasswordResendCode,
  );
}
