import {setOngoingPaymentData, setReceiptInput} from '../actions/payment';
import {TReceiptInputObject} from '../api/dataTypes';
import {reducerBuilder} from '../redux/reduxUtil';

export type TOffsitePaymentType =
  | 'paymentWithExistingCard'
  | 'paymentWithNewCard'
  | 'addNewCard';

export const payment = reducerBuilder<{
  receiptInput?: TReceiptInputObject | undefined;
  payformPaymentUrl?: string;
  payformPaymentToken?: string;
  offsitePaymentType?: TOffsitePaymentType | undefined;
}>({
  receiptInput: undefined,
})
  .matchAction(setReceiptInput, (state, action) => {
    return {
      ...state,
      receiptInput: action.payload.receiptInput,
    };
  })
  .matchAction(setOngoingPaymentData, (state, action) => {
    return {
      ...state,
      payformPaymentUrl: action.payload.payformPaymentUrl,
      payformPaymentToken: action.payload.payformPaymentToken,
      offsitePaymentType: action.payload.offsitePaymentType,
    };
  })

  .finish();
