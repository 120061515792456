import localforage from 'localforage';
import {put, takeEvery, call, all, delay, fork} from 'typed-redux-saga';
import moment from 'moment';

import {
  enduserSetCurrentCompany,
  setCompanies,
  setCurrentCompany,
} from '../actions/company';
import {addError} from '../actions/error';
import {enduserSetLanguage} from '../actions/localization';
import {getCompanies} from '../api/company';
import {localStorageKeys} from '../configs';
import {ActionOfActionCreator, typedSelect} from '../redux/reduxUtil';
import i18n from 'i18next';
import {replace} from 'connected-react-router';
import {refreshEvents} from './events';
import {refreshUserProfile} from './auth';
import {refreshTickets} from './ticket';
import {
  rerenderRouter,
  screenSizeChanged,
  setUsingMobileStyles,
  showHeavyLoadingScreen,
  showLightLoadingScreen,
} from '../actions/loading';
import {routes} from '../routes';

export function* refreshData(fetchFunc: Function, silentError?: boolean) {
  try {
    yield* call(fetchFunc as any);
  } catch (e) {
    if (silentError) {
      throw e;
    }

    yield put(
      addError({
        title: 'strings.refresh_error_title',
        message: 'strings.refresh_error_message',
      }),
    );

    throw e;
  }
}

export function* onEnduserSetCurrentCompany(
  action: ActionOfActionCreator<typeof enduserSetCurrentCompany>,
) {
  if (action.payload.sourceLocation === 'homepageSelector') {
    yield* put(showHeavyLoadingScreen({show: true}));

    yield* call(
      setCurrentCompanyMinimalSaga,
      action.payload.currentCompanySlug,
    );

    yield* call(refreshEssentialsPostCompany);

    yield put(replace(routes.root()));
    yield* put(showHeavyLoadingScreen({show: false}));
  }
}

export function* onEnduserSetLanguage(
  action: ActionOfActionCreator<typeof enduserSetLanguage>,
) {
  yield* put(showLightLoadingScreen({show: true}));

  yield* delay(500);

  moment.locale(action.payload.language);

  i18n.changeLanguage(action.payload.language);

  yield* put(rerenderRouter({}));

  yield* delay(500);

  yield* put(showLightLoadingScreen({show: false}));
}

export function* setCurrentCompanyMinimalSaga(companySlug: string) {
  const existingCompanies = yield* typedSelect(
    (state) => state.company.companies,
  );

  const foundExistingCompany = existingCompanies.find(
    (company) => company.slug === companySlug,
  );

  if (foundExistingCompany) {
    yield put(
      setCurrentCompany({
        currentCompanySlug: companySlug,
        currentCompany: foundExistingCompany,
      }),
    );

    yield* call(async () => {
      return localforage.setItem(
        localStorageKeys.selectedCompanySlug,
        companySlug,
      );
    });

    return true;
  }

  return false;
}

export function* watchBasics() {
  yield* takeEvery(
    enduserSetCurrentCompany.actionType,
    onEnduserSetCurrentCompany,
  );
  yield* takeEvery(enduserSetLanguage.actionType, onEnduserSetLanguage);

  yield* call(updateScreenSize);
  yield* fork(watchScreenSize);
}

export function* refreshCompanies() {
  const companiesResult = yield* call(getCompanies);

  if (!companiesResult.error) {
    yield put(
      setCompanies({
        companies: companiesResult.data.context,
      }),
    );
  } else {
    yield put(
      setCompanies({
        companies: [],
      }),
    );
  }
}

export function* refreshEssentialsPreCompany() {
  const refreshCalls = [call(refreshData, refreshCompanies, true)];

  yield all(refreshCalls);
}

export function* refreshEssentialsPostCompany() {
  const loggedIn = yield* typedSelect((state) => Boolean(state.auth.token));

  const refreshCalls1 = [];
  const refreshCalls2 = [call(refreshData, refreshEvents, true)];

  if (loggedIn) {
    refreshCalls1.push(call(refreshData, refreshUserProfile, true));
    refreshCalls2.push(call(refreshData, refreshTickets, true));
  }

  if (refreshCalls1.length > 0) {
    yield all(refreshCalls1);
  }
  if (refreshCalls2.length > 0) {
    yield all(refreshCalls2);
  }
}

function* watchScreenSize() {
  yield* takeEvery(screenSizeChanged.actionType, updateScreenSize);
}

function* updateScreenSize() {
  const currentScreenW = window.innerWidth;
  const currentScreenH = window.innerHeight;

  const newUsingMobileStyles =
    currentScreenW < currentScreenH || currentScreenW <= 991;

  const usingMobileStyles = yield* typedSelect(
    (state) => state.loading.usingMobileStyles,
  );

  if (usingMobileStyles !== newUsingMobileStyles) {
    yield* put(setUsingMobileStyles({usingMobileStyles: newUsingMobileStyles}));
  }
}
