import {
  setShowOndemandClipSelectionModal,
  setVideoStreamViewProps,
} from '../actions/stream';
import {
  TOndemandStreamWithFullProps,
  TVideoStreamViewProps,
} from '../commonTypes';
import {reducerBuilder} from '../redux/reduxUtil';

export const stream = reducerBuilder<{
  videoStreamViewProps?: TVideoStreamViewProps;
  ondemandClipSelectionModalShow: boolean;
  ondemandClipSelectionModalChoices?: TOndemandStreamWithFullProps[];
}>({
  ondemandClipSelectionModalShow: false,
})
  .matchAction(setVideoStreamViewProps, (state, action) => {
    return {
      ...state,
      videoStreamViewProps: action.payload.videoStreamViewProps,
    };
  })
  .matchAction(setShowOndemandClipSelectionModal, (state, action) => {
    return {
      ...state,
      ondemandClipSelectionModalShow: action.payload.show,
      ondemandClipSelectionModalChoices: action.payload.choices,
    };
  })

  .finish();
