export const PrivacyPolicy = () => (
  <>
    <h1>UMO Helsinki (UMO-s&auml;&auml;ti&ouml; sr):n tietosuojaseloste</h1>

    <p>T&auml;ss&auml; tietosuojaselosteessa kuvataan UMO Helsinki:n henkil&ouml;tietojen ja palvelun k&auml;yt&ouml;st&auml; ker&auml;ttyjen tietojen k&auml;sittely&auml;. K&auml;sittelyn kohteena ovat asiakkaidemme tiedot UMO Helsinki -sovelluksessa sek&auml; streaming.umohelsinki.fi-sivustolla.</p>

    <h2>Rekisterinpit&auml;j&auml;n yhteystiedot</h2>

    <p>UMO-s&auml;&auml;ti&ouml; sr (jatkossa UMO Helsinki)<br />
        2202299-9<br />
        Tallberginkatu 1 / 139, 00180 Helsinki<br />
        <a href="mailto:jazz@umohelsinki.fi">jazz@umohelsinki.fi</a>
    </p>

    <h2>Mit&auml; tietoja ker&auml;&auml;mme ja mihin tarkoitukseen?</h2>

    <p>Ker&auml;&auml;mme sinusta vain palvelumme toiminnan ja kehitt&auml;misen kannalta tarpeellisia tietoja.</p>
    
    <h3>Tiedot, jotka ker&auml;&auml;mme suoraan sinulta</h3>
    <ul>
        <li>Kun kirjaudut palveluidemme k&auml;ytt&auml;j&auml;ksi, pyyd&auml;mme sinulta henkil&ouml;tietoina nimen, s&auml;hk&ouml;postiosoitteen ja salasanan. Tiedot ker&auml;t&auml;&auml;n voidaksemme tunnistaa sinut palvelussa sek&auml; voidaksemme toimittaa ostamiasi tuotteita ja palveluita. K&auml;yt&auml;mme tietoja markkinointiin vain erillisell&auml; suostumuksellasi. Salasanoja emme miss&auml;&auml;n yhteydess&auml; s&auml;ilyt&auml; luettavassa muodossa.</li>
        <li>Maksamiseen k&auml;ytt&auml;mist&auml;si tiedoista s&auml;ilyt&auml;mme vain maksukortin voimassaoloajan ja maksukortin 4 viimeist&auml; numeroa. Tiedot eiv&auml;t sis&auml;ll&auml; kortin t&auml;ydellist&auml; numeroa, turvakoodia tai muita verkkomaksamiseen tarvittavia tietoja. Emme itse veloita korttia. Kaikki maksut k&auml;sitell&auml;&auml;n PCI-sertifioidun maksukumppanin Visma Payn palvelussa. Maksaminen kyseisess&auml; palvelussa on turvallista, sill&auml; kaikki maksutapahtumaa koskevat tiedot v&auml;litet&auml;&auml;n salattua yhteytt&auml; k&auml;ytt&auml;en niin ettei kukaan ulkopuolinen taho n&auml;e maksutapahtuman tietoja.</li>
        <li>S&auml;ilyt&auml;mme ostohistoriasi, eli ostotapahtumien kuitit kirjanpitolain edellytt&auml;m&auml;ll&auml; tavalla ja k&auml;yt&auml;mme tietoja anonyymisti ostok&auml;ytt&auml;ytymisen profilointiin. Peruessasi suostumuksesi tietojesi k&auml;sittelyyn kuitit muuttuvat anonyymeiksi, eik&auml; niit&auml; pystyt&auml; en&auml;&auml; yhdist&auml;m&auml;&auml;n sinuun. </li>
        <li>Kun olet meihin yhteydess&auml;, ker&auml;&auml;mme asiakaspalvelumme kanssa k&auml;ym&auml;si keskustelut eri kanavissa.</li>
    </ul>
    <p>Ker&auml;&auml;mme edell&auml; mainitut tiedot suoraan sinulta rekister&ouml;itymisen, kirjautumisen, palvelun k&auml;ytt&auml;misen, maksukortin lis&auml;&auml;misen, ostamisen tai asiakaspalvelupyynn&ouml;n yhteydess&auml;. N&auml;it&auml; tietoja k&auml;ytet&auml;&auml;n yhteydenpitoon sek&auml; joko palveluiden tarjoamiseksi tai tuottamiseksi.</p>
    
    <h3>Tiedot, joita ker&auml;&auml;mme kolmansilta osapuolilta tai k&auml;ytt&auml;ess&auml;si palvelua</h3>
    <p>Kolmannet osapuolet ker&auml;&auml;v&auml;t tai vastaanottavat tietoja UMO Helsinki -sovelluksestamme, web-sivustoltamme ja muista sovelluksista ja k&auml;ytt&auml;v&auml;t n&auml;it&auml; tietoja analyysipalveluiden tarjoamiseen ja mainosten kohdentamiseen. N&auml;it&auml; kolmansia osapuolia palvelussamme ovat:</p>
    
    <h4>UMO Helsinki -sovelluksessa</h4>
    <ul>
        <li>Google Firebase Crashlytics-lis&auml;osa ker&auml;&auml; UMO Helsinki -sovelluksen virhetilanteissa anonyymi&auml; dataa k&auml;ytt&auml;jien laitteista. K&auml;yt&auml;mme t&auml;t&auml; dataa virheiden paikallistamiseen ja korjaamiseen. Annat suostumuksen n&auml;iden tietojen ker&auml;&auml;misen kirjautumalla palveluun ja hyv&auml;ksym&auml;ll&auml; t&auml;m&auml;n tietosuojak&auml;yt&auml;nn&ouml;n. Tietojen ker&auml;&auml;minen keskeytyy k&auml;ynnist&auml;m&auml;ll&auml; sovellus uudelleen uloskirjauduttuasi. (<a href="https://firebase.google.com/support/privacy/" target="_blank" rel="noopener noreferrer">Lis&auml;tietoja Firebase:n tietosuojasta</a>) </li>
        <li>Facebook ker&auml;&auml; ja k&auml;sittelee tietoja Facebook-tunnistautumisesi j&auml;lkeen. Annat suostumuksen tietojen ker&auml;&auml;misen tunnistautumalla Facebook-tunnustasi k&auml;ytt&auml;en. K&auml;yt&auml;mme Facebookin ker&auml;&auml;m&auml;&auml; anonyymi&auml; analytiikkadataa palvelumme kehitt&auml;miseen. Facebook-tunnistautumisen yhteydess&auml; tallennamme k&auml;ytt&auml;j&auml;tiedoiksi nimen ja email-osoitteen. Lis&auml;ksi tallennamme Facebook-id:n tunnusten linkitt&auml;miseksi kesken&auml;&auml;n. Tietojen ker&auml;&auml;minen keskeytyy poistamalla UMO Helsinki -sovellukselle my&ouml;nnetty lupa Facebook:n asetuksissa. S&auml;ilyt&auml;mme t&auml;ss&auml; yhteydess&auml; Facebook-tunnisteen, jonka poistoa voit pyyt&auml;&auml; "Oikeuksien k&auml;ytt&auml;minen"-kohdassa m&auml;&auml;ritellyll&auml; tavalla. <a href="https://www.facebook.com/about/privacy" target="_blank" rel="noopener noreferrer">Lis&auml;tietoja Facebook:n tietosuojasta</a>. </li>
    </ul>
    
    <h4>Web-sivustolla</h4>
    <ul>
        <li>Web-sivullamme ker&auml;&auml;mme tietoja voidaksemme tarjota sinulle mahdollisimman hyv&auml;n k&auml;ytt&ouml;kokemuksen. Tarvitsemme tiedot voidaksemme analysoida palveluidemme k&auml;ytt&ouml;&auml;, jotta voimme parantaa palveluidemme laatua, mukautuvuutta ja olennaisuutta juuri sinun toiveitasi ajatellen. Tietojen ker&auml;&auml;minen tapahtuu ev&auml;steiden tai k&auml;ytt&auml;j&auml;tunnuksen avulla. Tallennamme ev&auml;steisiin anonyymisti tietoja kuten anonymisoitu ip-osoite sek&auml; k&auml;ytett&auml;v&auml;n laitteen ja selaimen tekniset tiedot. Hyv&auml;ksyt tietojen ker&auml;&auml;misen k&auml;ytt&auml;m&auml;ll&auml; web-sivustoamme.</li>
        <li>Tietojen ker&auml;&auml;miseen web-sivustollamme k&auml;ytet&auml;&auml;n mm. Facebook Pixel ja Google Analytics liit&auml;nn&auml;isi&auml; analytiikkadatan ker&auml;&auml;miseen. Kolmannet osapuolet, kuten mainostajat ja mainosverkostot, voivat mm. kohdentaa mainontaa n&auml;iden lis&auml;osien ker&auml;&auml;m&auml;n tiedon perusteella. <a href="https://www.facebook.com/about/privacy" target="_blank" rel="noopener noreferrer">Lis&auml;tietoja Facebook:n tietosuojasta</a>, <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Lis&auml;tietoja Google:n tietosuojasta</a>.</li>
        <li>Voit halutessasi tyhjent&auml;&auml; tai est&auml;&auml; ev&auml;steet ja muun seurannan selaimesi tai laitteesi asetuksista. Huomioithan kuitenkin, ett&auml; ev&auml;steiden tyhjent&auml;minen ei lopeta kokonaan tietojesi keruuta. Jos et halua tallentaa ev&auml;steit&auml; laitteellesi, voit asettaa selaimesi kielt&auml;ytym&auml;&auml;n ev&auml;steist&auml;. T&auml;m&auml;n toimenpiteen voi tehd&auml; joko ennen palveluiden k&auml;ytt&auml;mist&auml; tai palvelun k&auml;yt&ouml;n aikana. Mobiililaitteilla voit s&auml;&auml;t&auml;&auml; laitteesi ja sovelluksiesi asetuksia seurantaominaisuuksiin liittyen. Ev&auml;steiden ja seurannan poistaminen kokonaan k&auml;yt&ouml;st&auml; voi kuitenkin vaikuttaa palveluiden toimivuuteen.</li>
    </ul>

    <h2>Mit&auml; oikeuksia sinulla on ja kuinka voit k&auml;ytt&auml;&auml; niit&auml;?</h2>
    
    <h3>Sinulla on seuraavat oikeudet:</h3>
    <ul>
        <li>Oikeus saada p&auml;&auml;sy henkil&ouml;tietoihin: Sinulla on oikeus tutustua hallussamme oleviin henkil&ouml;tietoihin. Oikeutta tutustua tietoihin voidaan kuitenkin joutua rajoittamaan lains&auml;&auml;d&auml;nn&ouml;n ja muiden henkil&ouml;iden yksityisyyden suojan vuoksi.</li>
        <li>Oikeus tietojen oikaisemiseen: Sinulla on oikeus pyyt&auml;&auml; korjausta virheellisiin tai puutteellisiin tietoihin.</li>
        <li>Oikeus tietojen poistamiseen: Sinulla on on oikeus pyyt&auml;&auml; tietojesi poistamista. Tietojen poistaminen voidaan tehd&auml; esimerkiksi niiss&auml; tapauksissa, kun peruutat suostumuksen tietojesi k&auml;sittelyyn, eik&auml; k&auml;sittelyyn ole muuta perustetta tai kun vastustat tietojen k&auml;sittely&auml; eik&auml; k&auml;sittelyn jatkamiselle ole muuta perustetta.</li>
        <li>Oikeus k&auml;sittelyn rajoittamiseen: Sinulla on oikeus rajoittaa henkil&ouml;tietojensa k&auml;sittely&auml;.</li>
        <li>Vastustamisoikeus: Sinulla on oikeus vastustaa tietojesi k&auml;sittely&auml;.</li>
        <li>Oikeus tietojen siirrett&auml;vyyteen: Sinulla on oikeus saada luovuttamasi tiedot koneellisesti luettavassa muodossa. Oikeus koskee henkil&ouml;tietoja, joita on k&auml;sitelty automaattisesti sopimukseen tai suostumukseen perusten.</li>
        <li>Oikeus peruuttaa suostumus: Sinulla on oikeus peruuttaa suostumuksesi milloin tahansa, t&auml;m&auml;n vaikuttamatta ennen sen peruuttamista tehdyn k&auml;sittelyn lainmukaisuuteen. Suostumuksen peruuttaminen saattaa vaikuttaa mahdollisuutemme tarjota palveluita.</li>
        <li>Oikeus tehd&auml; valitus valvontaviranomaiselle: Sinulla on my&ouml;s oikeus tehd&auml; valitus valvontaviranomaiselle, mik&auml;li ep&auml;ilet henkil&ouml;tietojasi k&auml;ytett&auml;v&auml;n ep&auml;asianmukaisesti tai lainvastaisesti.</li>
    </ul>

    <h3>Oikeuksien k&auml;ytt&auml;minen</h3>
    <p>K&auml;ytt&auml;ksesi oikeuksiasi, ota yhteytt&auml; UMO Helsinki:n asiakaspalveluun <a href="mailto:jazz@umohelsinki.fi">jazz@umohelsinki.fi</a> P&auml;&auml;sy tallennettuihin henkil&ouml;tietoihin on mahdollista my&ouml;s web-sivustomme sek&auml; iOS- ja Android-sovellustemme kautta.</p>

    <h2>Mihin k&auml;yt&auml;mme tietoja ja mill&auml; perusteella k&auml;sittelemme niit&auml;?</h2>
        
    <p>UMO Helsinki k&auml;sittelee henkil&ouml;tietoja t&auml;ytt&auml;&auml;kseen lakis&auml;&auml;teiset ja sopimusten mukaiset velvoitteet. K&auml;sittelymme oikeusperusteita ovat:</p>
    <p>Sopimukseen t&auml;yt&auml;nt&ouml;&ouml;n paneminen: Sopimusvelvoitteiden t&auml;ytt&auml;minen, eli palvelumme tarjoaminen, on p&auml;&auml;asiallinen henkil&ouml;tietojen k&auml;sittelymme oikeusperuste. Sopimus muodostuu v&auml;lillemme rekister&ouml;ityess&auml;si palveluun. Hyv&auml;ksyt tietosuojaselosteen mukaisen tietojen k&auml;sittelyn k&auml;ytt&auml;m&auml;ll&auml; palvelua. K&auml;sittelemme henkil&ouml;tietoja meilt&auml; tilatun palvelun tuottamiseksi, silt&auml; osin kuin on tarpeellista.</p>
    <p>Lakis&auml;&auml;teinen velvoite: Sopimusten lis&auml;ksi toimintaamme liittyy lakis&auml;&auml;teisi&auml; velvoitteita, joiden perusteella k&auml;sittelemme henkil&ouml;tietoja. Esimerkkej&auml; n&auml;ist&auml; ovat kirjanpitolains&auml;&auml;d&auml;nt&ouml; sek&auml; tapahtuma- ja ravintola-aloja koskeva lains&auml;&auml;d&auml;nt&ouml;.</p>
    <p>Suostumus: Verkkosivujemme kehitt&auml;miseksi ker&auml;t&auml;&auml;n analytiikkatietoa sivujen k&auml;yt&ouml;st&auml; perustuen suostumukseen. Annat suostumuksesi hyv&auml;ksym&auml;ll&auml; ev&auml;steet sivustolle saapuessasi. Markkinointitarkoituksessa ker&auml;tyn tiedon osalta pyyd&auml;mme sinulta erillisen suostumuksen, jonka voi peruuttaa milloin tahansa. Kolmannet osapuolet voivat yh&auml; k&auml;ytt&auml;&auml; ker&auml;ttyj&auml; tietoja analyysipalveluiden tarjoamiseen ja mainosten kohdentamiseen. K&auml;ytt&auml;j&auml;ll&auml; on mahdollisuus est&auml;&auml; ev&auml;steiden k&auml;ytt&ouml; muuttamalla selaimensa asetuksia selainvalmistajan ohjeiden mukaisesti sek&auml; tyhjent&auml;&auml; mahdolliset ev&auml;steet selaimen v&auml;limuistista. Ev&auml;steiden tyhjent&auml;minen ei kuitenkaan lopeta mahdollista tiedon keruuta.</p>
    
    <h2>Kuinka kauan s&auml;ilyt&auml;mme tiedot?</h2>

    <p>Henkil&ouml;tietoja s&auml;ilytet&auml;&auml;n vain sopimussuhteen ajan, ellei lains&auml;&auml;d&auml;nn&ouml;ss&auml;, kuten kirjanpitolaissa, toisin vaadita. Esimerkiksi ostotapahtumat s&auml;ilytet&auml;&auml;n kirjanpitolain edellytt&auml;m&auml;n ajan, mutta tiedot anonymisoidaan sopimussuhteen p&auml;&auml;ttyess&auml;.</p>
    <p>Verkkosivujen anonyymi&auml; k&auml;vij&auml;analytiikkatietoa s&auml;ilyt&auml;mme vain niin kauan kuin se on tarpeen markkinoinnin ja asiakaspalvelun seurantaan ja kehitt&auml;miseen, maksimissaan 26kk.</p>
    <p>Asiakaspalvelutietoja s&auml;ilyt&auml;mme enint&auml;&auml;n 24kk.</p>
    
    <h2>Tietojen vastaanottajat ja tietojen siirrot kolmansiin maihin</h2>
    <p>Tietojen k&auml;sittely tapahtuu UMO Helsinki:n ty&ouml;ntekij&ouml;iden toimesta, voimassaolevan henkil&ouml;tietolain mukaisesti. UMO Helsinki pid&auml;tt&auml;&auml; oikeuden ulkoistaa henkil&ouml;tietojen k&auml;sittelyn osittain kolmannelle osapuolelle, jolloin takaamme sopimusj&auml;rjestelyin, ett&auml; henkil&ouml;tietoja k&auml;sitell&auml;&auml;n henkil&ouml;tietolain mukaisesti ja muutoin asianmukaisesti.</p>
    <p>Ostotapahtumiin liittyv&auml;t tiedot siirret&auml;&auml;n maksupalveluntarjoajamme Visma Payn j&auml;rjestelm&auml;&auml;n veloittamista varten. Visma Pay yhteystiedot:</p>
    <p>
        Visma Pay, Paybyway Oy (Y-tunnus 2486559-4)<br />S&auml;hk&ouml;posti: <a href="mailto:helpdesk@vismapay.com">helpdesk@vismapay.com</a><br />
        Puhelin: 09 315 42 037 (arkisin klo 8-16)<br />
        Postiosoite: Laserkatu 6, 53850 Lappeenranta
    </p>
    <p>Muilta osin tietoja ei yhdistet&auml; muihin rekistereihin, eik&auml; niit&auml; luovuteta kolmansille osapuolille, ellei lains&auml;&auml;d&auml;nn&ouml;ss&auml; sit&auml; vaadita (mm. kirjanpitolaki).</p>
    <p>
        Palvelun tuottamiseksi k&auml;ytett&auml;v&auml; palvelinj&auml;rjestelm&auml;n sijaitsee Salesforce:n omistaman Heroku-palveluntarjoajan palvelimilla, Irlannissa. Herokun tietosuojaperiaatteista l&ouml;ytyy tietoa alla olevista linkeist&auml;:<br />
        <a href="https://www.google.com/url?q=https://www.salesforce.com/company/privacy/&amp;sa=D&amp;ust=1541699670329000">https://www.salesforce.com/company/privacy/</a><br />
        <a href="https://www.google.com/url?q=https://www.salesforce.com/gdpr/overview/&amp;sa=D&amp;ust=1541699670329000">https://www.salesforce.com/gdpr/overview/</a>
    </p>

    <h2>Mink&auml;laisia riskej&auml; tietojen k&auml;sittelyyn liittyy ja miten suojaamme tiedot?</h2>
    <p>Olennaisin k&auml;ytt&auml;j&auml;dataan liittyv&auml; riski j&auml;rjestelm&auml;&auml;n liittyen on j&auml;rjestelm&auml;&auml;n kertyv&auml;n henkil&ouml;tiedon ja ostohistorian p&auml;&auml;tyminen v&auml;&auml;riin k&auml;siin esimerkiksi tietomurron yhteydess&auml;. T&auml;m&auml;n ep&auml;todenn&auml;k&ouml;isen riskin toteutuessa tietojen pohjalta voi selvitt&auml;&auml; k&auml;ytt&auml;j&auml;n ostok&auml;ytt&auml;ytymist&auml;, p&auml;&auml;tell&auml; sijaintia tapahtumap&auml;ivin&auml; ja l&auml;hett&auml;&auml; roskapostia.<br /> Laajamittaisista tietovuodoista toimitetaan sopimusosapuolelle (yhteyshenkil&ouml;) aina ilmoitus riippumatta siit&auml;, onko asia ilmoitusvelvollisuuden piiriin kuuluva vai ei. </p>
    <p>UMO Helsinki:n turvallisuustoimien p&auml;&auml;m&auml;&auml;r&auml;n&auml; on turvata tietojen ja tietoj&auml;rjestelmien saatavuus, varmistaa niiden luottamuksellisuus, varmistaa tietojen eheys sek&auml; minimoida mahdollisista poikkeamista aiheutuvat vahingot. Suojaustoimet perustuvat toiminnasta tehtyyn riskiarvioon ja ne suhteutetaan suojeltavan kohteen ja siihen kohdistuvien riskien hallitsemiseksi.</p>
    <p>Henkil&ouml;tietojen k&auml;sittelyss&auml; ja suojaustoimissa huomioidaan voimassa olevan tietosuojaa koskevan lains&auml;&auml;d&auml;nn&ouml;n vaatimukset.</p>
  </>
);
