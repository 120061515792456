import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router';
import {
  Segment,
  Menu,
  DropdownProps,
  Icon,
  Modal,
  Dropdown as SemanticDropdown,
} from 'semantic-ui-react';
import {enduserLogout} from '../actions/auth';
import {enduserSetCurrentCompany} from '../actions/company';
import {enduserSetLanguage} from '../actions/localization';
import Dropdown from '../components/Dropdown';
import {t, languages, getCurrentLanguage} from '../i18n';
import {useTypedSelector} from '../redux/reduxUtil';
import {tabsConfig, TTabsConfigPerTab} from '../tabConfig';
import {routes} from '../routes';
import {useLoggedIn} from '../redux/reduxHooks';

import {Link} from 'react-router-dom';
import {defaultCompanyLogo} from '../configs';
import {cartObjectsFromCart, totalCostFromCartObjects, totalNumberOfCartObjects} from './CartPage';
import {getCurrency} from '../util';
import {alert} from '../alert';

export function Topbar() {
  const dispatch = useDispatch();
  const routerLocation = useLocation();

  const {
    companies,
    currentCompanySlug,
    canNavigateToCart,
    cart,
    events,
    usingMobileStyles,
  } = useTypedSelector((state) => ({
    companies: state.company.companies,
    currentCompanySlug: state.company.currentCompanySlug,
    canNavigateToCart: state.cart.orderLines.length > 0,
    cart: state.cart,
    events: state.events.events,
    usingMobileStyles: state.loading.usingMobileStyles,
  }));

  const cartObjects = cartObjectsFromCart({
    cart,
    events,
    products: [],
    supplierProducts: [],
  });

  const cartTotalObjectCount = totalNumberOfCartObjects(cart);

  const cartTotalCost =
    cart.orderLines.length > 0
      ? totalCostFromCartObjects(cartObjects)
      : undefined;
  const cartTotalCostString =
    cartTotalCost !== undefined ? getCurrency(cartTotalCost) : undefined;

  const loggedIn = useLoggedIn();

  const currentLanguage = getCurrentLanguage();
  const selectableLanguage = languages.find(({ value }) => value !== currentLanguage);
  const selectableLanguageObject = {
    label: (selectableLanguage || {}).label || '',
    value: (selectableLanguage || {}).value || 'en',
  }

  const companiesForDropdown = companies.map((fullCompany) => {
    return {
      label: fullCompany.city,
      value: fullCompany.slug,
    };
  });

  const onSelectLanguage = (_event: any, {value}: DropdownProps) => {
    if (typeof value === 'string') {
      dispatch(enduserSetLanguage({language: value}));
    }
  };

  const onSelectCompany = (_event: any, {value}: DropdownProps) => {
    if (typeof value === 'string') {
      dispatch(
        enduserSetCurrentCompany({
          currentCompanySlug: value,
          sourceLocation: 'homepageSelector',
        }),
      );
    }
  };

  const onButtonLogout = () => {
    alert({
      title: t('ERROR_GENERIC.GENERIC_CONFIRM'),
      message: t('TOPBAR.LOGOUT_CONFIRM_MESSAGE'),
      buttons: [
        {
          label: t('BUTTON_GENERIC.BUTTON_CANCEL'),
        },
        {
          label: t('BUTTON_GENERIC.BUTTON_OK'),
          onClick: () => {
            dispatch(enduserLogout({}));
          },
        },
      ],
    });
  };

  const availableTabs = tabsConfig.filter((tab) => {
    if (tab.requiresLogin) {
      return loggedIn;
    } else {
      return true;
    }
  });

  let selectedTabIndex = 0;

  for (let i = 0; i < availableTabs.length; ++i) {
    const tab = availableTabs[i];

    const firstRoute = Array.isArray(tab.route) ? tab.route[0] : tab.route;

    if (routerLocation.pathname.indexOf(firstRoute) !== -1) {
      selectedTabIndex = i;
    }
  }

  let selectedAny: number | string = selectedTabIndex;

  if (routerLocation.pathname.indexOf('cart') !== -1) {
    selectedAny = 'cart';
  }
  if (routerLocation.pathname.indexOf('payment') !== -1) {
    selectedAny = 'cart';
  }
  if (routerLocation.pathname.indexOf('user_profile') !== -1) {
    selectedAny = 'user_profile';
  }
  if (routerLocation.pathname.indexOf('login') !== -1) {
    selectedAny = 'login';
  }
  if (routerLocation.pathname.indexOf('register') !== -1) {
    selectedAny = 'login';
  }
  if (routerLocation.pathname.indexOf('forgot_password') !== -1) {
    selectedAny = 'login';
  }
  if (routerLocation.pathname.indexOf('privacy') !== -1) {
    selectedAny = 'privacy';
  }
  if (routerLocation.pathname.indexOf('terms') !== -1) {
    selectedAny = 'terms';
  }

  const showLoginButton = !loggedIn;

  const visualsComponent = usingMobileStyles
    ? TopbarVisualsMobile
    : TopbarVisualsDesktop;

  return React.createElement(visualsComponent, {
    availableTabs,
    canNavigateToCart,
    cartTotalCostString,
    cartTotalObjectCount,
    companiesForDropdown,
    currentCompanySlug,
    currentLanguage,
    loggedIn,
    onButtonLogout,
    onSelectCompany,
    onSelectLanguage,
    selectedAny,
    showLoginButton,
    selectableLanguageObject,
  });
}

type TTopbarVisualsSharedProps = {
  availableTabs: TTabsConfigPerTab[];
  selectedAny: number | string;
  currentLanguage: string;
  selectableLanguageObject: {
    label: string,
    value: string,
  },
  onSelectLanguage: (_event: any, {value}: DropdownProps) => void;
  companiesForDropdown: Array<{
    label?: string;
    value?: string;
  }>;
  onSelectCompany: (_event: any, {value}: DropdownProps) => void;
  currentCompanySlug: string | undefined;
  loggedIn: boolean;
  showLoginButton: boolean;
  onButtonLogout: () => void;
  canNavigateToCart: boolean;
  cartTotalCostString: string | undefined;
  cartTotalObjectCount?: number;
};

function TopbarVisualsDesktop(props: TTopbarVisualsSharedProps) {
  return (
    <Segment basic inverted className="navigation" padded>
      <Menu inverted secondary size="massive">
        <Menu.Item
          as={Link}
          to={routes.root()}
          className="logo"
          name={t('TABS.HOME')}
          content={<img alt="companyLogo" src={defaultCompanyLogo} />}
        />
        {props.availableTabs.map((tab, tabIndex) => {
          const firstRoute = Array.isArray(tab.route)
            ? tab.route[0]
            : tab.route;

          return (
            <Menu.Item
              fitted
              as={Link}
              to={tab.tabClickDestination}
              key={firstRoute}
              name={t(tab.label)}
              content={t(tab.label)}
              active={props.selectedAny === tabIndex}
            />
          );
        })}

        <Menu.Menu position="right" size="massive">
          <Menu.Item
            fitted 
            onClick={(e: any) => props.onSelectLanguage(e, { value: props.selectableLanguageObject.value })}
          >
            {props.selectableLanguageObject.label}
          </Menu.Item>

          {props.companiesForDropdown.length > 1 && (
            <Menu.Item fitted>
              <Dropdown
                t={t}
                className="item"
                multiple={false}
                onChange={props.onSelectCompany}
                options={{data: props.companiesForDropdown}}
                search={false}
                value={props.currentCompanySlug}
              />
            </Menu.Item>
          )}

          {props.loggedIn && (
            <Menu.Item
              fitted
              as={Link}
              to={routes.userProfile()}
              name={t('TOPBAR.PROFILE')}
              active={props.selectedAny === 'user_profile'}>
              <Icon name="user" size="big" />
            </Menu.Item>
          )}

          {props.showLoginButton && (
            <Menu.Item
              fitted
              as={Link}
              to={routes.login()}
              content={t('TOPBAR.LOGIN')}
              name={t('TOPBAR.LOGIN')}
              active={props.selectedAny === 'login'}>
            </Menu.Item>
          )}

          {props.loggedIn && (
            <Menu.Item
              fitted
              name={t('TOPBAR.BUTTON_LOGOUT')}
              content={t('TOPBAR.BUTTON_LOGOUT')}
              onClick={props.onButtonLogout}
              position="right">
              <Icon name="log out" size="big" />
            </Menu.Item>
          )}

          {props.canNavigateToCart && (
            <Menu.Item
              fitted
              as={Link}
              to={routes.cart()}
              name={t('TOPBAR.CART')}
              active={props.selectedAny === 'cart'}>
              <Icon name="shopping cart" size="big" />
              {!!props.cartTotalObjectCount && (
                <label className="CartTotalObjectCountLabel">
                  {props.cartTotalObjectCount}
                </label>
              )}
              <label className="CartTotalCostStringLabel">{props.cartTotalCostString}</label>
            </Menu.Item>
          )}
        </Menu.Menu>
      </Menu>
    </Segment>
  );
}

function TopbarVisualsMobile(props: TTopbarVisualsSharedProps) {
  const [modalMainOpen, setModalMainOpen] = useState(false);
  const onButtonHamburgerMenu = () => {
    setModalMainOpen(true);
  };

  const companiesForDropdownMobile = props.companiesForDropdown.map(
    (original) => ({
      value: original.value,
      text: original.label,
    }),
  );

  return (
    <>
      <Segment basic inverted className="navigation" padded>
        <Menu inverted secondary size="massive">
          <Menu.Item
            as={Link}
            to={routes.root()}
            className="logo"
            name={t('TABS.HOME')}
            content={<img alt="companyLogo" src={defaultCompanyLogo} />}
          />

          <Menu.Menu position="right" size="massive">
            {props.canNavigateToCart && (
              <Menu.Item
                fitted
                as={Link}
                to={routes.cart()}
                name={t('TOPBAR.CART')}
                active={props.selectedAny === 'cart'}>
                <Icon name="shopping cart" size="big" />
                {!!props.cartTotalObjectCount && (
                  <label className="CartTotalObjectCountLabel">
                    {props.cartTotalObjectCount}
                  </label>
                )}
                <label className="CartTotalCostStringLabel">{props.cartTotalCostString}</label>
              </Menu.Item>
            )}

            <Menu.Item
              fitted
              name={t('TOPBAR.HAMBURGER_MENU')}
              onClick={onButtonHamburgerMenu}>
              <Icon name="bars" size="big" />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      </Segment>

      <Modal
        onClose={() => {
          setModalMainOpen(false);
        }}
        open={modalMainOpen}
        className="GappModalBackground">
        <Menu size="massive" vertical fluid>
          {props.availableTabs.map((tab, tabIndex) => {
            const firstRoute = Array.isArray(tab.route)
              ? tab.route[0]
              : tab.route;

            return (
              <Menu.Item
                as={Link}
                to={tab.tabClickDestination}
                key={firstRoute}
                name={t(tab.label)}
                content={t(tab.label)}
                active={props.selectedAny === tabIndex}
              />
            );
          })}
          {props.canNavigateToCart && (
            <Menu.Item
              as={Link}
              to={routes.cart()}
              content={t('TOPBAR.CART')}
              name={t('TOPBAR.CART')}
              active={props.selectedAny === 'cart'}
            />
          )}
          {props.loggedIn && (
            <Menu.Item
              as={Link}
              to={routes.userProfile()}
              content={t('TOPBAR.PROFILE')}
              name={t('TOPBAR.PROFILE')}
              active={props.selectedAny === 'user_profile'}
            />
          )}
          {props.showLoginButton && (
            <Menu.Item
              as={Link}
              to={routes.login()}
              content={t('TOPBAR.LOGIN')}
              name={t('TOPBAR.LOGIN')}
              active={props.selectedAny === 'login'}
            />
          )}
          <Menu.Item
            onClick={(e: any) => props.onSelectLanguage(e, { value: props.selectableLanguageObject.value })}
          >
            {props.selectableLanguageObject.label}
          </Menu.Item>

          {props.companiesForDropdown.length > 1 && (
            <Menu.Item fitted>
              <SemanticDropdown
                item
                onChange={props.onSelectCompany}
                options={companiesForDropdownMobile}
                value={props.currentCompanySlug}
                direction={'left'}
              />
            </Menu.Item>
          )}
          {props.loggedIn && (
            <Menu.Item
              onClick={props.onButtonLogout}
              content={t('TOPBAR.BUTTON_LOGOUT')}
              name={t('TOPBAR.BUTTON_LOGOUT')}
              active={false}
            />
          )}
        </Menu>
      </Modal>
    </>
  );
}
