import {setCompanies, setCurrentCompany} from '../actions/company';
import {TCompanyObject} from '../api/dataTypes';
import {getListedCompanySlugs} from '../configs';
import {reducerBuilder} from '../redux/reduxUtil';

export const company = reducerBuilder<{
  companies: TCompanyObject[];
  currentCompany?: TCompanyObject;
  currentCompanySlug?: string;
}>({
  companies: [],
})
  .matchAction(setCompanies, (state, action) => {
    return {
      ...state,
      companies: action.payload.companies.filter(
        (company) =>
          company.slug &&
          company.streetAddress &&
          getListedCompanySlugs().includes(company.slug) &&
          company.active,
      ),
    };
  })
  .matchAction(setCurrentCompany, (state, action) => {
    return {
      ...state,
      currentCompany: action.payload.currentCompany,
      currentCompanySlug: action.payload.currentCompanySlug,
    };
  })
  .finish();
