import {resetDiscount, setDiscount} from '../actions/cart';
import {TDiscountCodeObject} from '../api/dataTypes';
import {reducerBuilder} from '../redux/reduxUtil';

type TState = {
  activeDiscount?: TDiscountCodeObject;
};

const initialState: TState = {};

export const discount = reducerBuilder<TState>(initialState)
  .matchAction(setDiscount, (state, action) => {
    return {
      ...state,
      activeDiscount: action.payload.discount,
    };
  })
  .matchAction(resetDiscount, (_state, _action) => {
    return initialState;
  })
  .finish();
