import {ROUTE_BASE} from '../routes';

export const apiURL = 'api/v1/';
// export const baseURL = 'http://localhost:8080/';
export const baseURL = 'https://umo-helsinki.herokuapp.com/';
export const pictureURL =
  'https://umo-helsinki.s3-eu-west-1.amazonaws.com/';
export const paymentReturnUrl = 'https://umo-helsinki.herokuapp.com/paymentReturn';
export const paymentReturnUrlTrigger = 'paymentReturn';

const version = '4.00';
const applicationType = 'mobile';

export type TApiError = number;

export type TApiResultData<T> = {
  code: number;
  message?: string;
  context: T;
};

export type TApiResult<T> = {
  data: TApiResultData<T>;
  error: TApiError;
};

let fetchRequestIdCounter = 0;

const fetchTimeoutResult = Symbol();
export const fetchTimeoutError = Error('gappFetchTimeout');
export const fetchDefaultTimeoutSeconds = 30;

export async function fetchGet<T>(options: {
  url: string;
  token?: string;
  timeoutSeconds?: number;
  headers?: Record<string, string>;
}): Promise<TApiResult<T>> {
  const thisFetchId = fetchRequestIdCounter;
  ++fetchRequestIdCounter;

  console.log(`fetchGet ${thisFetchId}, request`, options);

  const usedTimeoutMilliseconds = options.timeoutSeconds
    ? options.timeoutSeconds * 1000
    : fetchDefaultTimeoutSeconds * 1000;

  const auth = options.token
    ? {Authorization: `Bearer ${options.token}`}
    : null;

  let finalHeaders = {
    'Content-Type': 'application/json',
    ...auth,
  };

  if (options.headers && typeof options.headers === 'object') {
    Object.assign(finalHeaders, options.headers);
  }

  try {
    const requestBody = {
      method: 'GET',
      headers: finalHeaders,
    };

    const raceResult = await Promise.race([
      fetch(options.url, requestBody),
      delayPromise(usedTimeoutMilliseconds, fetchTimeoutResult),
    ]);

    if (raceResult === fetchTimeoutResult) {
      throw fetchTimeoutError;
    } else {
      const resultJson = await raceResult.json();

      console.log(`fetchGet ${thisFetchId}, response`, raceResult, resultJson);

      return resultJson;
    }
  } catch (error) {
    console.log(`fetchGet ${thisFetchId}, error`, error);

    throw error;
  }
}

export async function fetchPost<T>(options: {
  url: string;
  json: Object;
  token?: string;
  timeoutSeconds?: number;
  headers?: Record<string, string>;
}): Promise<TApiResult<T>> {
  const thisFetchId = fetchRequestIdCounter;
  ++fetchRequestIdCounter;

  console.log(`fetchPost ${thisFetchId}, request`, options);

  const usedTimeoutMilliseconds = options.timeoutSeconds
    ? options.timeoutSeconds * 1000
    : fetchDefaultTimeoutSeconds * 1000;

  const auth = options.token
    ? {Authorization: `Bearer ${options.token}`}
    : null;

  let finalHeaders = {
    'Content-Type': 'application/json',
    version,
    applicationType,
    ...auth,
  };

  if (options.headers && typeof options.headers === 'object') {
    Object.assign(finalHeaders, options.headers);
  }

  const requestBody = {
    method: 'POST',
    headers: finalHeaders,
    body: JSON.stringify(options.json),
  };

  try {
    const raceResult = await Promise.race([
      fetch(options.url, requestBody),
      delayPromise(usedTimeoutMilliseconds, fetchTimeoutResult),
    ]);

    if (raceResult === fetchTimeoutResult) {
      throw fetchTimeoutError;
    } else {
      const resultJson = await raceResult.json();

      console.log(`fetchPost ${thisFetchId}, response`, raceResult, resultJson);

      return resultJson;
    }
  } catch (error) {
    console.log(`fetchPost ${thisFetchId}, error`, error);

    throw error;
  }
}

export async function fetchPatch<T>(options: {
  url: string;
  json: Object;
  token?: string;
  timeoutSeconds?: number;
  headers?: Record<string, string>;
}): Promise<TApiResult<T>> {
  const thisFetchId = fetchRequestIdCounter;
  ++fetchRequestIdCounter;

  console.log(`fetchPatch ${thisFetchId}, request`, options);

  const usedTimeoutMilliseconds = options.timeoutSeconds
    ? options.timeoutSeconds * 1000
    : fetchDefaultTimeoutSeconds * 1000;

  const auth = options.token
    ? {Authorization: `Bearer ${options.token}`}
    : null;

  let finalHeaders = {
    'Content-Type': 'application/json',
    version,
    applicationType,
    ...auth,
  };

  if (options.headers && typeof options.headers === 'object') {
    Object.assign(finalHeaders, options.headers);
  }

  const requestBody = {
    method: 'PATCH',
    headers: finalHeaders,
    body: JSON.stringify(options.json),
  };

  try {
    const raceResult = await Promise.race([
      fetch(options.url, requestBody),
      delayPromise(usedTimeoutMilliseconds, fetchTimeoutResult),
    ]);

    if (raceResult === fetchTimeoutResult) {
      throw fetchTimeoutError;
    } else {
      const resultJson = await raceResult.json();

      console.log(
        `fetchPatch ${thisFetchId}, response`,
        raceResult,
        resultJson,
      );

      return resultJson;
    }
  } catch (error) {
    console.log(`fetchPatch ${thisFetchId}, error`, error);

    throw error;
  }
}

export function delayPromise<TResult>(
  timeMilliseconds: number,
  result: TResult,
): Promise<TResult> {
  return new Promise((resolve, _reject) => {
    setTimeout(() => {
      resolve(result);
    }, timeMilliseconds);
  });
}

export function getPaymentReturnUrl(): string {
  return window.location.origin + ROUTE_BASE + '/' + paymentReturnUrlTrigger;
}
