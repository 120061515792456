export const TermsOfService = () => (
  <>
    <h1>Terms of Service</h1>
    <p>By using the UMO Helsinki App and streaming.umohelsinki.fi, you agree to these Terms of Service.</p>
    <p>UMO Foundation sr Oy (later UMO Helsinki) 2202299-9 sells products and/or services to private persons in [Finland and abroad]. We reserve all rights to change prices and &ldquo;terms and conditions&rdquo;. All prices include VAT.</p>

    <h2>Contact us</h2>

    <p>e-mail: <a href="mailto:jazz@umohelsinki.fi">jazz@umohelsinki.fi</a><br />
    address: Tallberginkatu 1 / 139, 00180 Helsinki</p>

    <h2>Age limit</h2>

    <p>To Order any alcohol products on the UMO Helsinki App you must be 18 years or older.</p>

    <h2>Orders</h2>

    <p>Orders are made by the user and placed in the shopping cart that directs the order to the UMO Helsinki App. The order is confirmed by using the check-out function in the UMO Helsinki App for the purchase payment. By confirming the order, you accept the pricing and these terms and conditions.</p>

    <h2>Order confirmation and receipt</h2>

    <p>After an order and payment, all receipts will be available in &ldquo;history&rdquo; found in the UMO Helsinki App. Ordered products and the price breakdown are listed in the receipt. An e-mail confirmation will be delivered in case of a ticket order. The e-mail confirmation includes the purchased ticket and the receipt of the order.</p>
    
    <h2>Payment, cancellation &amp; refund of an order</h2>
    
    <p>The payment of the order is made in the UMO Helsinki App. The order is automatically charged from the user of the App the moment the order is made. After placing the order, it cannot be cancelled by the user. In regards to card payments, the UMO Helsinki reserves the right to make a funds provision when the order is placed. Should the card payment fail, an invoice can be sent to the user. The UMO Helsinki doesn&rsquo;t save or handle any payment information that could be used for making a payment. All the payments are handled by a PCI-certified payment partner. See the payment terms and information in the Payments chapter in the end of these terms. Should the delivery or part of the delivery fail because of the UMO Helsinki for any reason, the UMO Helsinki is responsible of making a refund for the user. Refund usually takes 1-3 weeks.</p>
    
    <h2>Saving customer data</h2>
    
    <p>All the customer data will be handled following the GDPR requirements. Data handling and protection principles are described in the Privacy Policy.</p>
    
    <h2>Delivery, collecting and claiming of the order</h2>
    
    <p>The order will be delivered to a delivery point selected by the user (eg. table, seat or otherwise designated place). The UMO Helsinki is obliged to retain undeliverable orders made via UMO Helsinki App for 30 minutes after the first delivery attempt.</p>
    
    <h2>Event Cancellation</h2>
    
    <p>In the event of cancellation, the user may request a reimbursement of the ticket purchase funds according to the payment method used.</p>
    
    <h2>Complaints</h2>
    
    <p>The UMO Helsinki and its partners are committed to delivering the products and services in accordance with the order. Should there appear any faults in the order or the service, we kindly request you to contact our service personnel.</p>

    <h2>Removing a user from the Service</h2>
    
    <p>Should a user misuse the UMO Helsinki App or cause any harm or detriment to the UMO Helsinki App or to the UMO Helsinki App partners, the owner of the UMO Helsinki App reserves the right to remove the user from the UMO Helsinki App with immediate effect.</p>

    <h2>Amendments to the Terms of Service</h2>
    
    <p>The UMO Helsinki App Terms of Service may be subject to amendments. The user of the UMO Helsinki App should cease using the UMO Helsinki App if he/she does not agree to the amended Terms of Service.</p>
    
    <h2>Payments</h2>
    
    <p>Visma Pay (Paybyway Oy, business-id FI24865594) is the payment facilitator of the UMO Helsinki App. Paybyway Oy is a payment facilitator authorized by the Financial Supervisory Authority of Finland. The payment process is conducted in the online service of Visma Pay. Visma Pay or Paybyway Oy is shown as the receiver of your payment in the bank account listing and in your invoice. Paying with Visma Pay is safe. All information is exchanged through secured connections.</p>
    
    <p>The trade happens between the online customer and the UMO Helsinki App. The UMO Helsinki is responsible for all obligations related to the trade. </p>
    
    <p>Read more about Visma Pay: https://www.visma.fi/vismapay/</p>
    
    <h3>Payment methods</h3>
    
    <p>With Visma Pay you can pay your order by an internet banking account, a wallet, a payment card (credit/debit), an invoice or a partial payment. In the UMO Helsinki App the following methods of payment are available: Visa-, Visa Debit-, Visa Electron-, MasterCard- and Debit MasterCard payment cards.</p>
    
    <h3>Contact Visma Pay</h3>
    
    <p>Visma Pay, Paybyway Oy (business-id FI24865594)<br />
    E-mail: helpdesk@vismapay.com<br />
    Telephone: +358 9 315 42 037 (workdays 8-16)<br />
    Address: Laserkatu 6, 53850 Lappeenranta, Finland</p>
  </>
);
