import {put, call} from 'typed-redux-saga';

import {setTickets} from '../actions/ticket';
import {getTickets} from '../api/ticket';
import {typedSelect} from '../redux/reduxUtil';

export function* refreshTickets() {
  const {currentCompanySlug, authToken, userProfile} = yield* typedSelect(
    (state) => ({
      currentCompanySlug: state.company.currentCompanySlug,
      authToken: state.auth.token,
      userProfile: state.auth.userProfile,
    }),
  );

  if (currentCompanySlug && authToken && userProfile && userProfile._id) {
    const ticketsResult = yield* call(
      getTickets,
      currentCompanySlug,
      userProfile._id,
      authToken,
    );

    if (!ticketsResult.error) {
      yield put(
        setTickets({
          tickets: ticketsResult.data.context,
        }),
      );
    }
  }
}
