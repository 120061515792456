import i18n from 'i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import translationsEn from './locales/locale-en.json';
import translationsFi from './locales/locale-fi.json';

export const languages = [
  {
    key: 'en',
    value: 'en',
    label: 'In English',
  },
  {
    key: 'fi',
    value: 'fi',
    label: 'Suomeksi',
  },
];

i18n.use(LanguageDetector).init({
  fallbackLng: 'en',
  debug: true,

  ns: ['translations'],
  defaultNS: 'translations',

  react: {
    wait: true,
  },
  interpolation: {escapeValue: false},
  resources: {
    en: {
      translations: translationsEn,
    },
    fi: {
      translations: translationsFi,
    },
  },
});

export const t = (key: string) => i18n.t(key);

export const format = (
  key: string,
  arg1?: string | number,
  arg2?: string | number,
  arg3?: string | number,
) => {
  let result = i18n.t(key);

  if (typeof arg1 === 'string') {
    result = result.replaceAll('{0}', arg1);
  } else if (typeof arg1 === 'number') {
    result = result.replaceAll('{0}', '' + arg1);
  }

  if (typeof arg2 === 'string') {
    result = result.replaceAll('{1}', arg2);
  } else if (typeof arg2 === 'number') {
    result = result.replaceAll('{1}', '' + arg2);
  }

  if (typeof arg3 === 'string') {
    result = result.replaceAll('{2}', arg3);
  } else if (typeof arg3 === 'number') {
    result = result.replaceAll('{2}', '' + arg3);
  }

  return result;
};

export function getCurrentLanguage(): string {
  return (i18n.language ? i18n.language : 'en').slice(0, 2);
}
