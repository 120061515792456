import React from 'react';
import {TURLObject} from '../api/dataTypes';
import {t} from '../i18n';

const facebookIcon = require('../img/logo_fb.png').default;
const youtubeIcon = require('../img/logo_youtube.png').default;
const spotifyIcon = require('../img/logo_spotify.png').default;
const homepageIcon = require('../img/logo_homepage.png').default;
const instagramIcon = require('../img/logo_instagram.png').default;
const twitterIcon = require('../img/logo_twitter.png').default;

export type TSocialMediaRowProps = {
  facebookUrl?: string;
  youtubeUrl?: string;
  spotifyUrl?: string;
  instagramUrl?: string;
  homepageUrl?: string;
  twitterUrl?: string;
  otherUrls?: TURLObject[];
  className?: string;
};

export type TSocialMediaButtonProps = {
  img: any;
  url: string;
  label?: string;
};

export function SocialMediaRow(props: TSocialMediaRowProps) {
  if (Object.keys(props).length === 0) {
    return null;
  }

  return (
    <div className={`SocialMediaRow ${props.className ? props.className : ''}`}>
      <div className={'SocialMediaRowHorizontal'}>
        {props.facebookUrl ? (
          <SocialMediaButton
            img={facebookIcon}
            url={props.facebookUrl}
            label={t('SOCIAL_MEDIA.FACEBOOK_UMO_EVENT')}
          />
        ) : null}
        {props.youtubeUrl ? (
          <SocialMediaButton img={youtubeIcon} url={props.youtubeUrl} />
        ) : null}
        {props.spotifyUrl ? (
          <SocialMediaButton img={spotifyIcon} url={props.spotifyUrl} />
        ) : null}
        {props.homepageUrl ? (
          <SocialMediaButton img={homepageIcon} url={props.homepageUrl} />
        ) : null}
        {props.instagramUrl ? (
          <SocialMediaButton img={instagramIcon} url={props.instagramUrl} />
        ) : null}
        {props.twitterUrl ? (
          <SocialMediaButton img={twitterIcon} url={props.twitterUrl} />
        ) : null}
      </div>
      {props.otherUrls &&
        props.otherUrls.map((urlObject) => {
          return (
            <a className="SocialMediaRowOtherUrl" href={urlObject.url}>
              {urlObject.name ? urlObject.name : urlObject.url}
            </a>
          );
        })}
    </div>
  );
}

export function SocialMediaButton(props: TSocialMediaButtonProps) {
  return (
    <div className="SocialMediaButton">
      <a href={props.url}>
        <div className="SocialMediaButtonContainer2">
          <img className="SocialMediaButtonIcon" alt={''} src={props.img} />
          {props.label && (
            <p className="SocialMediaButtonLabel">{props.label}</p>
          )}
        </div>
      </a>
    </div>
  );
}
