import {push, replace} from 'connected-react-router';
import localforage from 'localforage';
import {call, delay, fork, put, takeEvery, takeLatest} from 'typed-redux-saga';
import {setUserProfile} from '../actions/auth';
import {
  addEventToCart,
  addProductToCart,
  clearCart,
  discountCart,
  editCart,
  enduserActivateDiscount,
  refreshCartDiscount,
  removeEventFromCart,
  removeProductFromCart,
  resetDiscount,
  setDiscount,
} from '../actions/cart';
import {showLightLoadingScreen} from '../actions/loading';
import {
  enduserAddNewCard,
  enduserNavigateToPaymentSettingsPage,
  enduserPaymentSettingsPay,
  enduserPaymentSettingsSetDefaultCard,
  setOngoingPaymentData,
  setReceiptInput,
} from '../actions/payment';
import {alert, alertPromise, TPerAlertDataPartial} from '../alert';
import {
  TDiscountCodeObject,
  TOrder,
  TReceiptInputObject,
} from '../api/dataTypes';
import {getDiscount} from '../api/discount';
import {patchProfile} from '../api/profile';
import {
  addAndChargeCard,
  addCard,
  chargeCard,
  finishOffsitePayment,
} from '../api/receipt';
import {localStorageKeys} from '../configs';
import {t} from '../i18n';
import {TOffsitePaymentType} from '../reducers/payment';
import {ActionOfActionCreator, typedSelect} from '../redux/reduxUtil';
import {routes} from '../routes';
import {needsLoginComplaintAlert, refreshUserProfile} from './auth';
import {refreshTickets} from './ticket';

type TPersistedPaymentProcess = {
  cart: TOrder;
  receipt?: TReceiptInputObject | undefined;
  payformPaymentUrl?: string | undefined;
  payformPaymentToken?: string | undefined;
  offsitePaymentType?: TOffsitePaymentType | undefined;
  discount?: TDiscountCodeObject;
};

export function* persistedPaymentProcessClear() {
  yield* call(async () => {
    return localforage.removeItem(localStorageKeys.paymentProcess);
  });
}

export function* persistedPaymentProcessSave() {
  const {cart, statePayment, activeDiscount} = yield* typedSelect((state) => ({
    cart: state.cart,
    statePayment: state.payment,
    activeDiscount: state.discount.activeDiscount,
  }));

  const newPersistedPaymentProcess: TPersistedPaymentProcess = {
    cart: cart,
    receipt: statePayment.receiptInput,
    payformPaymentToken: statePayment.payformPaymentToken,
    payformPaymentUrl: statePayment.payformPaymentUrl,
    offsitePaymentType: statePayment.offsitePaymentType,
    discount: activeDiscount,
  };

  yield* call(async () => {
    return localforage.setItem(
      localStorageKeys.paymentProcess,
      newPersistedPaymentProcess,
    );
  });
}

export function* persistedPaymentProcessLoad() {
  const persistedCart: TPersistedPaymentProcess | undefined | null =
    yield* call(async () => {
      return localforage.getItem(localStorageKeys.paymentProcess) as any;
    });

  if (
    persistedCart &&
    typeof persistedCart === 'object' &&
    typeof persistedCart.cart === 'object' &&
    Array.isArray(persistedCart.cart.orderLines)
  ) {
    yield put(editCart({order: persistedCart.cart}));
    yield put(setReceiptInput({receiptInput: persistedCart.receipt}));
    yield put(
      setOngoingPaymentData({
        payformPaymentToken: persistedCart.payformPaymentToken,
        payformPaymentUrl: persistedCart.payformPaymentUrl,
        offsitePaymentType: persistedCart.offsitePaymentType,
      }),
    );

    if (persistedCart.discount) {
      yield put(setDiscount({discount: persistedCart.discount}));
    } else {
      const {cart} = yield* typedSelect((state) => ({
        cart: state.cart,
      }));
      yield put(resetDiscount({cart}));
    }

    yield put(refreshCartDiscount({}));
  }
}

function* onAnyCartAction() {
  yield* delay(1000);
  yield* call(persistedPaymentProcessSave);
}

function* onEnduserPaymentSettingsPay(
  action: ActionOfActionCreator<typeof enduserPaymentSettingsPay>,
) {
  const {authToken, currentCompanySlug} = yield* typedSelect((state) => ({
    authToken: state.auth.token,
    currentCompanySlug: state.company.currentCompanySlug,
  }));

  if (authToken && currentCompanySlug) {
    let errorAlert: TPerAlertDataPartial = {
      title: t('PAYMENT_SETTINGS_PAGE.PAYMENT_ERROR_TITLE'),
      message: t('PAYMENT_SETTINGS_PAGE.PAYMENT_ERROR_MESSAGE'),
    };

    let success = false;

    try {
      yield* put(showLightLoadingScreen({show: true}));

      if (action.payload.paymentMethod === 'existingCard') {
        const chargeCartResult = yield* call(
          chargeCard,
          currentCompanySlug,
          authToken,
          action.payload.receiptInput,
        );

        if (
          chargeCartResult.data &&
          chargeCartResult.data.context &&
          chargeCartResult.error === 0
        ) {
          alert({
            title: t('PAYMENT_SETTINGS_PAGE.PAYMENT_SUCCESS_TITLE'),
            message: t('PAYMENT_SETTINGS_PAGE.PAYMENT_SUCCESS_MESSAGE'),
          });

          success = true;

          yield* put(clearCart({}));

          yield fork(function* () {
            try {
              yield* call(refreshTickets);
            } catch (_e) {}
          });

          yield put(replace(routes.root()));
        } else if (chargeCartResult.error && chargeCartResult.error !== 0) {
          if (chargeCartResult.data.code === 1122) {
            // error = receiptValidationError
            if (Array.isArray(chargeCartResult.data.context)) {
              for (const e of chargeCartResult.data.context) {
                if (typeof e === 'object') {
                  if (e.code === 1114) {
                    errorAlert = {
                      title: t('PAYMENT_SETTINGS_PAGE.PAYMENT_ERROR_TITLE'),
                      message: t('CART.ERROR_1114'),
                    };
                  } else if (e.code === 1217) {
                    errorAlert = {
                      title: t('PAYMENT_SETTINGS_PAGE.PAYMENT_ERROR_TITLE'),
                      message: t('CART.DISCOUNT_CODE_ERROR_CODE_EXPIRED'),
                    };
                  }
                }
              }
            }
          } else if (chargeCartResult.data.code === 1709) {
            // error = 3d auth needed for this payment

            const {auth_url: payform3DAuthUrl, token: payform3DAuthToken} =
              chargeCartResult.data.context as any;

            success = true;

            yield* call(
              startOffsitePayment as any,
              payform3DAuthToken,
              payform3DAuthUrl,
              'paymentWithExistingCard',
            );
          } else if (chargeCartResult.data.code === 1710) {
            // error = pre-orders are not allowed at this time
          }
        }
      } else {
        const addAndChargeCardResult = yield* call(
          addAndChargeCard,
          currentCompanySlug,
          authToken,
          action.payload.receiptInput,
        );

        if (
          addAndChargeCardResult.data &&
          addAndChargeCardResult.data.context &&
          addAndChargeCardResult.error === 0
        ) {
          if (action.payload.paymentMethod === 'discount100') {
            alert({
              title: t('PAYMENT_SETTINGS_PAGE.PAYMENT_SUCCESS_TITLE'),
              message: t('PAYMENT_SETTINGS_PAGE.PAYMENT_SUCCESS_MESSAGE'),
            });

            success = true;

            yield* put(clearCart({}));

            yield fork(function* () {
              try {
                yield* call(refreshTickets);
              } catch (_e) {}
            });

            yield put(replace(routes.root()));
          } else {
            const {url: payformPaymentUrl, token: payformPaymentToken} =
              addAndChargeCardResult.data.context as any;

            success = true;

            yield* call(
              startOffsitePayment as any,
              payformPaymentToken,
              payformPaymentUrl,
              'paymentWithNewCard',
            );
          }
        } else if (
          addAndChargeCardResult.error &&
          addAndChargeCardResult.error !== 0
        ) {
          if (addAndChargeCardResult.data.code === 1122) {
            // error = receiptValidationError
            if (Array.isArray(addAndChargeCardResult.data.context)) {
              for (const e of addAndChargeCardResult.data.context) {
                if (typeof e === 'object') {
                  if (e.code === 1114) {
                    errorAlert = {
                      title: t('PAYMENT_SETTINGS_PAGE.PAYMENT_ERROR_TITLE'),
                      message: t('CART.ERROR_1114'),
                    };
                  } else if (e.code === 1217) {
                    errorAlert = {
                      title: t('PAYMENT_SETTINGS_PAGE.PAYMENT_ERROR_TITLE'),
                      message: t('CART.DISCOUNT_CODE_ERROR_CODE_EXPIRED'),
                    };
                  }
                }
              }
            }
          } else if (addAndChargeCardResult.data.code === 1710) {
            // error = pre-orders are not allowed at this time
          }
        }
      }
    } catch (e) {}

    if (!success) {
      alert(errorAlert);
    }

    yield* put(showLightLoadingScreen({show: false}));
  } else {
    yield* call(needsLoginComplaintAlert, t('CART.LOGIN_REQUIRED_MESSAGE'));
  }
}

function* onEnduserPaymentSettingsSetDefaultCard(
  action: ActionOfActionCreator<typeof enduserPaymentSettingsSetDefaultCard>,
) {
  const {authToken, userProfile} = yield* typedSelect((state) => ({
    authToken: state.auth.token,
    userProfile: state.auth.userProfile,
  }));

  if (authToken && userProfile && Array.isArray(userProfile.cards)) {
    if (action.payload.cardIndex < userProfile.cards.length) {
      const newCards = userProfile.cards.map((oldCard, cardIndex) => {
        return {...oldCard, isDefault: cardIndex === action.payload.cardIndex};
      });

      const patchProfileResult = yield* call(patchProfile, authToken, {
        cards: newCards,
      });

      if (!patchProfileResult.error) {
        yield put(
          setUserProfile({
            userProfile: patchProfileResult.data.context,
          }),
        );
      }
    }
  }
}

function* startOffsitePayment(
  payformPaymentToken: string,
  payformPaymentUrl: string,
  offsitePaymentType: TOffsitePaymentType,
) {
  yield put(
    setOngoingPaymentData({
      payformPaymentToken: payformPaymentToken,
      payformPaymentUrl: payformPaymentUrl,
      offsitePaymentType: offsitePaymentType,
    }),
  );

  yield* call(persistedPaymentProcessSave);

  window.location.replace(payformPaymentUrl);
}

export function* returnFromOffsitePayment(urlParams: Record<string, string>) {
  yield put(replace(routes.root()));

  const {
    authToken,
    currentCompanySlug,
    payformPaymentToken,
    offsitePaymentType,
  } = yield* typedSelect((state) => ({
    authToken: state.auth.token,
    currentCompanySlug: state.company.currentCompanySlug,
    payformPaymentToken: state.payment.payformPaymentToken,
    offsitePaymentType: state.payment.offsitePaymentType,
  }));

  if (
    authToken &&
    currentCompanySlug &&
    payformPaymentToken &&
    offsitePaymentType
  ) {
    let success = false;

    if (urlParams['RETURN_CODE'] === '0') {
      const finishOffsitePaymentResult = yield* call(
        finishOffsitePayment,
        currentCompanySlug,
        authToken,
        payformPaymentToken,
        offsitePaymentType,
      );

      if (finishOffsitePaymentResult.error === 0) {
        success = true;
      }
    }

    if (success === true) {
      if (
        offsitePaymentType === 'addNewCard' ||
        offsitePaymentType === 'paymentWithNewCard'
      ) {
        // refreshUserProfile is needed for the newly added card to show up in the frontend UI
        yield fork(function* () {
          try {
            yield* call(refreshUserProfile);
          } catch (_e) {}
        });
      }

      if (offsitePaymentType !== 'addNewCard') {
        yield fork(function* () {
          try {
            yield* call(refreshTickets);
          } catch (_e) {}
        });
      }

      if (offsitePaymentType === 'addNewCard') {
        alert({
          title: t('PAGE_PROFILE.ADD_CARD_SUCCESS_TITLE'),
          message: t('PAGE_PROFILE.ADD_CARD_SUCCESS_MESSAGE'),
        });
      } else {
        alert({
          title: t('PAYMENT_SETTINGS_PAGE.PAYMENT_SUCCESS_TITLE'),
          message: t('PAYMENT_SETTINGS_PAGE.PAYMENT_SUCCESS_MESSAGE'),
        });

        yield* put(clearCart({}));
      }
    } else {
      if (offsitePaymentType === 'addNewCard') {
        alert({
          title: t('ERROR_GENERIC.ERROR_TITLE_GENERIC'),
          message: t('PAGE_PROFILE.ERROR_ADD_NEW_CARD_MESSAGE'),
        });
      } else {
        alert({
          title: t('PAYMENT_SETTINGS_PAGE.PAYMENT_ERROR_TITLE'),
          message: t('PAYMENT_SETTINGS_PAGE.PAYMENT_ERROR_MESSAGE'),
        });
      }
    }
  }
}

function* onEnduserAddNewCard() {
  let success = false;
  let canceled = false;

  const {authToken, currentCompanySlug} = yield* typedSelect((state) => ({
    authToken: state.auth.token,
    currentCompanySlug: state.company.currentCompanySlug,
  }));

  if (authToken && currentCompanySlug) {
    const alertResult = yield* call(alertPromise, {
      title: t('PAGE_PROFILE.ADD_CARD_WARNING_TITLE'),
      message: t('PAGE_PROFILE.ADD_CARD_WARNING_MESSAGE'),
      buttons: [
        {
          label: t('BUTTON_GENERIC.BUTTON_OK'),
          resultValue: 'ok',
        },
        {
          label: t('BUTTON_GENERIC.BUTTON_CANCEL'),
          resultValue: 'cancel',
        },
      ],
    });

    if (alertResult === 'ok') {
      const addCardResult = yield* call(addCard, currentCompanySlug, authToken);

      if (
        addCardResult.data &&
        addCardResult.data.context &&
        addCardResult.error === 0
      ) {
        const {url: payformPaymentUrl, token: payformPaymentToken} =
          addCardResult.data.context as any;

        success = true;

        yield* call(
          startOffsitePayment as any,
          payformPaymentToken,
          payformPaymentUrl,
          'addNewCard',
        );
      }
    } else {
      canceled = true;
    }
  }

  if (success === false && canceled === false) {
    alert({
      title: t('ERROR_GENERIC.ERROR_TITLE_GENERIC'),
      message: t('PAGE_PROFILE.ERROR_ADD_NEW_CARD_MESSAGE'),
    });
  }
}

export function* onEnduserActivateDiscount(
  action: ActionOfActionCreator<typeof enduserActivateDiscount>,
) {
  let success = false;

  const trimmedCode = action.payload.code.trim();
  const sanitizedCode = trimmedCode.replace(/[^a-zA-Z0-9]/g, '');

  if (sanitizedCode !== '') {
    const {authToken, currentCompanySlug} = yield* typedSelect((state) => ({
      authToken: state.auth.token,
      currentCompanySlug: state.company.currentCompanySlug,
    }));

    if (authToken && currentCompanySlug) {
      const fetchResult = yield* call(
        getDiscount,
        currentCompanySlug,
        authToken,
        action.payload.code,
      );

      if (
        fetchResult.data &&
        fetchResult.data.context &&
        fetchResult.error === 0
      ) {
        yield* put(setDiscount({discount: fetchResult.data.context}));

        success = true;
      }
    }
  }

  if (success === false) {
    if (sanitizedCode.length > 0) {
      alert({
        message: t('CART.DISCOUNT_CODE_ERROR_INVALID_CODE_MESSAGE'),
      });
    }

    const {cart} = yield* typedSelect((state) => ({
      cart: state.cart,
    }));

    yield* put(resetDiscount({cart}));
  }
}

export function* onSetDiscount(
  action: ActionOfActionCreator<typeof setDiscount>,
) {
  const {cart} = yield* typedSelect((state) => ({
    cart: state.cart,
  }));

  yield* put(discountCart({discount: action.payload.discount, cart}));
}

export function* onRefreshCartDiscount() {
  const {cart, activeDiscount} = yield* typedSelect((state) => ({
    cart: state.cart,
    activeDiscount: state.discount.activeDiscount,
  }));

  if (cart.orderLines.length > 0 && activeDiscount) {
    yield* put(setDiscount({discount: activeDiscount}));
  } else {
    const {cart} = yield* typedSelect((state) => ({
      cart: state.cart,
    }));

    yield* put(resetDiscount({cart}));
  }
}

export function* onEnduserNavigateToPaymentSettingsPage(
  action: ActionOfActionCreator<typeof enduserNavigateToPaymentSettingsPage>,
) {
  const {userProfile} = yield* typedSelect((state) => ({
    userProfile: state.auth.userProfile,
  }));

  if (userProfile && userProfile._id) {
    const receiptInput: typeof action.payload.receiptInput = {
      ...action.payload.receiptInput,
      customer: userProfile._id,
    };

    yield* put(
      setReceiptInput({
        receiptInput: receiptInput,
      }),
    );

    yield* put(push(routes.payment()));
  } else {
    yield* call(needsLoginComplaintAlert, t('CART.LOGIN_REQUIRED_MESSAGE'));
  }
}

export function* watchCart() {
  yield* takeLatest(
    [
      clearCart.actionType,
      editCart.actionType,
      addEventToCart.actionType,
      addProductToCart.actionType,
      removeEventFromCart.actionType,
      removeProductFromCart.actionType,
      discountCart.actionType,
      resetDiscount.actionType,
      setReceiptInput.actionType,
      setOngoingPaymentData.actionType,
    ],
    onAnyCartAction,
  );
  yield* takeEvery(
    enduserPaymentSettingsPay.actionType,
    onEnduserPaymentSettingsPay,
  );
  yield* takeEvery(enduserAddNewCard.actionType, onEnduserAddNewCard);

  yield* takeEvery(
    enduserPaymentSettingsSetDefaultCard.actionType,
    onEnduserPaymentSettingsSetDefaultCard,
  );

  yield* takeEvery(
    enduserActivateDiscount.actionType,
    onEnduserActivateDiscount,
  );

  yield* takeEvery(setDiscount.actionType, onSetDiscount);

  yield* takeEvery(refreshCartDiscount.actionType, onRefreshCartDiscount);

  yield* takeEvery(
    enduserNavigateToPaymentSettingsPage.actionType,
    onEnduserNavigateToPaymentSettingsPage,
  );
}
