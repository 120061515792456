import {
  setAuthToken,
  setOngoingForgotPassword,
  setOngoingRegister,
  setUserProfile,
} from '../actions/auth';
import {TProfileObject} from '../api/dataTypes';
import {reducerBuilder} from '../redux/reduxUtil';

export type TOngoingRegister = {
  email: string;
  password: string;
};

export type TOngoingForgotPassword = {
  email: string;
};

export const auth = reducerBuilder<{
  token?: string;
  userProfile?: TProfileObject;
  ongoingRegister?: TOngoingRegister;
  ongoingForgotPassword?: TOngoingForgotPassword;
}>({
  token: undefined,
})
  .matchAction(setAuthToken, (state, action) => {
    return {
      ...state,
      token: action.payload.token,
    };
  })
  .matchAction(setUserProfile, (state, action) => {
    return {
      ...state,
      userProfile: action.payload.userProfile,
    };
  })
  .matchAction(setOngoingRegister, (state, action) => {
    return {
      ...state,
      ongoingRegister: action.payload,
    };
  })
  .matchAction(setOngoingForgotPassword, (state, action) => {
    return {
      ...state,
      ongoingForgotPassword: action.payload,
    };
  })
  .finish();
