import {baseURL, fetchPatch, fetchPost, TApiResult} from './baseapi';
import {
  TProfileObject,
  TRegisterRequestObject,
  TResendRegisterCodeRequestObject,
  TResetPasswordRequestObject,
} from './dataTypes';

export type TLoginResult = string;
export type TRegisterResult = TApiResult<TProfileObject>;
export type TResendRegisterCodeResult = TApiResult<TProfileObject>;
export type TRegisterSendConfirmationCodeResult = TApiResult<TProfileObject>;
export type TForgotPasswordRequestResetResult = TApiResult<{}>;
export type TForgotPasswordReset = TApiResult<TProfileObject>;

export async function doLogin(
  username: string,
  password: string,
): Promise<TApiResult<TLoginResult>> {
  console.log('API, doLogin, request');

  const response = await fetchPost<TLoginResult>({
    url: `${baseURL}auth/local`,
    json: {
      email: username,
      password,
    },
  });

  if (response.error) {
    console.error('API, doLogin, error', response);
  } else {
    console.log('API, doLogin, response', response);
  }

  return response;
}

export async function doRegister(
  request: TRegisterRequestObject,
): Promise<TApiResult<TLoginResult>> {
  console.log('API, doRegister, request');

  const response = await fetchPost<TLoginResult>({
    url: `${baseURL}auth/local/signup`,
    json: request,
  });

  if (response.error) {
    console.error('API, doRegister, error', response);
  } else {
    console.log('API, doRegister, response', response);
  }

  return response;
}

export async function doResendRegisterCode(
  request: TResendRegisterCodeRequestObject,
): Promise<TApiResult<TResendRegisterCodeResult>> {
  console.log('API, doResendRegisterCode, request');

  const response = await fetchPost<TResendRegisterCodeResult>({
    url: `${baseURL}auth/local/resend_register_code`,
    json: request,
  });

  if (response.error) {
    console.error('API, doResendRegisterCode, error', response);
  } else {
    console.log('API, doResendRegisterCode, response', response);
  }

  return response;
}

export async function doRegisterSendConfirmationCode(
  request: TResetPasswordRequestObject,
  shortCode: string,
): Promise<TApiResult<TRegisterSendConfirmationCodeResult>> {
  console.log('API, doRegisterSendConfirmationCode, request');

  const response = await fetchPatch<TRegisterSendConfirmationCodeResult>({
    url: `${baseURL}auth/local/reset/${shortCode}`,
    json: request,
  });

  if (response.error) {
    console.error('API, doRegisterSendConfirmationCode, error', response);
  } else {
    console.log('API, doRegisterSendConfirmationCode, response', response);
  }

  return response;
}

export async function doForgotPasswordRequestReset(
  email: string,
  language: string,
  shortCode: boolean,
): Promise<TApiResult<TForgotPasswordRequestResetResult>> {
  console.log('API, doForgotPasswordRequestReset, request');

  const response = await fetchPost<TForgotPasswordRequestResetResult>({
    url: `${baseURL}auth/local/reset`,
    json: {
      email,
      language,
      shortCode,
    },
  });

  if (response.error) {
    console.error('API, doForgotPasswordRequestReset, error', response);
  } else {
    console.log('API, doForgotPasswordRequestReset, response', response);
  }

  return response;
}

export async function doForgotPasswordReset(
  request: TResetPasswordRequestObject,
  shortCode: string,
): Promise<TApiResult<TForgotPasswordReset>> {
  console.log('API, doForgotPasswordReset, request');

  const response = await fetchPatch<TForgotPasswordReset>({
    url: `${baseURL}auth/local/reset/${shortCode}`,
    json: request,
  });

  if (response.error) {
    console.error('API, doForgotPasswordReset, error', response);
  } else {
    console.log('API, doForgotPasswordReset, response', response);
  }

  return response;
}
