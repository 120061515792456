import {call, put, take, takeLatest} from 'typed-redux-saga';

import {ActionOfActionCreator, typedSelect} from '../redux/reduxUtil';
import {
  enduserNavigateToStreamPlayerPageOfEvent,
  enduserOndemandClipSelectionModalChoice,
  setShowOndemandClipSelectionModal,
  setVideoStreamViewProps,
} from '../actions/stream';
import {
  eventOndemandStreamStillAvailable,
  streamEventIsLiveNow,
  streamOndemandOwned,
  streamOwned,
  videoStreamViewPropsFromEventAndUrl,
} from '../util';
import {push} from 'connected-react-router';
import {
  getStreamOndemandPlaybackUrls,
  getStreamPlaybackUrl,
} from '../api/stream';
import {alert} from '../alert';
import {t} from '../i18n';
import {routes} from '../routes';

export function* onEnduserNavigateToStreamPlayerPageOfEvent(
  action: ActionOfActionCreator<
    typeof enduserNavigateToStreamPlayerPageOfEvent
  >,
) {
  const {currentCompanySlug, authToken, tickets} = yield* typedSelect(
    (state) => ({
      currentCompanySlug: state.company.currentCompanySlug,
      authToken: state.auth.token,
      tickets: state.ticket.tickets,
    }),
  );

  const event = action.payload.event;

  let errorTitle = '';
  let errorMessage = '';

  if (event.streamInfo && currentCompanySlug && authToken) {
    if (streamEventIsLiveNow(event)) {
      if (streamOwned(event._id, tickets)) {
        const playbackUrlResult = yield* call(
          getStreamPlaybackUrl,
          currentCompanySlug,
          authToken,
          event.streamInfo.streamId,
        );

        if (playbackUrlResult.error) {
          errorTitle = t('STREAM.LIVE_STREAM_STARTUP_ERROR_TITLE');
          errorMessage = t('STREAM.LIVE_STREAM_STARTUP_ERROR_MESSAGE');
        } else {
          if (!playbackUrlResult.data.context.status || playbackUrlResult.data.context.status !== 'active') {
            alert({
              title: t('STREAM.STREAM_ERROR_MESSAGE'),
              message: t('STREAM.STREAM_ERROR_STATUS_TITLE'),
            })

            return;
          }

          yield put(
            setVideoStreamViewProps({
              videoStreamViewProps: videoStreamViewPropsFromEventAndUrl(
                event,
                playbackUrlResult.data.context.playbackUrl,
                0,
              ),
            }),
          );

          yield put(push(routes.watchStream(event._id)));
        }
      }
    } else if (eventOndemandStreamStillAvailable(event)) {
      if (streamOndemandOwned(event._id, tickets)) {
        const ondemandPlaybackUrlsResult = yield* call(
          getStreamOndemandPlaybackUrls,
          currentCompanySlug,
          authToken,
          event.streamInfo.streamId,
        );

        if (ondemandPlaybackUrlsResult.error) {
          errorTitle = t('STREAM.ONDEMAND_STREAM_STARTUP_ERROR_TITLE');
          errorMessage = t('STREAM.ONDEMAND_STREAM_STARTUP_ERROR_MESSAGE');
        } else {
          const ondemandObjects = ondemandPlaybackUrlsResult.data.context;

          if (ondemandObjects.length === 1) {
            yield put(
              setVideoStreamViewProps({
                videoStreamViewProps: videoStreamViewPropsFromEventAndUrl(
                  event,
                  ondemandObjects[0].playbackUrl,
                  (ondemandObjects[0].introLengthMS || 0) / 1000,
                ),
              }),
            );

            yield put(push(routes.watchStream(event._id)));
          } else if (ondemandObjects.length > 1) {
            yield put(
              setShowOndemandClipSelectionModal({
                show: true,
                choices: ondemandObjects.map((ondemandObject) => ({
                  fullProps: videoStreamViewPropsFromEventAndUrl(
                    event,
                    ondemandObject.playbackUrl,
                    (ondemandObject.introLengthMS || 0) / 1000,
                  ),
                  startDate: ondemandObject.startDate,
                  endDate: ondemandObject.endDate,
                })),
              }),
            );

            const action: ActionOfActionCreator<
              typeof enduserOndemandClipSelectionModalChoice
            > = yield* take(
              enduserOndemandClipSelectionModalChoice.actionType,
            ) as any;

            yield put(
              setVideoStreamViewProps({
                videoStreamViewProps: action.payload.choice.fullProps,
              }),
            );

            yield put(push(routes.watchStream(event._id)));
          }
        }
      }
    }
  }

  if (errorTitle || errorMessage) {
    alert({
      title: errorTitle,
      message: errorMessage,
    });
  }
}

export function* watchStream() {
  yield* takeLatest(
    enduserNavigateToStreamPlayerPageOfEvent.actionType,
    onEnduserNavigateToStreamPlayerPageOfEvent,
  );
}
