import {TEventObject} from '../api/dataTypes';
import {
  TOndemandStreamWithFullProps,
  TVideoStreamViewProps,
} from '../commonTypes';
import {actionCreator} from '../redux/reduxUtil';

export const enduserNavigateToStreamPlayerPageOfEvent = actionCreator<{
  event: TEventObject;
}>('stream.enduserNavigateToStreamPlayerPageOfEvent');

export const setVideoStreamViewProps = actionCreator<{
  videoStreamViewProps?: TVideoStreamViewProps | undefined;
}>('stream.setVideoStreamViewProps');

export const setShowOndemandClipSelectionModal = actionCreator<{
  show: boolean;
  choices?: TOndemandStreamWithFullProps[];
}>('stream.setShowOndemandClipSelectionModal');

export const enduserOndemandClipSelectionModalChoice = actionCreator<{
  choice: TOndemandStreamWithFullProps;
}>('stream.enduserOndemandClipSelectionModalChoice');
