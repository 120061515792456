import React from 'react';
import {EventList} from './EventList';

export function HomePage() {
  return (
    <div className="HomePage">
      <EventList />
    </div>
  );
}
