import {setTickets} from '../actions/ticket';
import {TTicketObject} from '../api/dataTypes';
import {reducerBuilder} from '../redux/reduxUtil';

export const ticket = reducerBuilder<{
  tickets: TTicketObject[];
}>({
  tickets: [],
})
  .matchAction(setTickets, (state, action) => {
    return {
      ...state,
      tickets: action.payload.tickets,
    };
  })
  .finish();
