import React from 'react';
import moment from 'moment';

import {TEventObject} from '../api/dataTypes';
import {useTypedSelector} from '../redux/reduxUtil';
import {
  eventIsFinished,
  eventLiveStreamStillAvailable,
  eventOndemandStreamAvailableNowOrLater,
  isAnnouncementEvent,
  isGiftEvent,
} from '../util';
import {EventElement} from './EventElement';
import {Button, Grid} from 'semantic-ui-react';
import {useParams} from 'react-router-dom';
import {t} from '../i18n';
import {useDispatch} from 'react-redux';
import {push} from 'connected-react-router';
import {routes} from '../routes';

export function EventList() {
  const dispatch = useDispatch();

  const {events} = useTypedSelector((state) => ({
    events: state.events.events,
  }));

  const urlParams = useParams<{sublist: string}>();

  const sublistParam = urlParams.sublist || '';

  const selectedSublistIsOndemand = sublistParam === 'ondemand';

  const onTopbuttonLive = () => {
    dispatch(push(routes.root()));
  };

  const onTopbuttonOndemand = () => {
    dispatch(push(routes.tabHome('ondemand')));
  };

  let sublistElement;

  if (selectedSublistIsOndemand) {
    sublistElement = (
      <EventListSublist
        events={events
          .filter(eventOndemandStreamAvailableNowOrLater)
          .filter((event) => Boolean(event.gigStartDate))
          .sort(
            (eventA, eventB) =>
              moment(eventA.gigStartDate!).unix() -
              moment(eventB.gigStartDate!).unix(),
          )}
        emptyListMessage={t('EVENT_LIST.EMPTY_SUBLIST_ONDEMAND')}
      />
    );
  } else {
    sublistElement = (
      <EventListSublist
        events={events.filter(
          (event) =>
            !eventIsFinished(event) || eventLiveStreamStillAvailable(event),
        )}
        emptyListMessage={t('EVENT_LIST.EMPTY_SUBLIST_EVENT')}
      />
    );
  }

  return (
    <div className="EventList">
      <div className="EventListTopButtons">
        <div>
          <Button
            className="EventListTopButton"
            inverted
            size="big"
            active={!selectedSublistIsOndemand}
            onClick={onTopbuttonLive}>
            {t('EVENT_LIST.TOP_BUTTON_LIVE')}
          </Button>
          <Button
            className="EventListTopButton"
            inverted
            size="big"
            active={selectedSublistIsOndemand}
            onClick={onTopbuttonOndemand}>
            {t('EVENT_LIST.TOP_BUTTON_ONDEMAND')}
          </Button>
        </div>
      </div>

      {sublistElement}
    </div>
  );
}

export function EventListSublist(props: {
  events: TEventObject[];
  emptyListMessage?: string;
}) {
  let sortedEvents = [...props.events];

  const giftIndex = sortedEvents.findIndex((event) => isGiftEvent(event._id));

  if (giftIndex >= 0) {
    const giftEvent = sortedEvents.splice(giftIndex, 1);

    sortedEvents.unshift(...giftEvent);
  }

  const announcementEvents = sortedEvents.filter((event) =>
    isAnnouncementEvent(event),
  );
  const nonAnnouncementEvents = sortedEvents.filter(
    (event) => !isAnnouncementEvent(event),
  );

  sortedEvents = [...announcementEvents, ...nonAnnouncementEvents];

  return (
    <div className="EventListSublist">
    <Grid>
      {sortedEvents.map((event) => {
        return <EventElement key={event._id} event={event} />;
      })}
    </Grid>

      {sortedEvents.length === 0 && Boolean(props.emptyListMessage) && (
        <p className="EventListSublistEmptyMessage">{props.emptyListMessage}</p>
      )}
    </div>
  );
}
