import Linkify from 'react-linkify';

import {useTypedSelector} from '../redux/reduxUtil';
import {getNameObject} from '../util';

export const IntroductionPage = () => {
  const company = useTypedSelector((state) => state.company?.currentCompany);
  const introductionInfo = company?.introduction;

  return (
    <div className="IntroductionPage">
      {introductionInfo && (
        <div>
          {introductionInfo ? (
            <Linkify>
              <p className="IntroductionText">
                {getNameObject(introductionInfo)}
              </p>
            </Linkify>
          ) : null}
        </div>
      )}
    </div>
  );
};
