import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router';
import {Link} from 'react-router-dom';
import {Input, Button, Form, Checkbox} from 'semantic-ui-react';
import {enduserProfileConfirmChanges} from '../actions/profile';
import {alert} from '../alert';
import {t} from '../i18n';
import {useLoggedIn} from '../redux/reduxHooks';
import {useTypedSelector} from '../redux/reduxUtil';
import {routes} from '../routes';
import {PageWithLoginComplaint} from './LoginRequiredComplaint';
import {Topbar} from './Topbar';

export function ProfilePage() {
  const dispatch = useDispatch();
  const {existingUserProfile} = useTypedSelector((state) => ({
    existingUserProfile: state.auth.userProfile,
  }));
  const loggedIn = useLoggedIn();

  const [firstName, setFirstName] = useState(
    existingUserProfile?.firstName || '',
  );
  const [lastName, setLastName] = useState(existingUserProfile?.lastName || '');
  const [canSendVendorEmail, setCanSendVendorEmail] = useState(
    existingUserProfile?.canSendVendorEmail || false,
  );

  useEffect(() => {
    if(
        existingUserProfile?.firstName &&
        existingUserProfile?.lastName &&
        !firstName &&
        !lastName
      ) {
      setFirstName(existingUserProfile?.firstName);
      setLastName(existingUserProfile?.lastName);
      setCanSendVendorEmail(existingUserProfile?.canSendVendorEmail || false);
    }
  }, [
      existingUserProfile?.canSendVendorEmail,
      existingUserProfile?.firstName,
      existingUserProfile?.lastName,
      firstName,
      lastName
    ]);

  const onButtonConfirmChanges = () => {
    let errorMessage;

    if (errorMessage === undefined && firstName.length === 0) {
      errorMessage = 'PAGE_REGISTER.ERROR_MISSING_FIELD_FIRST_NAME';
    }

    if (errorMessage === undefined && lastName.length === 0) {
      errorMessage = 'PAGE_REGISTER.ERROR_MISSING_FIELD_LAST_NAME';
    }

    if (errorMessage === undefined) {
      dispatch(
        enduserProfileConfirmChanges({
          firstName: firstName,
          lastName: lastName,
          canSendVendorEmail: canSendVendorEmail,
        }),
      );
    } else {
      alert({
        title: t('ERROR_GENERIC.ERROR_TITLE_GENERIC'),
        message: t(errorMessage),
      });
    }
  };

  if (!loggedIn) {
    return <PageWithLoginComplaint />;
  }

  return (
    <div className="AuthPageVerticalRoot">
      <Topbar />

      <div className="SubmenuHorizontalRoot">
        <div className="SubmenuHorizontalRight">
          <div className="ProfilePageContainer">
            <ProfileSubPageVerticalMenu />
            <Form inverted>
              <Form.Field>
                <label>{t('PAGE_REGISTER.FIELD_EMAIL_HINT')}</label>
                <label className="ProfilePageLabel">
                  {existingUserProfile?.email}
                </label>
              </Form.Field>
              <Form.Field>
                <label>{t('PAGE_REGISTER.FIELD_FIRST_NAME_HINT')}</label>
                <Input
                  size="huge"
                  value={firstName}
                  placeholder={t('PAGE_REGISTER.FIELD_FIRST_NAME_HINT')}
                  onChange={(changeEvent) => {
                    setFirstName(changeEvent.target.value);
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>{t('PAGE_REGISTER.FIELD_LAST_NAME_HINT')}</label>
                <Input
                  size="huge"
                  value={lastName}
                  placeholder={t('PAGE_REGISTER.FIELD_LAST_NAME_HINT')}
                  onChange={(changeEvent) => {
                    setLastName(changeEvent.target.value);
                  }}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label={t('PAGE_REGISTER.FIELD_CAN_SEND_VENDOR_EMAIL_LABEL')}
                  checked={canSendVendorEmail}
                  onChange={(_changeEvent) => {
                    setCanSendVendorEmail(!canSendVendorEmail);
                  }}
                />
              </Form.Field>
              <Button
                inverted
                size="large"
                onClick={onButtonConfirmChanges}
                fluid>
                {t('PAGE_PROFILE.BUTTON_CONFIRM_CHANGES')}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export function ProfileSubPageVerticalMenu() {
  const routerLocation = useLocation();

  let selectedMenuIndex = 0;

  if (routerLocation.pathname.indexOf('user_profile/cards') !== -1) {
    selectedMenuIndex = 1;
  }

  return (
    <div className="ProfilePageTopButtons">
      <Button
        className="ProfilePageTopButton"
        as={Link}
        to={routes.userProfile()}
        size="big"
        inverted
        active={selectedMenuIndex === 0}>
        {t('PAGE_PROFILE.SUBMENU_PROFILE')}
      </Button>
      <Button
        className="ProfilePageTopButton"
        as={Link}
        to={routes.userProfileCards()}
        size="big"
        inverted
        active={selectedMenuIndex === 1}>
        {t('PAGE_PROFILE.SUBMENU_CARDS')}
      </Button>
    </div>
  );
}
