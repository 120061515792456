import React, {useEffect, useState} from 'react';
import {Button, Modal} from 'semantic-ui-react';
import {setUiComponent, initialUiState} from '../alert';

export function AlertModal() {
  const [uiState, setUiState] = useState(initialUiState);

  useEffect(() => {
    setUiComponent(setUiState);

    return () => {
      setUiComponent(undefined);
    };
  }, []);

  return (
    <Modal
      size={uiState.alert.modalSize ?? 'mini'}
      onClose={() => {
        if (uiState.alert.allowCloseByBackgroundClick) {
          uiState.onClose();
        }
      }}
      open={uiState.show}
      className="GappModalBackground">
      <Modal.Header>{uiState.alert.title}</Modal.Header>
      <Modal.Content>
        <Modal.Description>{uiState.alert.message}</Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {uiState.alert.buttons.map((button, index) => {
          return (
            <Button key={button.label || '' + index} onClick={button.onClick}>
              {button.label}
            </Button>
          );
        })}
      </Modal.Actions>
    </Modal>
  );
}
