import {call, put, takeEvery} from 'typed-redux-saga';
import {showLightLoadingScreen} from '../actions/loading';
import {alert} from '../alert';
import {t} from '../i18n';
import {ActionOfActionCreator, typedSelect} from '../redux/reduxUtil';
import {
  enduserProfileConfirmChanges,
  enduserProfileDeleteCard,
} from '../actions/profile';
import {doDeleteCard, patchProfile} from '../api/profile';
import {refreshUserProfile} from './auth';

export function* onEnduserProfileConfirmChanges(
  action: ActionOfActionCreator<typeof enduserProfileConfirmChanges>,
) {
  let success = false;

  yield* put(showLightLoadingScreen({show: true}));

  try {
    const authToken = yield* typedSelect((state) => state.auth.token);

    if (authToken) {
      const patchProfileResult = yield* call(patchProfile, authToken, {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        canSendVendorEmail: action.payload.canSendVendorEmail,
      });

      if (patchProfileResult.error === 0) {
        success = true;
      }
    }
  } catch (_e) {}

  // refreshUserProfile is needed for the new user data to show up in the frontend UI
  try {
    yield* call(refreshUserProfile);
  } catch (_e) {}
  yield* put(showLightLoadingScreen({show: false}));

  if (success) {
    alert({
      title: t('PAGE_PROFILE.ALERT_CHANGES_SAVED_TITLE'),
    });
  } else {
    alert({
      title: t('ERROR_GENERIC.ERROR_TITLE_GENERIC'),
      message: t('PAGE_PROFILE.ERROR_CHANGES_SAVED_FAILED_MESSAGE'),
    });
  }
}

export function* onEnduserProfileDeleteCard(
  action: ActionOfActionCreator<typeof enduserProfileDeleteCard>,
) {
  let success = false;

  yield* put(showLightLoadingScreen({show: true}));

  try {
    const {currentCompanySlug, authToken, userProfile} = yield* typedSelect(
      (state) => ({
        currentCompanySlug: state.company.currentCompanySlug,
        authToken: state.auth.token,
        userProfile: state.auth.userProfile,
      }),
    );

    if (currentCompanySlug && authToken && userProfile) {
      const deleteCardResult = yield* call(
        doDeleteCard,
        currentCompanySlug,
        authToken,
        action.payload.cardToken,
      );

      if (deleteCardResult.error === 0) {
        success = true;

        // refreshUserProfile is needed for the new card list to show up in the frontend UI
        try {
          yield* call(refreshUserProfile);
        } catch (_e) {}
      }
    }
  } catch (_e) {}

  yield* put(showLightLoadingScreen({show: false}));

  if (success) {
    alert({
      message: t('PAGE_PROFILE.DELETE_CARD_SUCCESS_MESSAGE'),
    });
  } else {
    alert({
      title: t('PAGE_PROFILE.DELETE_CARD_FAILURE_TITLE'),
      message: t('ERROR_GENERIC.TRY_AGAIN_LATER'),
    });
  }
}

export function* watchProfile() {
  yield* takeEvery(
    enduserProfileConfirmChanges.actionType,
    onEnduserProfileConfirmChanges,
  );
  yield* takeEvery(
    enduserProfileDeleteCard.actionType,
    onEnduserProfileDeleteCard,
  );
}
