import {TCompanyObject} from './dataTypes';
import {apiURL, baseURL, fetchGet, TApiResult} from './baseapi';

export type TCompanyDataResult = TApiResult<TCompanyObject[]>;

export async function getCompanies() {
  console.log('API, getCompanies, request');

  const response: TCompanyDataResult = await fetchGet({
    url: `${baseURL}${apiURL}companies/`,
  });

  if (response.error) {
    console.log('API, getCompanies, error', response);
  } else {
    console.log('API, getCompanies, response', response);
  }

  return response;
}
