import React from 'react';
import {useDispatch} from 'react-redux';
import {Button} from 'semantic-ui-react';
import {useTypedSelector} from '../redux/reduxUtil';
import {t} from '../i18n';
import {enduserSetCurrentCompany} from '../actions/company';

export function CompanySelectionPage() {
  const dispatch = useDispatch();
  const companies = useTypedSelector((state) => state.company.companies);

  return (
    <div className="CompanySelectionPage AuthPageVerticalRoot">
      <div className="CenterContainer">
        <h1 className="CompanySelectionPageTitle">
          {t('PAGE_COMPANY_SELECTION.LABEL_TITLE_1')}
        </h1>

        {companies
          .filter(
            (company) =>
              company._id &&
              company.slug &&
              company.name &&
              company.streetAddress &&
              company.postalCode &&
              company.city,
          )
          .map((company) => {
            if (
              company._id &&
              company.slug &&
              company.name &&
              company.streetAddress &&
              company.postalCode &&
              company.city
            ) {
              const companySlug = company.slug;

              const fullAddress = `${company.streetAddress},${company.postalCode},${company.city}`;

              return (
                <CompanySelectionPageCompany
                  key={company._id}
                  name={company.city}
                  fullAddress={fullAddress}
                  onSelected={() => {
                    dispatch(
                      enduserSetCurrentCompany({
                        currentCompanySlug: companySlug,
                        sourceLocation: 'loginProcess',
                      }),
                    );
                  }}
                />
              );
            } else {
              return null;
            }
          })}
      </div>
    </div>
  );
}

export function CompanySelectionPageCompany(props: {
  name: string;
  fullAddress: string;
  onSelected: () => void;
}) {
  return (
    <div className="CompanySelectionPageCompany">
      <Button inverted primary onClick={props.onSelected} fluid>
        {props.name}
      </Button>

      <div className="CompanySelectionPageCompanyPadding" />

      <p className="CompanySelectionPageCompanyAddress">{props.fullAddress}</p>
    </div>
  );
}
