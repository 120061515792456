import React, {useEffect} from 'react';
import {Provider, useDispatch} from 'react-redux';
import {Switch, Route} from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router';
import {Dimmer, Loader} from 'semantic-ui-react';

import {store, history} from './redux/reduxStore';
import {useTypedSelector} from './redux/reduxUtil';
import {LoginPage} from './components/LoginPage';
import {CompanySelectionPage} from './components/CompanySelectionPage';
import {LoadingPage} from './components/LoadingPage';
import {AlertModal} from './components/AlertModal';
import {StreamVideoPlayerPage} from './components/StreamVideoPlayerPage';
import {OndemandClipSelectionModal} from './components/OndemandClipSelectionModal';
import {PageWithTabs} from './components/PageWithTabs';
import {tabsConfig} from './tabConfig';
import {EventPage} from './components/EventPage';
import {CartPage} from './components/CartPage';
import {PaymentSettingsPage} from './components/PaymentSettingsPage';
import {ForgotPasswordPage} from './components/ForgotPasswordPage';
import {ForgotPasswordResetPage} from './components/ForgotPasswordResetPage';
import {RegisterPage} from './components/RegisterPage';
import {RegisterCodeInputPage} from './components/RegisterCodeInputPage';
import {ProfilePage} from './components/ProfilePage';
import {TermsOfServicePage} from './components/TermsOfServicePage';
import {PrivacyPolicyPage} from './components/PrivacyPolicyPage';
import {t} from './i18n';
import {ProfileCardManagementPage} from './components/ProfileCardManagementPage';
import {routeMatchers, routes} from './routes';
import {screenSizeChanged} from './actions/loading';

function App() {
  return (
    <Provider store={store}>
      <AppInner />
    </Provider>
  );
}

function AppInner() {
  const dispatch = useDispatch();
  const {
    anyLightLoadingScreenShown,
    routerRerenderCounter,
    heavyLoadingScreenShown,
  } = useTypedSelector((state) => ({
    anyLightLoadingScreenShown:
      state.loading.initialLoadingScreenShown ||
      state.loading.lightLoadingScreenShown ||
      state.loading.heavyLoadingScreenShown,
    routerRerenderCounter: state.loading.routerRerenderCounter,
    heavyLoadingScreenShown: state.loading.heavyLoadingScreenShown,
  }));

  useEffect(() => {
    window.addEventListener('resize', () => {
      dispatch(
        screenSizeChanged({
          width: window.innerWidth,
          height: window.innerHeight,
        }),
      );
    });
  }, [dispatch]);

  return (
    <>
      <ConnectedRouter history={history} key={routerRerenderCounter}>
        <Switch>
          <Route path={routes.login()}>
            <LoginPage />
          </Route>

          <Route path={routes.companySelection()}>
            <CompanySelectionPage />
          </Route>
          <Route path={routes.loading()}>
            <LoadingPage />
          </Route>
          <Route path={routeMatchers.watchStream}>
            <StreamVideoPlayerPage />
          </Route>
          <Route path={routeMatchers.event}>
            <EventPage />
          </Route>
          <Route path={routes.cart()}>
            <CartPage />
          </Route>
          <Route path={routes.payment()}>
            <PaymentSettingsPage />
          </Route>

          <Route path={routes.forgotPassword()}>
            <ForgotPasswordPage />
          </Route>
          <Route path={routes.forgotPasswordReset()}>
            <ForgotPasswordResetPage />
          </Route>

          <Route path={routes.register()}>
            <RegisterPage />
          </Route>
          <Route path={routes.registerCodeInput()}>
            <RegisterCodeInputPage />
          </Route>

          <Route path={routes.userProfileCards()}>
            <ProfileCardManagementPage />
          </Route>
          <Route path={routes.userProfile()}>
            <ProfilePage />
          </Route>

          <Route path={routes.termsOfService()}>
            <TermsOfServicePage />
          </Route>
          <Route path={routes.privacyPolicy()}>
            <PrivacyPolicyPage />
          </Route>

          {tabsConfig.map((tab, tabIndex) => {
            if (Array.isArray(tab.route)) {
              return (
                <>
                  {tab.route.map((route, routeIndex) => {
                    return (
                      <Route key={routeIndex} path={route}>
                        <PageWithTabs>{tab.component}</PageWithTabs>
                      </Route>
                    );
                  })}
                </>
              );
            } else {
              return (
                <Route key={tabIndex} path={tab.route}>
                  <PageWithTabs>{tab.component}</PageWithTabs>
                </Route>
              );
            }
          })}

          <Route path={'/'}>
            <PageWithTabs>{tabsConfig[0].component}</PageWithTabs>
          </Route>
        </Switch>
      </ConnectedRouter>

      {heavyLoadingScreenShown && (
        <div className="GappAppHeavyLoadingScreenDarkener" />
      )}

      <Dimmer active={anyLightLoadingScreenShown} page>
        <Loader key={routerRerenderCounter} size="massive">
          {t('PAGE_LOADING.LOADING')}
        </Loader>
      </Dimmer>

      <OndemandClipSelectionModal />
      <AlertModal />
    </>
  );
}

export default App;
