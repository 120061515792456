import React from 'react';
import {Topbar} from './Topbar';

export function PageWithTabs(props: {children: any}) {
  return (
    <div className="PageWithTabs">
      <Topbar />
      <div className="PageWithTabsContent">{props.children}</div>
    </div>
  );
}
