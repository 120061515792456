import {apiURL, baseURL, fetchGet, TApiResult} from './baseapi';
import {TDiscountCodeObject} from './dataTypes';

export type TGetDiscountResult = TApiResult<TDiscountCodeObject>;

export async function getDiscount(
  companySlug: string,
  authToken: string,
  discountCode: string,
) {
  console.log('API, getDiscount, request');

  const response: TGetDiscountResult = await fetchGet({
    url: `${baseURL}${apiURL}companies/${companySlug}/discountcodes/${discountCode}`,
    token: authToken,
  });

  if (response.error) {
    console.log('API, getDiscount, error', response);
  } else {
    console.log('API, getDiscount, response', response);
  }

  return response;
}
