import {TReceiptInputObject} from './dataTypes';
import {
  apiURL,
  baseURL,
  fetchPost,
  getPaymentReturnUrl,
  TApiResult,
} from './baseapi';
import {TOffsitePaymentType} from '../reducers/payment';

export type TChargeCardResult = TApiResult<{}>;
export type TAddAndChargeCardResult = TApiResult<{}>;
export type TFinishOffsitePaymentResult = TApiResult<{}>;

export async function chargeCard(
  companySlug: string,
  authToken: string,
  receiptInput: TReceiptInputObject,
) {
  console.log('API, chargeCard, request');

  const response: TChargeCardResult = await fetchPost({
    url: `${baseURL}${apiURL}companies/${companySlug}/payform/charge_card`,
    token: authToken,
    json: {...receiptInput, returnUrl: getPaymentReturnUrl()},
  });

  if (response.error) {
    console.log('API, chargeCard, error', response);
  } else {
    console.log('API, chargeCard, response', response);
  }

  return response;
}

export async function addAndChargeCard(
  companySlug: string,
  authToken: string,
  receiptInput: TReceiptInputObject,
) {
  console.log('API, addAndChargeCard, request');

  const response: TAddAndChargeCardResult = await fetchPost({
    url: `${baseURL}${apiURL}companies/${companySlug}/payform/charge`,
    token: authToken,
    json: {...receiptInput, returnUrl: getPaymentReturnUrl()},
  });

  if (response.error) {
    console.log('API, addAndChargeCard, error', response);
  } else {
    console.log('API, addAndChargeCard, response', response);
  }

  return response;
}

export async function addCard(companySlug: string, authToken: string) {
  console.log('API, addCard, request');

  const response: TAddAndChargeCardResult = await fetchPost({
    url: `${baseURL}${apiURL}companies/${companySlug}/payform/add_card`,
    token: authToken,
    json: {returnUrl: getPaymentReturnUrl()},
  });

  if (response.error) {
    console.log('API, addCard, error', response);
  } else {
    console.log('API, addCard, response', response);
  }

  return response;
}

export async function finishOffsitePayment(
  companySlug: string,
  authToken: string,
  paymentToken: string,
  offsitePaymentType: TOffsitePaymentType,
) {
  console.log('API, finishOffsitePayment, request');

  const response: TFinishOffsitePaymentResult = await fetchPost({
    url: `${baseURL}${apiURL}companies/${companySlug}/payform/status`,
    token: authToken,
    json: {
      token: paymentToken,
      skipAddCard: offsitePaymentType === 'paymentWithExistingCard',
    },
  });

  if (response.error) {
    console.log('API, finishOffsitePayment, error', response);
  } else {
    console.log('API, finishOffsitePayment, response', response);
  }

  return response;
}
