import {setEvents} from '../actions/events';
import {TEventObject} from '../api/dataTypes';
import {reducerBuilder} from '../redux/reduxUtil';

export const events = reducerBuilder<{
  events: TEventObject[];
}>({
  events: [],
})
  .matchAction(setEvents, (state, action) => {
    return {
      ...state,
      events: action.payload.events,
    };
  })
  .finish();
