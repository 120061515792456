export const PrivacyPolicy = () => (
  <>
    <h1>UMO Helsinki (UMO Foundation sr) Privacy Statement</h1>

    <p>This Privacy Statement describes the processing of personal data of the UMO Helsinki. The subject of our processing is our customers&#39; information in UMO Helsinki -application and streaming.umohelsinki.fi.</p>

    <h2>Contact details of the Data Controller</h2>

    <p>
        UMO Foundation sr (later UMO Helsinki)<br />
        2202299-9<br />
        Tallberginkatu 1 / 139, 00180 Helsinki<br />
        <a href="mailto:jazz@umohelsinki.fi">jazz@umohelsinki.fi</a>
    </p>

    <h2>What information do we collect and for what purpose?</h2>

    <p>We only collect data that is necessary for the operation and development of our service.</p>
    
    <h3>The information we collect directly from you</h3>
    <ul>
        <li>When you register to the service, we collect your name, e-mail address, and password. This information is collected for identification, communication, and implementation of the service. We do not store passwords in a readable form. For the marketing purposes, we ask for a separate consent. </li>
        <li>The information required for payments is not stored in our system. We do keep the tokens of the payment cards that you saved, their type, validity, and last 4 digits. The information does not include the complete card number, security code, or other information required for online payments. UMO Helsinki does not charge the card. All of the payments are handled by a PCI Certified Payment Partner - the Visma Pay. Payment procedure in their service is secure, as all of the payment information is transmitted using encrypted connection so that no third party can access it. </li>
        <li>We store your purchase history (receipts), which is required by the Accounting Act, and we use that information for the use of purchase behavior profiling. We will anonymize the data if you withdraw the consent. </li>
        <li> When you contact our customer support through an email, we collect the information you provide us in order to be able to help you with your case. We may also store that information to develop our customer support. </li>
    </ul>

    <p>We collect the aforementioned information directly from you. You hand over information by signing up, signing in, using the service, adding a payment card, making purchases or making a customer service request. This information is used for communication and to either develop or provide services. </p>
    
    <h3>Personal information we collect from third parties</h3>
    <p>Third parties may collect or receive information from UMO Helsinki -application, streaming.umohelsinki.fi and other apps and use that information to provide measurement services and targeted ads. These third parties in our service include:</p>
    <h4>UMO Helsinki -application</h4>
    <ul>
        <li>Google Firebase Crashlytics add-on collects anonymous crash and analytics data. We are using this data to optimize the user experience and to locate and fix the emerging issues of UMO Helsinki -application. You consent to data collection by registering to service and accepting this privacy policy. You can stop the data collection by signing out and restarting the application. (<a href="https://firebase.google.com/support/privacy/" target="_blank" rel="noopener noreferrer">Firebase Privacy Policy</a>) </li>
        <li>Facebook collects and handles your information if you select Facebook as the method of registration and/or sign in. You consent to data collection by using your Facebook account. We are using the anonymous analytics data collected by Facebook to optimize and develop our service. When you are using a Facebook account to register, we save your name, email address, and Facebook id number. You can stop the data collection by removing the UMO Helsinki -application authorization in the settings of your Facebook account. Facebook id remains in our database and you can ask for removal by following the instructions given in the "Use of rights" chapter. (<a href="https://www.facebook.com/about/privacy" target="_blank" rel="noopener noreferrer">Facebook Privacy Policy</a>) </li>
    </ul>

    <h4>streaming.umohelsinki.fi</h4>
    <ul>
        <li>On our website, we collect some information to be able to provide the best possible user experience. We need that information for analyzing the way our service are being used. Based on the collected information, we optimize the quality, content, adaptability, and relevancy of our service to match our customers' needs. The information is collected using cookies and user accounts. It contains anonymous usage data, e.g. technical information about your browser and device. You consent to the data collection by using our website. </li>
        <li> Some of the tools that we use for data collection are Facebook Pixel and Google Analytics. Third parties may collect or receive this information and use it to provide measurement services and targeted ads. Read more about privacy policy of <a href="https://www.facebook.com/about/privacy" target="_blank" rel="noopener noreferrer">Facebook</a> and <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Google</a>. </li>
        <li> You may clear or block cookies and other information collected in your browser settings or by using specific add-ons. Please notice that emptying cookies doesn't stop the data collection. You may activate collection blocking either beforehand or while using the service. In your mobile device, you may disable location sharing. Complete blocking of cookies and/or location services may affect our ability to provide services. </li>
    </ul>

    <h2>What kind of rights you have and how to use them?</h2>

    <p>You have the rights to personal information held by UMO Helsinki.</p>
    <ul>
        <li>You have the right to access the personal data in our possession. However, access to information may be restricted by the privacy of the legislation and the privacy of other persons. </li>
        <li>You have the right to request correction of incorrect or incomplete information. </li>
        <li>You have the right to be forgotten. You may request the removal of your data. Data deletion can be done, for example, in cases when you withdraw the consent, and there is no other reason for the processing, or when you deny the data processing, and there is no other reason for the continuation of processing. </li>
        <li>You have the right to limit the processing of your personal data. </li>
        <li>You have the right to object to the processing of your data. </li>
        <li>You have the right of data transferability. Upon request, you may receive personal data in machine-readable form. This right applies to personal information that has been processed automatically by agreement or consent to the breach. </li>
        <li>You're entitled to withdraw the consent, at any time, without prejudice to the lawfulness of the processing before withdrawal if the processing is based on consent. Canceling consent may affect our ability to provide services. </li>
        <li>You also have the right to file a complaint with the Data Protection Authority if you suspect that your personal data is being used improperly or unlawfully. </li>
    </ul>
    <h3>Use of Rights</h3>
    <p>To use your rights, please contact the UMO Helsinki customer service <a href="mailto:jazz@umohelsinki.fi">jazz@umohelsinki.fi</a> Access to stored personal data is also possible through our web site and iOS and Android applications. </p>
    
    <h2>For what purpose we use the information and on what basis do we handle them? </h2>
	
    <p>UMO Helsinki handles personal information to meet statutory and contractual obligations. The legal bases of our processing are: </p>
    <p>Implementing the contract: Fulfilling contractual obligations, i.e. providing our service, is the main legal basis for our processing of personal data. The contract is formed between UMO Helsinki and you (Data Subject) when you register to the service. You will accept the processing of data according to this Privacy Statement by using the service. UMO Helsinki will process personal information to the extent necessary for providing or developing the service. </p>
    <p>Statutory Obligation: In addition to our contracts, we have statutory obligations to deal with personal information. Examples of these include Accounting Act and public event and restaurant legislation. </p>
    <p>Consent: To develop our website we collect analytical information on the use of the pages based on your consent. Acceptance of data collection is given at the site upon arrival. Regarding the information collected for marketing purposes, a separate consent will be collected from you, and that consent can be withdrawn at any time. Third parties may still use the collected data to provide measurement services and targeted ads. You are able to block the use of cookies by changing the browser settings in accordance with the browser manufacturer&#39;s instructions and deleting any cookies from the browser&#39;s cache. Clearing cookies do not stop the procedure of data collection. </p>

    <h2>How long will we keep the data?</h2>

    <p>Personal data is kept only for a contractual period unless otherwise required by law, such as the Accounting Act. For example, purchase transactions are maintained for the period required by the Accounting Act, but the information is anonymized at the end of the contractual relationship. </p>
    <p>Website anonymous visitor analytics information will only be retained as long as it is necessary to track and develop marketing and customer service, a maximum of 26 months. </p>
    <p>Customer support data is kept for the maximum of 24 months. </p>

    <h2>Data Processors and Cross-Border Processing </h2>

    <p>Data processing is being done by employees of UMO Helsinki, in accordance with the current Personal Data Act. UMO Helsinki reserves the right to outsource the processing of personal data to a third party, thereby guaranteeing contractual arrangements that personal data will be processed in accordance with the Personal Data Act and otherwise appropriately. </p>
    <p>Information related to purchase transactions is transferred to our payment service provider, Visma Pay, for charging. Visma Pay contact information: </p>
    <p>
		Visma Pay, Paybyway Oy (business-id FI24865594)
		<br />E-mail: <a href="mailto:helpdesk@vismapay.com">helpdesk@vismapay.com</a>
		<br />Telephone: +358 9 315 42 037 (workdays 8-16)
		<br />Address: Laserkatu 6, 53850 Lappeenranta, Finland
	</p>
    <p>Otherwise, data will not be combined with other registers and will not be disclosed to third parties, unless required by law (e.g. the Accounting Act). </p>
    <p>
		The server system used to provide the service is located in the Heroku server of Salesforce, Ireland. For information on the principles of the Heroku Privacy Policy, see the links below:
        <br /><a href="https://www.google.com/url?q=https://www.salesforce.com/company/privacy/&amp;sa=D&amp;ust=1541699670317000">https://www.salesforce.com/company/privacy/</a>
		<br /><a href="https://www.google.com/url?q=https://www.salesforce.com/gdpr/overview/&amp;sa=D&amp;ust=1541699670318000">https://www.salesforce.com/gdpr/overview/</a>
    </p>
    
    <h2>What are the risks involved in personal data processing and how do we protect the data? </h2>
    <p>The largest (still minor) risk is the personal data ending in the wrong hands, for example in connection with data theft or leakage. If this unlikely event occurs, the information can be used to find out the behavior of the Data Subjects, determine Data Subjects&rsquo; locations on event days, and send junk mail. Announcements of the large-scale data leaks are always provided to each party of the contract, regardless of whether or not the party is subject to the notification obligation. </p>
    <p>The purpose of the UMO Helsinki security operations are to secure the availability of information and information systems, to ensure their confidentiality, to ensure data integrity, and to minimize any possible damage caused by deviations. The hedging activities are based on an activity risk assessment and are proportioned to managing the hedged item and the risks it poses. </p>
    <p>Your personal data is always processed in accordance with the Personal Data Protection Act. </p>
  </>
);
