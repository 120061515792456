import {TTicketObject} from './dataTypes';
import {apiURL, baseURL, fetchGet, TApiResult} from './baseapi';

export type TTicketsResult = TApiResult<TTicketObject[]>;

export async function getTickets(
  companySlug: string,
  userId: string,
  authToken: string,
) {
  console.log('API, getTickets, request');

  const response: TTicketsResult = await fetchGet({
    url: `${baseURL}${apiURL}companies/${companySlug}/tickets?user=${userId}`,
    token: authToken,
  });

  if (response.error) {
    console.log('API, getTickets, error', response);
  } else {
    console.log('API, getTickets, response', response);
  }

  return response;
}
