import Moment from 'moment';
import {pictureURL} from './api/baseapi';
import {
  TArticleObject,
  TBenefitDiscountObject,
  TEventObject,
  TLineSpecifics,
  TNameObject,
  TOrderLine,
  TProductObject,
  TTicketObject,
  TTicketSeriesObject,
  TURLObject,
} from './api/dataTypes';
import {TVideoStreamViewProps} from './commonTypes';
import {giftTicketEventIds, giftTicketTicketseriesIds} from './configs';
import { getCurrentLanguage } from './i18n';

export const getNameObject = (
  names: TNameObject[] | undefined | null,
  currentLanguage?: string | undefined | null,
): string => {
  if (!names) {
    return '';
  }

  const language = currentLanguage || getCurrentLanguage();

  let nameFound = names.find((name) => name.language === language);

  if (nameFound && nameFound.value) {
    return nameFound.value;
  }

  nameFound = names.find(({language}) => language === 'en');

  if (nameFound && nameFound.value) {
    return nameFound.value;
  }

  return names[0] ? names[0].value || '' : '';
};

export const isTicketSeriesSoldout = (ts: TTicketSeriesObject) => {
  if (ts.maxCount === 0) {
    return true;
  }

  // If some data is missing, assume it's not sold out
  if (!ts.soldTickets || !ts.maxCount) {
    return false;
  }

  return ts.soldTickets.length >= ts.maxCount;
};

export function isEventSoldout(event: TEventObject): boolean {
  if (event.ticketSeries && event.ticketSeries.length > 0) {
    let isSoldout = true;

    event.ticketSeries.forEach((ticketSeries) => {
      if (!isTicketSeriesSoldout(ticketSeries)) {
        isSoldout = false;
      }
    });

    return isSoldout;
  }

  return false;
}

export const streamOwned = (eventId: string, tickets: TTicketObject[]) =>
  !!tickets.find(
    (ticket) =>
      (ticket.ticketTypes || []).includes('stream') &&
      !ticket.isDeleted &&
      ((ticket.event || {})._id || '').toString() === eventId,
  );

export const streamOndemandOwned = (
  eventId: string,
  tickets: TTicketObject[],
) =>
  !!tickets.find(
    (ticket) =>
      (ticket.ticketTypes || []).includes('ondemand') &&
      !ticket.isDeleted &&
      ((ticket.event || {})._id || '').toString() === eventId,
  );

export const streamEventIsInTheFuture = (event?: TEventObject) =>
  event
    ? Moment().isBefore((event.streamInfo || {}).liveStreamStartDate)
    : true;

export const streamEventIsLiveNow = (event?: TEventObject) =>
  event &&
  event.streamInfo &&
  Moment().isAfter((event.streamInfo || {}).liveStreamStartDate) &&
  Moment().isBefore((event.streamInfo || {}).liveStreamEndDate);

export const ondemandEventIsFinished = (event?: TEventObject) =>
  event && event.streamInfo
    ? Moment().isAfter((event.streamInfo || {}).vodEndDate)
    : true;

export const streamEventIsFinished = (event?: TEventObject) =>
  event && event.streamInfo
    ? Moment().isAfter((event.streamInfo || {}).liveStreamEndDate)
    : true;

export const eventIsFinished = (event?: TEventObject) =>
  event ? Moment().isAfter(event.endDate) : true;

export function ticketIsEventTicket(ticket: TTicketObject): boolean {
  return (
    !Array.isArray(ticket.ticketTypes) ||
    (Array.isArray(ticket.ticketTypes) && ticket.ticketTypes.length === 0) ||
    (Array.isArray(ticket.ticketTypes) &&
      ticket.ticketTypes.indexOf('event') !== -1)
  );
}

export function ticketIsStreamTicket(ticket: TTicketObject): boolean {
  return (
    Array.isArray(ticket.ticketTypes) &&
    ticket.ticketTypes.indexOf('stream') !== -1
  );
}

export function ticketIsOndemandStreamTicket(ticket: TTicketObject): boolean {
  return (
    Array.isArray(ticket.ticketTypes) &&
    ticket.ticketTypes.indexOf('ondemand') !== -1
  );
}

export const eventHasLiveStream = (event?: TEventObject) =>
  !!(
    event &&
    Boolean(event.streamInfo) &&
    (event.ticketSeries || []).find((ticket) =>
      (ticket.ticketTypes || []).includes('stream'),
    )
  );

export const eventHasOndemandStream = (event?: TEventObject) =>
  !!(
    event &&
    Boolean(event.streamInfo) &&
    (event.ticketSeries || []).find((ticket) =>
      (ticket.ticketTypes || []).includes('ondemand'),
    )
  );

export const eventLiveStreamStillAvailable = (event: TEventObject) =>
  event.streamInfo &&
  event.streamInfo.liveStreamEndDate &&
  event.streamInfo.liveStreamStartDate &&
  Moment.utc().isAfter((event.streamInfo || {}).liveStreamStartDate) &&
  Moment.utc().isBefore((event.streamInfo || {}).liveStreamEndDate);

export const eventOndemandStreamStillAvailable = (event: TEventObject) =>
  event.streamInfo &&
  event.streamInfo.vodEndDate &&
  Moment.utc().isAfter(
    (event.streamInfo || {}).liveStreamEndDate || event.gigStartDate,
  ) &&
  Moment.utc().isBefore((event.streamInfo || {}).vodEndDate);

export const eventOndemandStreamAvailableNowOrLater = (event: TEventObject) =>
  event.streamInfo &&
  event.streamInfo.vodEndDate &&
  Moment.utc().isBefore((event.streamInfo || {}).vodEndDate);

export const eventOndemandStreamFormatAvailableUntilDateString = (
  event: TEventObject,
) => {
  if (event && event.streamInfo && event.streamInfo.vodEndDate) {
    return Moment(event.streamInfo.vodEndDate).format('DD/MM/YY');
  }

  return '';
};

const getExpirationFromType = (
  ticketTypes: string[] | undefined | null,
  event: TEventObject,
) => {
  let expirationDate = event.endDate;

  if ((ticketTypes || []).includes('ondemand')) {
    expirationDate = (event.streamInfo || {}).vodEndDate || event.endDate;
  } else if ((ticketTypes || []).includes('stream')) {
    expirationDate =
      (event.streamInfo || {}).liveStreamEndDate || event.endDate;
  }

  return expirationDate;
};

export const getExpirationDateFromSeries = (
  series: TTicketSeriesObject,
  event: TEventObject,
) => getExpirationFromType((series || {}).ticketTypes, event);

export const getExpirationDateFromTicket = (
  ticket: TTicketObject,
  event: TEventObject,
) => getExpirationFromType((ticket || {}).ticketTypes, event);

export const isGiftEvent = (id: string): boolean =>
  giftTicketEventIds.includes(id);
export const isGiftTicketSeries = (id: string): boolean =>
  giftTicketTicketseriesIds.includes(id);

export const isAnnouncementEvent = (event?: TEventObject): boolean =>
  event ? event.isSticky === true : false;

export function getTicketSeriesById(
  ticketId: string,
  events: TEventObject[],
): TTicketSeriesObject | undefined {
  let foundTicket: TTicketSeriesObject | undefined;

  events.forEach((event: TEventObject) => {
    if (!foundTicket && event.ticketSeries) {
      foundTicket = event.ticketSeries.find(
        (ticket: TTicketSeriesObject) => ticket._id === ticketId,
      );
    }
  });

  return foundTicket;
}

export function getEventURL(urls?: TURLObject[]): string {
  let retVal = '';

  if (urls) {
    urls.forEach((value: TURLObject) => {
      if (value.name === 'eventUrl') {
        retVal = value.url;
      }
    });
  }

  if (retVal !== '') {
    retVal = pictureURL + retVal;
  }

  return retVal;
}

export function videoStreamViewPropsFromEventAndUrl(
  event: TEventObject,
  streamUrl: string,
  introLength: number,
): TVideoStreamViewProps {
  return {
    url: streamUrl,
    imageUrl: getEventURL(event.urls),
    title: getNameObject(event.names),
    subtitle: getNameObject(event.descriptions),
    introLength,
    type: eventOndemandStreamStillAvailable(event) ? 'ondemand' : 'stream',
  };
}

export function getArticleCountById(
  specifics: TLineSpecifics[],
  articleId: string,
) {
  let count = 0;

  specifics.forEach((spec: TLineSpecifics) => {
    if (spec.articleId === articleId) {
      count += 1;
    }
  });

  return count;
}

export function getCurrency(price: number): string {
  return `${price.toFixed(2).replace('.', ',')}€`;
}

export function urlParamsFromFullUrl(fullUrl: string): Record<string, string> {
  let resultParams: Record<string, string> = {};

  const questionMarkSeparated = fullUrl.split('?');

  if (questionMarkSeparated.length > 1) {
    const lastQuestionMarkSeparated =
      questionMarkSeparated[questionMarkSeparated.length - 1];

    const paramKeyValuesEncoded = lastQuestionMarkSeparated.split('&');

    for (const paramKv of paramKeyValuesEncoded) {
      const paramKVSplit = paramKv.split('=');

      if (paramKVSplit.length === 2) {
        const paramKeyEncoded = paramKVSplit[0];
        const paramValueEncoded = paramKVSplit[1];

        if (paramKeyEncoded.length > 0) {
          resultParams[decodeURIComponent(paramKeyEncoded)] =
            decodeURIComponent(paramValueEncoded);
        }
      }
    }
  }

  return resultParams;
}

export function getArticleById(
  articleId: string,
  products: TProductObject[],
): TArticleObject | undefined {
  let foundArticle: TArticleObject | undefined;

  products.forEach((product: TProductObject) => {
    if (!foundArticle && product.articles) {
      foundArticle = product.articles.find(
        (article: TArticleObject) => article._id === articleId,
      );
    }
  });

  return foundArticle;
}

export function getDiscountPrice(
  originalPrice: number,
  discountType: string,
  discountAmount: number,
) {
  switch (discountType) {
    case 'currency':
      return Math.round(Math.max(0, originalPrice - discountAmount));
    case 'percent':
      return Math.round(
        Math.max(0, (originalPrice * (100 - discountAmount)) / 100),
      );
    default:
      return originalPrice;
  }
}

export function discountEvents(
  events: TEventObject[],
  discounts: TBenefitDiscountObject[],
): TEventObject[] {
  const newEvents = [...events];

  const combinedDiscounts = discounts.map((discount) => {
    if (discount.events === null) {
      const combinedSeries = events.reduce(
        (ticketSeries, event) =>
          ticketSeries.concat((event.ticketSeries || []).map(({_id}) => _id)),
        [] as string[],
      );

      return {...discount, ticketSeries: combinedSeries};
    } else if (discount.events && discount.ticketSeries === null) {
      const combinedSeries = discount.events.reduce(
        (ticketSeries, eventId) =>
          ticketSeries.concat(
            (
              (events.find(({_id}) => _id === eventId) || {}).ticketSeries || []
            ).map(({_id}) => _id),
          ),
        [] as string[],
      );

      return {...discount, ticketSeries: combinedSeries};
    }

    return discount;
  });

  combinedDiscounts.forEach((d: TBenefitDiscountObject) => {
    if (d.ticketSeries) {
      d.ticketSeries.forEach((t: string) => {
        const series = getTicketSeriesById(t, newEvents);

        if (
          series &&
          typeof series.ticketPrice === 'number' &&
          typeof d.discountType === 'string' &&
          typeof d.discount === 'number'
        ) {
          if (!series.originalPrice) {
            series.originalPrice = series.ticketPrice;
          }

          const newPrice = Math.min(
            series.ticketPrice,
            getDiscountPrice(series.originalPrice, d.discountType, d.discount),
          );
          const newSeries: TTicketSeriesObject = {
            ...series,
            ticketPrice: newPrice,
          };

          let seriesIndex = -1;
          let eventIndex = -1;

          newEvents.some((e: TEventObject, index: number) => {
            if (e.ticketSeries) {
              seriesIndex = e.ticketSeries.findIndex(
                (es: TTicketSeriesObject) => {
                  if (es._id === t) {
                    return true;
                  }

                  return false;
                },
              );

              if (seriesIndex >= 0) {
                eventIndex = index;

                return true;
              }
            }

            return false;
          });

          if (seriesIndex >= 0 && eventIndex >= 0) {
            const oldEvent = {...newEvents[eventIndex]};

            if (oldEvent.ticketSeries) {
              oldEvent.ticketSeries.splice(seriesIndex, 1, newSeries);
              const newEvent = {
                ...oldEvent,
                ticketSeries: [...oldEvent.ticketSeries],
              };

              newEvents.splice(eventIndex, 1, newEvent);
            }
          }
        }
      });
    }
  });

  return newEvents;
}

export function discountProducts(
  products: TProductObject[],
  discounts: TBenefitDiscountObject[],
): TProductObject[] {
  const newProducts = [...products];

  const combinedDiscounts = discounts.map((discount) => {
    if (discount.products === null) {
      const combinedArticles = products.reduce(
        (articles, product) =>
          articles.concat((product.articles || []).map(({_id}) => _id)),
        [] as string[],
      );

      return {...discount, articles: combinedArticles};
    } else if (discount.products && discount.articles === null) {
      const combinedArticles = discount.products.reduce(
        (articles, prodId) =>
          articles.concat(
            (
              (products.find(({_id}) => _id === prodId) || {}).articles || []
            ).map(({_id}) => _id),
          ),
        [] as string[],
      );

      return {...discount, articles: combinedArticles};
    }

    return discount;
  });

  combinedDiscounts.forEach((d: TBenefitDiscountObject) => {
    if (d.articles) {
      d.articles.forEach((a: string) => {
        const article = getArticleById(a, newProducts);

        if (
          article &&
          typeof article.discountPrice === 'number' &&
          typeof d.discountType === 'string' &&
          typeof d.discount === 'number'
        ) {
          if (!article.originalPrice) {
            article.originalPrice = article.discountPrice;
          }

          const newPrice = Math.min(
            article.discountPrice,
            getDiscountPrice(article.originalPrice, d.discountType, d.discount),
          );
          const newArticle = {...article, discountPrice: newPrice};

          let articleIndex = -1;
          let productIndex = -1;

          newProducts.some((p: TProductObject, index: number) => {
            if (p.articles) {
              articleIndex = p.articles.findIndex(
                (pa: TArticleObject) => pa._id === a,
              );

              if (articleIndex >= 0) {
                productIndex = index;

                return true;
              }
            }

            return false;
          });

          if (articleIndex >= 0 && productIndex >= 0) {
            const oldProduct = {...newProducts[productIndex]};

            if (oldProduct.articles) {
              oldProduct.articles.splice(articleIndex, 1, newArticle);

              const newProduct = {
                ...oldProduct,
                articles: [...oldProduct.articles],
              };

              newProducts.splice(productIndex, 1, newProduct);
            }
          }
        }
      });
    }
  });

  return newProducts;
}

export function getOrderLinesTotal(orderLines: TOrderLine[]) {
  const totalPrice = orderLines.reduce((total: number, data: TOrderLine) => {
    if (data.ticketSeries && data.ticketPrice) {
      const origPrice = data.ticketPrice;
      let sumToAdd = 0;

      if (
        data.appliedCodeDiscount &&
        typeof data.appliedCodeDiscount.discount === 'number' &&
        data.appliedCodeDiscount.discountType
      ) {
        const d = data.appliedCodeDiscount.discount;
        const t = data.appliedCodeDiscount.discountType;

        if (
          typeof data.count === 'number' &&
          typeof data.codeDiscountedCount === 'number' &&
          data.count > data.codeDiscountedCount
        ) {
          const normalCount = data.count - data.codeDiscountedCount;
          const discount =
            getDiscountPrice(origPrice, t, d) * (data.codeDiscountedCount || 0);

          sumToAdd = normalCount * origPrice + discount;
        } else {
          sumToAdd =
            getDiscountPrice(data.ticketPrice, t, d) * (data.count || 0);
        }
      } else if (typeof data.count === 'number') {
        sumToAdd = data.count * origPrice;
      }

      return total + sumToAdd;
    } else if (data.product && data.specifics) {
      const sortedSpec = [...data.specifics];

      sortedSpec.sort(
        (a: TLineSpecifics, b: TLineSpecifics) =>
          b.articlePrice - a.articlePrice,
      );
      const specificTotal = sortedSpec.reduce(
        (specTotal: number, spec: TLineSpecifics, index: number) => {
          if (spec.articlePrice) {
            if (data.appliedCodeDiscount && data.codeDiscountedCount) {
              const t = data.appliedCodeDiscount.discountType;
              const d = data.appliedCodeDiscount.discount;

              if (index < data.codeDiscountedCount) {
                return (
                  specTotal +
                  getDiscountPrice(spec.articlePrice, t || '', d || 0)
                );
              }
            }

            return specTotal + spec.articlePrice;
          }

          return specTotal;
        },
        0,
      );

      return total + specificTotal;
    }

    return total;
  }, 0);

  return totalPrice;
}

export const getMapUrlFromQuery = (query: string) => {
  const zoomLevel = 2;

  return `https://maps.google.com/maps?q=${encodeURIComponent(
    query,
  )}&z=${zoomLevel}`;
};
