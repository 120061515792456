import React, {PureComponent, SyntheticEvent} from 'react';
import {Select, Input} from 'semantic-ui-react';

import {getNameObject} from '../util';
import type {
  TDropdownOption,
  TDropdownOptionsObject,
  TTranslateFunction,
} from '../api/dataTypes';

type TProps = {
  button?: boolean;
  className?: string;
  disabled?: boolean;
  icon?: string;
  inline?: boolean;
  label?: string;
  minChars?: number;
  minCharsMessage?: string;
  multiple?: boolean;
  name?: string;
  noResultsMessage?: string;
  onChange: (event: any, data: {value: string[]}) => void;
  options?: TDropdownOptionsObject;
  placeholder?: string;
  scrollIntoViewOnFocus?: boolean;
  search?: boolean;
  selectOnBlur?: boolean;
  selectOnNavigation?: boolean;
  short?: boolean;
  t: TTranslateFunction;
  text?: string;
  value?: string | string[];
  getLabel?: (element: any) => string;
};

type TState = {
  inputValue: string;
  inputFocused: boolean;
  opened: boolean;
};

type TSearchOptions = {
  // eslint-disable-next-line react/no-unused-prop-types
  key: string;
  text?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  textstring: string;
};

export default class Dropdown extends PureComponent<TProps, TState> {
  constructor(props: TProps) {
    super(props);

    this.state = {
      inputValue: '',
      inputFocused: false,
      opened: false,
    };
  }

  getNewLabelValue = () => {
    const {
      options: {
        data: allowedValues = [],
        flat,
        language = 'en',
        prefix = '',
        translatable,
      } = {},
      t,
      value,
      getLabel,
    } = this.props;

    return (Array.isArray(value) ? value : [])
      .map((singleValue) => {
        const foundElement =
          allowedValues.find(
            (allowedElement) =>
              (allowedElement.value || allowedElement._id) === singleValue,
          ) || {};

        let elementValue: any = '';

        if (flat) {
          elementValue = foundElement;
        } else if (getLabel) {
          elementValue = getLabel(foundElement);
        } else {
          elementValue = translatable
            ? foundElement.value
            : getNameObject(foundElement.names, language);
        }

        if (foundElement) {
          return translatable && foundElement.label
            ? t(prefix + foundElement.label)
            : foundElement.name || elementValue;
        }

        return '';
      })
      .join(', ');
  };

  onClear = (event?: SyntheticEvent<HTMLButtonElement>) => {
    if (event) {
      event.stopPropagation();
    }

    this.onDropdownChange(event, {value: []});
  };

  onDropdownChange = (
    event?: SyntheticEvent<HTMLButtonElement>,
    element: any = {},
  ) => {
    const {
      props: {onChange, options: {data: allowedValues} = {}, value = []} = {},
    } = this;
    const {value: elementArray} = element;
    const elementValue = elementArray && elementArray[0];

    if (onChange) {
      if (elementValue && allowedValues) {
        const newValue = allowedValues
          .map((option) => option.value || option._id)
          .filter((val: any) =>
            val === elementValue ? !value.includes(val) : value.includes(val),
          );

        onChange(event, {...element, value: newValue});
      } else if (elementArray) {
        onChange(event, {...element, value: []});
      }
    }
  };

  onDropdownClose = () => {
    this.setState({opened: false, inputValue: '', inputFocused: false});
  };

  onDropdownOpen = () => {
    this.setState({opened: true, inputValue: ''});
  };

  onInputBlur = () => {
    this.setState({inputFocused: false});
  };

  onInputFocus = (event?: SyntheticEvent<HTMLButtonElement>) => {
    const {scrollIntoViewOnFocus} = this.props;
    const {currentTarget: input} = event || {};

    if (
      scrollIntoViewOnFocus &&
      input &&
      typeof input.scrollIntoView === 'function'
    ) {
      input.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
    }

    this.setState({opened: true, inputFocused: true});
  };

  onKeyPressClear = (event: any) => {
    const keys = [32, 13];

    if ([event.which, event.keyCode].some((key) => keys.includes(key))) {
      this.onClear();
    }
  };

  onKeyPressSelectAll = (event: any) => {
    const keys = [32, 13];

    if ([event.which, event.keyCode].some((key) => keys.includes(key))) {
      this.onSelectAll();
    }
  };

  onSearch = (
    options: TSearchOptions[],
    searchValue: string,
    specialOptions: any,
  ): TSearchOptions[] =>
    options.filter(
      ({key, text, textstring}: TSearchOptions) =>
        specialOptions.some((opt: any) => opt.key === key) ||
        (typeof text === 'string' ? text : textstring || '')
          .toLowerCase()
          .includes(searchValue.toLowerCase()),
    );

  onSearchChange = (event: SyntheticEvent<HTMLButtonElement>, value: any) => {
    const {searchQuery} = value;

    this.setState({inputValue: searchQuery});
  };

  onSelectAll = (event?: SyntheticEvent<HTMLButtonElement>) => {
    const {props: {onChange, options: {data: allowedValues} = {}} = {}} = this;

    if (event) {
      event.stopPropagation();
    }

    if (onChange && allowedValues !== undefined) {
      onChange(event, {
        value: allowedValues.map((option) => option.value || option._id || ''),
      });
    }
  };

  render() {
    const {
      props: {
        button,
        className,
        disabled,
        icon,
        inline,
        label,
        minChars,
        minCharsMessage,
        multiple = true,
        name,
        noResultsMessage,
        onChange,
        options: {
          data = [],
          flat = false,
          language = 'en',
          prefix = '',
          translatable,
        } = {},
        placeholder,
        search = true,
        selectOnBlur = false,
        selectOnNavigation = false,
        short,
        t,
        text,
        value,
        getLabel,
      },
      state: {inputFocused, inputValue, opened},
      onClear,
      onDropdownClose,
      onDropdownChange,
      onDropdownOpen,
      onInputBlur,
      onInputFocus,
      onKeyPressClear,
      onKeyPressSelectAll,
      onSearch,
      onSearchChange,
      onSelectAll,
    } = this;

    const inlineMultiple = inline && multiple;
    const minCharsValid =
      !minChars || !opened || (inputValue || '').length >= minChars;

    const specialOptions = inlineMultiple
      ? [
          {
            key: 'clear',
            text: (
              <div
                className="ui checkbox clearSelection"
                role="button"
                tabIndex={0}
                onClick={onClear as any}
                onKeyPress={onKeyPressClear}>
                <input type="checkbox" />
                <div className="clear-label">
                  <i className="icon eraser" />
                  {t('DROPDOWNS.CLEAR_SELECTED')}
                </div>
              </div>
            ),
          },
          {
            key: 'selectAll',
            text: (
              <div
                className="ui checkbox clearSelection"
                role="button"
                tabIndex={0}
                onClick={onSelectAll as any}
                onKeyPress={onKeyPressSelectAll}>
                <input type="checkbox" />
                <div className="clear-label">
                  <i className="icon check circle outline" />
                  {t('DROPDOWNS.SELECT_ALL')}
                </div>
              </div>
            ),
          },
        ]
      : [];

    const dropdownOptions = !minCharsValid
      ? []
      : specialOptions.concat(
          data.reduce((elements: any, element: TDropdownOption, index) => {
            if (element.hideInFilter) {
              return elements;
            }

            let elementValue: any = '';

            if (flat) {
              elementValue = element;
            } else if (getLabel) {
              elementValue = getLabel(element);
            } else {
              elementValue = translatable
                ? element.value
                : getNameObject(element.names, language);
            }

            const itemLabel =
              translatable && element.label
                ? t(prefix + element.label)
                : element.label || element.text || element.name || elementValue;
            const itemValue = flat ? element : element._id || element.value;

            if (inlineMultiple) {
              return elements.concat({
                disabled: element.disabled,
                key: index,
                text: (
                  <div className="ui checkbox labelFilterOut">
                    <input
                      checked={Boolean(
                        value && value.includes(itemValue as any),
                      )}
                      id="labelFilterOut"
                      type="checkbox"
                      readOnly
                    />
                    {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                    <label htmlFor="labelFilterOut">{itemLabel}</label>
                    {/* The checkbox and label are hacked a bit so we cannot fulfill this rule in this case */}
                  </div>
                ),
                textstring: itemLabel || '',
                value: itemValue,
              });
            }

            return elements.concat({
              disabled: element.disabled,
              key: index,
              text: itemLabel,
              value: itemValue,
            });
          }, []),
        );

    let inputText;

    if (inputFocused) {
      inputText = inputValue;
    } else {
      inputText =
        inputValue ||
        this.getNewLabelValue() ||
        (opened ? '' : placeholder || '');
    }

    return (
      <div className={`dropdown${inlineMultiple ? ' inlineMode' : ''}`}>
        {label && <div className="form-label">{label}</div>}

        <Select
          button={button}
          className={className}
          disabled={disabled}
          fluid={!inline && !short}
          icon={icon}
          labeled
          multiple={multiple}
          name={name}
          noResultsMessage={minCharsValid ? noResultsMessage : minCharsMessage}
          onChange={(inlineMultiple ? onDropdownChange : onChange) as any}
          onSearchChange={(search ? onSearchChange : undefined) as any}
          options={dropdownOptions}
          placeholder={(!value && placeholder) || ''}
          search={
            search &&
            ((opts, searchString) =>
              onSearch(opts as any, searchString, specialOptions))
          }
          searchQuery={inputValue}
          searchInput={
            inlineMultiple ? (
              <Input
                value={inputText}
                onBlur={onInputBlur}
                onFocus={onInputFocus}
              />
            ) : undefined
          }
          selectOnBlur={selectOnBlur}
          selectOnNavigation={selectOnNavigation}
          text={text}
          value={inlineMultiple ? [] : value}
          onClose={onDropdownClose}
          onOpen={onDropdownOpen}
        />
      </div>
    );
  }
}
