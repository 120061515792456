import {push, replace} from 'connected-react-router';
import localforage from 'localforage';
import {call, put, takeEvery, take} from 'typed-redux-saga';
import {
  enduserLogin,
  enduserLogout,
  resetReduxStateToInitial,
  setAuthToken,
  setUserProfile,
} from '../actions/auth';
import {enduserSetCurrentCompany} from '../actions/company';
import {
  setUsingMobileStyles,
  showHeavyLoadingScreen,
  showInitialLoadingScreen,
  showLightLoadingScreen,
} from '../actions/loading';
import {type TAlertModalSizeOption, alert, alertPromise} from '../alert';
import {paymentReturnUrlTrigger} from '../api/baseapi';
import {doLogin} from '../api/login';
import {getProfile} from '../api/profile';
import {localStorageKeys} from '../configs';
import {t} from '../i18n';
import {ActionOfActionCreator, typedSelect} from '../redux/reduxUtil';
import {routes} from '../routes';
import {urlParamsFromFullUrl} from '../util';
import {
  refreshEssentialsPostCompany,
  refreshEssentialsPreCompany,
  setCurrentCompanyMinimalSaga,
} from './basics';
import {
  persistedPaymentProcessClear,
  persistedPaymentProcessLoad,
  returnFromOffsitePayment,
} from './cart';

export function* onEnduserLogin(
  action: ActionOfActionCreator<typeof enduserLogin>,
) {
  yield* put(showLightLoadingScreen({show: true}));

  try {
    const loginResult = yield* call(
      doLogin,
      action.payload.username,
      action.payload.password,
    );

    if (loginResult.error) {
      throw Error('badCredentials');
    } else {
      const authToken = loginResult.data.context;

      yield put(setAuthToken({token: authToken}));

      yield* call(async () => {
        return localforage.setItem(localStorageKeys.authToken, authToken);
      });

      yield* call(loginShared);

      yield put(replace(routes.root()));

      yield* put(showLightLoadingScreen({show: false}));

      return true;
    }
  } catch (e) {}

  yield* put(showLightLoadingScreen({show: false}));

  alert({
    title: t('PAGE_LOGIN.ERROR_LOGIN_FAILED_TITLE'),
    message: t('PAGE_LOGIN.ERROR_LOGIN_FAILED_MESSAGE'),
  });

  return false;
}

export function* loginShared() {
  yield* call(doPotentialCompanySelectionFlow);

  yield* call(refreshEssentialsPostCompany);

  const currentUrl = window.location.pathname;

  const returnedFromOffsitePayment =
    currentUrl.indexOf(paymentReturnUrlTrigger) !== -1;

  if (returnedFromOffsitePayment) {
    const currentUrlSearch = window.location.search;
    const urlParams = urlParamsFromFullUrl(currentUrlSearch);

    yield* call(returnFromOffsitePayment, urlParams);
  }
}

// Might navigate if there is no saved company selection.
// After this saga returns true, a company has successfully been selected.
// Returns false if there was an error.
export function* doPotentialCompanySelectionFlow() {
  yield put(showLightLoadingScreen({show: true}));
  yield put(showInitialLoadingScreen({show: false}));
  yield put(showHeavyLoadingScreen({show: false}));

  yield* call(refreshEssentialsPreCompany);

  yield put(showLightLoadingScreen({show: false}));

  const companies = yield* typedSelect((state) => state.company.companies);

  if (companies.length === 0) {
    return false;
  } else if (companies.length === 1) {
    yield* call(setCurrentCompanyMinimalSaga, companies[0].slug!);
  } else {
    const existingSelelectedCompany = yield* call(async () => {
      return localforage.getItem(localStorageKeys.selectedCompanySlug);
    });

    if (
      typeof existingSelelectedCompany === 'string' &&
      companies.some((company) => company.slug === existingSelelectedCompany)
    ) {
      yield* call(setCurrentCompanyMinimalSaga, existingSelelectedCompany);
    } else {
      yield* call(companySelectionFlow);
    }
  }

  return true;
}

export function* companySelectionFlow() {
  yield put(replace(routes.companySelection()));

  const action: ActionOfActionCreator<typeof enduserSetCurrentCompany> =
    yield* take(enduserSetCurrentCompany.actionType) as any;

  yield* call(setCurrentCompanyMinimalSaga, action.payload.currentCompanySlug);

  yield put(replace(routes.root()));
}

export function* onEnduserLogout() {
  yield put(showHeavyLoadingScreen({show: true}));

  yield put(replace(routes.root()));

  yield* call(async () => {
    return localforage.removeItem(localStorageKeys.authToken);
  });
  yield* call(async () => {
    return localforage.removeItem(localStorageKeys.selectedCompanySlug);
  });

  const mobileStyles = yield* typedSelect((state) => state.loading.usingMobileStyles);
  yield put(resetReduxStateToInitial({}));
  yield put(setUsingMobileStyles({ usingMobileStyles: mobileStyles }));

  yield* call(persistedPaymentProcessClear);

  yield put(showHeavyLoadingScreen({show: false}));

  yield* call(checkForExistingLogin);
}

export function* checkForExistingLogin() {
  yield put(showHeavyLoadingScreen({show: true}));

  const existingToken = yield* call(async () => {
    return localforage.getItem(localStorageKeys.authToken);
  });

  yield* call(persistedPaymentProcessLoad);

  if (typeof existingToken === 'string') {
    yield put(setAuthToken({token: existingToken}));

    try {
      yield* call(loginShared);
    } catch (e) {}
  } else {
    yield* call(doPotentialCompanySelectionFlow);

    yield* call(refreshEssentialsPostCompany);
  }

  yield put(showLightLoadingScreen({show: false}));
  yield put(showInitialLoadingScreen({show: false}));
  yield put(showHeavyLoadingScreen({show: false}));
}

export function* refreshUserProfile() {
  const authToken = yield* typedSelect((state) => state.auth.token);

  if (authToken) {
    const profileResult = yield* call(getProfile, authToken);

    if (!profileResult.error) {
      yield put(
        setUserProfile({
          userProfile: profileResult.data.context,
        }),
      );
    }
  }
}

export function* needsLoginComplaintAlert(alertMessage: string) {
  const modalSize: TAlertModalSizeOption = 'small';
  const alertResult = yield* call(alertPromise, {
    title: t('ERROR_GENERIC.LOGIN_REQUIRED_TITLE'),
    message: alertMessage,
    allowCloseByBackgroundClick: true,
    modalSize,
    buttons: [
      {
        label: t('BUTTON_GENERIC.BUTTON_CANCEL'),
        resultValue: 'cancel',
      },
      {
        label: t('ERROR_GENERIC.LOGIN_REQUIRED_BUTTON_LOGIN'),
        resultValue: 'login',
      },
      {
        label: t('ERROR_GENERIC.LOGIN_REQUIRED_BUTTON_REGISTER'),
        resultValue: 'register',
      },
    ],
  });

  if (alertResult === 'login') {
    yield put(push(routes.login()));
  } else if (alertResult === 'register') {
    yield put(push(routes.register()));
  }
}

export function* watchAuth() {
  yield* takeEvery(enduserLogin.actionType, onEnduserLogin);
  yield* takeEvery(enduserLogout.actionType, onEnduserLogout);
}
