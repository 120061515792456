import {
  addEventToCart,
  addProductToCart,
  clearCart,
  discountCart,
  editCart,
  removeEventFromCart,
  removeProductFromCart,
  resetDiscount,
} from '../actions/cart';
import {
  TDiscountCodeObject,
  TDiscountListObject,
  TLineSpecifics,
  TOrder,
  TOrderLine,
} from '../api/dataTypes';
import {reducerBuilder} from '../redux/reduxUtil';
import {getArticleCountById, isGiftTicketSeries} from '../util';

function handleCartAddEvent(
  ticketId: string,
  cart: TOrder,
  ticketPrice: number,
  count: number = 1,
  isTicketDiscounted?: boolean,
): TOrder {
  let orderFound = false;
  const newLines = cart.orderLines.map((line: TOrderLine) => {
    let rline: TOrderLine = {...line};

    if (line.ticketSeries === ticketId) {
      rline = {...rline, count: rline.count! + count};

      if (line.ticketPrice !== ticketPrice) {
        rline = {...rline, ticketPrice};
      }

      orderFound = true;
    }

    return rline;
  });

  if (!orderFound) {
    const line: TOrderLine = {
      count,
      ticketSeries: ticketId,
      ticketPrice,
      isTicketDiscounted,
    };

    newLines.push(line);
  }

  return {...cart, orderLines: newLines};
}

function handleCartRemoveEvent(eventId: string, cart: TOrder) {
  let newLines: TOrderLine[] = cart.orderLines.map((line: TOrderLine) => {
    let rline: TOrderLine = {...line};

    if (line.ticketSeries === eventId) {
      rline = {...rline, count: rline.count! - 1};
    }

    return rline;
  });

  newLines = newLines.filter(
    (line: TOrderLine) =>
      line.count || (line.specifics && line.specifics.length),
  );

  return {...cart, orderLines: newLines, discountHandled: false};
}

function handleCartAddProduct(
  productID: string,
  cart: TOrder,
  specifics: TLineSpecifics,
  count: number = 1,
) {
  const orderLine = cart.orderLines.find(
    (line: TOrderLine) => line.product === productID,
  );
  const specificsToAdd = [...Array(count)].map(() => ({...specifics}));

  if (orderLine) {
    orderLine.specifics = (orderLine.specifics || [])
      .concat(specificsToAdd)
      .map((oldSpecifics: TLineSpecifics) => {
        if (
          specifics.articlePrice !== oldSpecifics.articlePrice &&
          specifics.articleId === oldSpecifics.articleId
        ) {
          return {...oldSpecifics, articlePrice: specifics.articlePrice};
        }

        return oldSpecifics;
      });

    return {...cart};
  }

  const lines = (cart.orderLines || []).concat([
    {product: productID, specifics: specificsToAdd},
  ]);

  return {...cart, orderLines: lines};
}

function handleCartRemoveProduct(
  productID: string,
  cart: TOrder,
  index: number,
) {
  let newLines: TOrderLine[] = cart.orderLines.map((line: TOrderLine) => {
    const rline = {...line};

    if (line.product === productID) {
      if (index >= 0 && rline.specifics!.length > index) {
        rline.specifics!.splice(index, 1);
      }
    }

    return rline;
  });

  newLines = newLines.filter(
    (line: TOrderLine) =>
      line.count || (line.specifics && line.specifics.length),
  );

  return {...cart, orderLines: newLines, discountHandled: false};
}

function getHighestPriceNonDiscountedTicket(orderLines: TOrderLine[]) {
  let currentMaxPrice = 0;
  let currentSeriesId = '';

  orderLines.forEach((line: TOrderLine) => {
    let discounted = 0;

    if (typeof line.codeDiscountedCount === 'number') {
      discounted = line.codeDiscountedCount;
    }

    if (
      line.ticketSeries &&
      line.ticketPrice &&
      !isGiftTicketSeries(line.ticketSeries || '')
    ) {
      const price = +line.ticketPrice;

      if (price > currentMaxPrice && (line.count || 0) > discounted) {
        currentMaxPrice = price;
        currentSeriesId = line.ticketSeries;
      }
    }
  });

  return currentSeriesId;
}

function getHighestPriceNonDiscountedArticle(orderLines: TOrderLine[]) {
  let currentMaxPrice = 0;
  let currentArticleId = '';

  orderLines.forEach((line: TOrderLine) => {
    let discounted = 0;

    if (typeof line.codeDiscountedCount === 'number') {
      discounted = line.codeDiscountedCount;
    }

    if (line.product && line.specifics) {
      const sortedSpecs = [...line.specifics];

      sortedSpecs.sort(
        (a: TLineSpecifics, b: TLineSpecifics) =>
          b.articlePrice - a.articlePrice,
      );
      sortedSpecs.forEach((s: TLineSpecifics, index: number) => {
        if (s.articlePrice > currentMaxPrice && index + 1 > discounted) {
          currentArticleId = s.articleId;
          currentMaxPrice = s.articlePrice;
        }
      });
    }
  });

  return currentArticleId;
}

function handleCartDiscount(discount: TDiscountCodeObject, cart: TOrder) {
  let currentArticleDiscount = 0;
  let currentTicketDiscount = 0;

  let maxTotalCount = 0;

  if (typeof discount.maxCount === 'number') {
    // 0 === "infinite"
    if (discount.maxCount === 0) {
      maxTotalCount = 999999;
    } else {
      maxTotalCount = discount.maxCount;
    }
  }

  let maxArticleCount = maxTotalCount;
  let maxTicketCount = maxTotalCount;

  if (discount.articles && typeof discount.articles.maxCount === 'number') {
    // 0 === "infinite"
    if (discount.articles.maxCount === 0) {
      maxArticleCount = 999999;
    } else {
      maxArticleCount = discount.articles.maxCount;
    }
  }

  if (
    discount.ticketSeries &&
    typeof discount.ticketSeries.maxCount === 'number'
  ) {
    if (discount.ticketSeries.maxCount === 0) {
      maxTicketCount = 999999;
    } else {
      maxTicketCount = discount.ticketSeries.maxCount;
    }
  }

  let newLines = [...cart.orderLines];

  if (
    discount.ticketSeries &&
    currentTicketDiscount < maxTicketCount &&
    currentArticleDiscount + currentTicketDiscount < maxTotalCount
  ) {
    const dl: TDiscountListObject = discount.ticketSeries;

    if (dl.ids) {
      const {ids} = dl;

      if (ids.length === 0) {
        // Any ticket
        let highestId = getHighestPriceNonDiscountedTicket(newLines);
        const handleTicketDiscount = (line: TOrderLine) => {
          const rline: TOrderLine = {...line};

          if (rline.ticketSeries === highestId) {
            const fromMax =
              maxTotalCount - (currentArticleDiscount + currentTicketDiscount);
            const fromTicketMax = maxTicketCount - currentTicketDiscount;
            let fromUsed = fromMax < fromTicketMax ? fromMax : fromTicketMax;

            if (fromUsed > rline.count!) {
              fromUsed = rline.count!;
            }

            const countAmount = fromUsed;

            currentTicketDiscount += countAmount;
            rline.appliedCodeDiscount = discount;

            if (typeof rline.codeDiscountedCount !== 'number') {
              rline.codeDiscountedCount = countAmount;
            } else {
              rline.codeDiscountedCount += countAmount;
            }
          }

          return rline;
        };

        while (
          highestId !== '' &&
          currentTicketDiscount < maxTicketCount &&
          currentArticleDiscount + currentTicketDiscount < maxTotalCount
        ) {
          newLines = newLines.map(handleTicketDiscount);
          highestId = getHighestPriceNonDiscountedTicket(newLines);
        }
      } else {
        // Specific tickets
        newLines = newLines.map((line: TOrderLine) => {
          const rline: TOrderLine = {...line};

          ids.forEach((id: string) => {
            if (id === line.ticketSeries) {
              const fromMax =
                maxTotalCount -
                (currentArticleDiscount + currentTicketDiscount);
              const fromTicketMax = maxTicketCount - currentTicketDiscount;
              let fromUsed = fromMax < fromTicketMax ? fromMax : fromTicketMax;

              if (fromUsed > rline.count!) {
                fromUsed = rline.count!;
              }

              const countAmount = fromUsed;

              currentTicketDiscount += countAmount;
              rline.appliedCodeDiscount = discount;

              if (typeof rline.codeDiscountedCount !== 'number') {
                rline.codeDiscountedCount = countAmount;
              } else {
                rline.codeDiscountedCount += countAmount;
              }
            }
          });

          return rline;
        });
      }
    }
  }

  if (
    discount.articles &&
    currentArticleDiscount < maxArticleCount &&
    currentArticleDiscount + currentTicketDiscount < maxTotalCount
  ) {
    const dl: TDiscountListObject = discount.articles;

    if (dl.ids) {
      const {ids} = dl;

      if (ids.length === 0) {
        // Any article
        let highestId = getHighestPriceNonDiscountedArticle(newLines);

        const handleArticleDiscount = (line: TOrderLine): TOrderLine => {
          const rline: TOrderLine = {...line};

          if (rline.specifics) {
            const index = rline.specifics.findIndex(
              (s: TLineSpecifics) => s.articleId === highestId,
            );

            if (index >= 0) {
              const fromMax =
                maxTotalCount -
                (currentArticleDiscount + currentTicketDiscount);
              const fromArticleMax = maxArticleCount - currentArticleDiscount;
              let fromUsed =
                fromMax < fromArticleMax ? fromMax : fromArticleMax;
              const specCount = getArticleCountById(rline.specifics, highestId);

              if (fromUsed > specCount) {
                fromUsed = specCount;
              }

              const countAmount = fromUsed;

              currentArticleDiscount += countAmount;
              rline.appliedCodeDiscount = discount;

              if (typeof rline.codeDiscountedCount !== 'number') {
                rline.codeDiscountedCount = countAmount;
              } else {
                rline.codeDiscountedCount += countAmount;
              }
            }
          }

          return rline;
        };

        while (
          highestId !== '' &&
          currentArticleDiscount < maxArticleCount &&
          currentArticleDiscount + currentTicketDiscount < maxTotalCount
        ) {
          newLines = newLines.map(handleArticleDiscount);
          highestId = getHighestPriceNonDiscountedArticle(newLines);
        }
      } else {
        // Specific articles
        const handleArticleDiscount = (line: TOrderLine): TOrderLine => {
          const rline: TOrderLine = {...line};

          ids.forEach((id: string) => {
            if (rline.specifics) {
              rline.specifics.some((s: TLineSpecifics) => {
                if (id === s.articleId) {
                  const fromMax =
                    maxTotalCount -
                    (currentArticleDiscount + currentTicketDiscount);
                  const fromArticleMax =
                    maxArticleCount - currentArticleDiscount;
                  let fromUsed =
                    fromMax < fromArticleMax ? fromMax : fromArticleMax;

                  if (fromUsed > rline.count!) {
                    fromUsed = rline.count!;
                  }

                  const countAmount = fromUsed;

                  currentArticleDiscount += countAmount;
                  rline.appliedCodeDiscount = discount;

                  if (typeof rline.codeDiscountedCount !== 'number') {
                    rline.codeDiscountedCount = countAmount;
                  } else {
                    rline.codeDiscountedCount += countAmount;
                  }

                  return true;
                }

                return false;
              });
            }
          });

          return rline;
        };

        newLines = newLines.map(handleArticleDiscount);
      }
    }
  }

  return {...cart, orderLines: newLines, discountHandled: true};
}

function resetCartDiscount(cart: TOrder) {
  const newLines = cart.orderLines.map((line: TOrderLine) => {
    const {codeDiscountedCount, appliedCodeDiscount, ...other} = line;

    return {...other} as TOrderLine;
  });

  return {...cart, orderLines: newLines, discountHandled: false};
}

export const initialState: TOrder = {
  customer: '',
  language: '',
  orderPoint: '',
  utcOffset: 0,
  orderLines: [],
  discountHandled: false,
};

export const cart = reducerBuilder<TOrder>(initialState)
  .matchAction(editCart, (_state, action) => {
    return action.payload.order;
  })
  .matchAction(clearCart, (_state, _action) => {
    return initialState;
  })
  .matchAction(addEventToCart, (_state, action) => {
    if (
      typeof action.payload.ticketID === 'string' &&
      typeof action.payload.ticketPrice === 'number'
    ) {
      return handleCartAddEvent(
        action.payload.ticketID,
        action.payload.cart,
        action.payload.ticketPrice,
        action.payload.count,
        action.payload.isTicketDiscounted,
      );
    }

    return action.payload.cart;
  })
  .matchAction(removeEventFromCart, (_state, action) => {
    if (typeof action.payload.ticketID === 'string') {
      return handleCartRemoveEvent(
        action.payload.ticketID,
        action.payload.cart,
      );
    }

    return action.payload.cart;
  })
  .matchAction(addProductToCart, (_state, action) => {
    if (
      typeof action.payload.productID === 'string' &&
      action.payload.specifics
    ) {
      return handleCartAddProduct(
        action.payload.productID,
        action.payload.cart,
        action.payload.specifics,
        action.payload.count,
      );
    }

    return action.payload.cart;
  })
  .matchAction(removeProductFromCart, (_state, action) => {
    if (
      typeof action.payload.productID === 'string' &&
      typeof action.payload.index === 'number'
    ) {
      return handleCartRemoveProduct(
        action.payload.productID,
        action.payload.cart,
        action.payload.index,
      );
    }

    return action.payload.cart;
  })
  .matchAction(discountCart, (state, action) => {
    if (action.payload.discount && action.payload.cart) {
      const newCart = resetCartDiscount(action.payload.cart);

      return handleCartDiscount(action.payload.discount, newCart);
    }

    return state;
  })
  .matchAction(resetDiscount, (state, action) => {
    if (action.payload.cart) {
      return resetCartDiscount(action.payload.cart);
    }

    return state;
  })
  .finish();
