import React, {useRef} from 'react';
import {useParams} from 'react-router';
import ReactPlayer from 'react-player';

import {useTypedSelector} from '../redux/reduxUtil';
import {Button, Icon} from 'semantic-ui-react';
import {useDispatch} from 'react-redux';
import {goBack} from 'connected-react-router';
import {useLoggedIn} from '../redux/reduxHooks';
import {PageWithLoginComplaint} from './LoginRequiredComplaint';
import {t} from '../i18n';

export function StreamVideoPlayerPage() {
  const dispatch = useDispatch();
  const player = useRef<ReactPlayer | null>(null);

  const loggedIn = useLoggedIn();

  const urlParams = useParams<{event: string}>();

  const eventId = urlParams.event || '';

  const {videoStreamViewProps} = useTypedSelector((state) => ({
    event: state.events.events.find((event) => event._id === eventId),
    videoStreamViewProps: state.stream.videoStreamViewProps,
  }));

  const navigateBack = () => {
    dispatch(goBack());
  };

  const onPlayerStart = () => {
    const introLength = videoStreamViewProps?.introLength;
    if (introLength && player?.current) {
      player.current.seekTo(introLength);
    }
  }

  if (!loggedIn) {
    return (
      <PageWithLoginComplaint
        specificComplaintMessage={t(
          'ERROR_GENERIC.LOGIN_REQUIRED_COMPONENT_MESSAGE_EVENT_STREAM',
        )}
      />
    );
  }

  if (videoStreamViewProps) {
    return (
      <div className="StreamVideoPlayerPage">
        <ReactPlayer
          ref={player}
          onDuration={onPlayerStart}
          url={videoStreamViewProps.url}
          controls
          style={{
            width: '100%',
            height: '100%',
            marginLeft: 45,
            marginRight: 45,
            paddingTop: 8,
          }}
          width={'100%'}
          height={'100%'}
        />
        <div className="StreamVideoPlayerPageExtraControlsBackContainer">
          <Button inverted primary icon onClick={navigateBack}>
            <Icon name="arrow left" />
          </Button>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
