import {Image, Button} from 'semantic-ui-react';
import {Link} from 'react-router-dom';

import {t} from '../i18n';
import {getCompanyContactInfo, mapLogo, poweredIcon, TPerson} from '../configs';
import {useTypedSelector} from '../redux/reduxUtil';
import {getMapUrlFromQuery} from '../util';
import {routes} from '../routes';

export const ContactPage = () => {
  const company = useTypedSelector((state) => state.company?.currentCompany);
  const contactInfo = getCompanyContactInfo(company?.slug || '');

  return (
    <div className="ContactPage">
      {contactInfo && (
        <div>
          <p>{company?.name}</p>
          <p>{company?.streetAddress}</p>
          <p>{`${company?.postalCode || ''} ${company?.city || ''}`}</p>
          {contactInfo.phone ? <p>{contactInfo.phone}</p> : null}
          {contactInfo.email ? <p>{contactInfo.email}</p> : null}
        </div>
      )}
      {
        <a
          className="ElementWrapper"
          href={getMapUrlFromQuery(contactInfo?.mapQuery || '')}
          target="_blank"
          rel="noopener noreferrer">
          <Image src={mapLogo} />
        </a>
      }
      {contactInfo &&
        contactInfo.persons.map((person: TPerson) =>
          person.phone ? (
            <div className="PersonWrapper" key={person.name + person.email}>
              <p>{person.title}</p>
              <p>{person.name}</p>
              <p>{person.phone}</p>
              <p>{person.email}</p>
            </div>
          ) : (
            <div className="PersonWrapper" key={person.name + person.email}>
              <p>{person.title}</p>
              <p>{person.name}</p>
              <p>{person.email}</p>
            </div>
          ),
        )}
      <div className="ElementWrapper">
        <p>{t('CONTACT_PAGE.CONTACT')}</p>
        <Button
          as={Link}
          to={routes.termsOfService()}
          inverted
          primary
          fluid>
          {t('ABOUT_PAGE.TERMS')}
        </Button>
        <Button
          as={Link}
          to={routes.privacyPolicy()}
          inverted
          primary
          fluid>
          {t('ABOUT_PAGE.PRIVACY')}
        </Button>
        <Image className="PoweredLogo" src={poweredIcon} />
        <p>{t('CONTACT_PAGE.COPYRIGHT')}</p>
      </div>
    </div>
  );
};
