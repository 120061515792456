import {actionCreator} from '../redux/reduxUtil';

export const showInitialLoadingScreen = actionCreator<{
  show: boolean;
}>('loading.showInitialLoadingScreen');

export const showLightLoadingScreen = actionCreator<{
  show: boolean;
}>('loading.showLightLoadingScreen');

export const showHeavyLoadingScreen = actionCreator<{
  show: boolean;
}>('loading.showHeavyLoadingScreen');

export const rerenderRouter = actionCreator<{}>('loading.rerenderRouter');

export const screenSizeChanged = actionCreator<{
  width: number;
  height: number;
}>('loading.screenSizeChanged');

export const setUsingMobileStyles = actionCreator<{
  usingMobileStyles: boolean;
}>('loading.setUsingMobileStyles');
