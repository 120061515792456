import React from 'react';
import {ContactPage} from './components/ContactPage';
import {HomePage} from './components/HomePage';
import {IntroductionPage} from './components/IntroductionPage';
import {TicketsView} from './components/TicketsView';
import {routeMatchers, routes} from './routes';

export type TTabsConfigPerTab = {
  label: string;
  route: string | string[];
  tabClickDestination: string;
  requiresLogin: boolean;
  component: any;
  icon: any;
};

export const tabsConfig: TTabsConfigPerTab[] = [
  {
    label: 'TABS.HOME',
    route: routeMatchers.tabHome,
    tabClickDestination: routes.root(),
    requiresLogin: false,
    component: <HomePage />,
    icon: 'home',
  },
  {
    label: 'TABS.TICKETS',
    route: routes.tabTickets(),
    tabClickDestination: routes.tabTickets(),
    requiresLogin: true,
    component: <TicketsView />,
    icon: 'ticket',
  },
  {
    label: 'TABS.CONTACT',
    route: routes.tabContact(),
    tabClickDestination: routes.tabContact(),
    requiresLogin: false,
    component: <ContactPage />,
    icon: 'home',
  },
  {
    label: 'TABS.INTRODUCTION',
    route: routes.tabIntroduction(),
    tabClickDestination: routes.tabIntroduction(),
    requiresLogin: false,
    component: <IntroductionPage />,
    icon: 'home',
  },
];
