import {TReceiptInputObject} from '../api/dataTypes';
import {TOffsitePaymentType} from '../reducers/payment';
import {actionCreator} from '../redux/reduxUtil';

export type TPaymentMethodSelection =
  | 'existingCard'
  | 'newCard'
  | 'discount100';

export const setReceiptInput = actionCreator<{
  receiptInput?: TReceiptInputObject | undefined;
}>('payment.setReceiptInput');

export const enduserPaymentSettingsSetDefaultCard = actionCreator<{
  cardIndex: number;
}>('payment.enduserPaymentSettingsSetDefaultCard');

export const enduserPaymentSettingsPay = actionCreator<{
  paymentMethod: TPaymentMethodSelection;
  receiptInput: TReceiptInputObject;
}>('payment.enduserPaymentSettingsPay');

export const setOngoingPaymentData = actionCreator<{
  payformPaymentUrl?: string | undefined;
  payformPaymentToken?: string | undefined;
  offsitePaymentType?: TOffsitePaymentType | undefined;
}>('payment.setOngoingPaymentData');

export const enduserAddNewCard = actionCreator<{}>('payment.enduserAddNewCard');

export const enduserNavigateToPaymentSettingsPage = actionCreator<{
  receiptInput: TReceiptInputObject;
}>('payment.enduserNavigateToPaymentSettingsPage');
