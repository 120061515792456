import React from 'react';
import {Dimmer, Loader} from 'semantic-ui-react';
import {t} from '../i18n';

export function LoadingPage() {
  return (
    <div className="LoadingPage">
      <Dimmer active={true}>
        <Loader size="massive">{t('PAGE_LOADING.LOADING')}</Loader>
      </Dimmer>
    </div>
  );
}
