import React from 'react';
import {useDispatch} from 'react-redux';
import {Button, Icon, Image, Radio} from 'semantic-ui-react';
import {
  enduserAddNewCard,
  enduserPaymentSettingsSetDefaultCard,
} from '../actions/payment';
import {t} from '../i18n';
import {useTypedSelector} from '../redux/reduxUtil';
import {Topbar} from './Topbar';
import {ProfileSubPageVerticalMenu} from './ProfilePage';
import {alertPromise} from '../alert';
import {enduserProfileDeleteCard} from '../actions/profile';
import {useLoggedIn} from '../redux/reduxHooks';
import {PageWithLoginComplaint} from './LoginRequiredComplaint';
import { vismaLogo } from '../configs';

export function ProfileCardManagementPage() {
  const dispatch = useDispatch();
  const {userProfile} = useTypedSelector((state) => ({
    userProfile: state.auth.userProfile,
  }));
  const loggedIn = useLoggedIn();

  const cards = userProfile?.cards || [];

  const onButtonAddNewCard = () => {
    dispatch(enduserAddNewCard({}));
  };

  const onButtonDeleteCard = async (cardToken: string) => {
    const alertResult = await alertPromise({
      title: t('PAGE_PROFILE.DELETE_CARD_CONFIRM_TITLE'),
      message: t('PAGE_PROFILE.DELETE_CARD_CONFIRM_MESSAGE'),
      buttons: [
        {
          label: t('BUTTON_GENERIC.BUTTON_OK'),
          resultValue: 'ok',
        },
        {
          label: t('BUTTON_GENERIC.BUTTON_CANCEL'),
          resultValue: 'cancel',
        },
      ],
    });

    if (alertResult === 'ok') {
      dispatch(enduserProfileDeleteCard({cardToken: cardToken}));
    }
  };

  if (!loggedIn) {
    return <PageWithLoginComplaint />;
  }

  return (
    <div className="AuthPageVerticalRoot">
      <Topbar />

      <div className="SubmenuHorizontalRoot">
        <div className="ProfilePageContainer">
          <div className="SubmenuHorizontalRight">
            <ProfileSubPageVerticalMenu />
            <div className="PaymentSettingsPageMainScroll">
              <div className="PaymentSettingsPageMainScrollContent">
                <div className="PaymentSettingsPageCardsContainer">
                  {cards.map((card, cardIndex) => {
                    return (
                      <div key={cardIndex} className="PaymentSettingsPageCard">
                        <div className="PaymentSettingsPageCardRadioContainer">
                          <Radio
                            onChange={() => {
                              dispatch(
                                enduserPaymentSettingsSetDefaultCard({
                                  cardIndex: cardIndex,
                                }),
                              );
                            }}
                            checked={card.isDefault === true}
                          />
                        </div>
                        <div className="PaymentSettingsPageCardLeft">
                          <p className="PaymentSettingsPageCardAnyLabel">
                            {card.type}
                          </p>
                          <p className="PaymentSettingsPageCardAnyLabel">
                            {`**** **** **** ${card.partialPan}`}
                          </p>
                        </div>
                        <div className="PaymentSettingsPageCardRight">
                          <p className="PaymentSettingsPageCardAnyLabel">{`${card.expireMonth}/${card.expireYear}`}</p>
                        </div>
                        <div className="PaymentSettingsPageCardRight PaymentSettingsPageCardRightIcon">
                          <Button
                            primary
                            onClick={() => {
                              if (card.token) {
                                onButtonDeleteCard(card.token);
                              }
                            }}
                            icon>
                            <Icon name="trash" />
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          <div className="PaymentSettingsPageBottom">
            <Button inverted onClick={onButtonAddNewCard}>
              {t('PAGE_PROFILE.BUTTON_ADD_NEW_CARD')}
            </Button>
            <Image className="VismaLogo" src={vismaLogo} />
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}
