import {actionCreator} from '../redux/reduxUtil';

export const enduserProfileConfirmChanges = actionCreator<{
  firstName: string;
  lastName: string;
  canSendVendorEmail: boolean;
}>('profile.enduserProfileConfirmChanges');

export const enduserProfileDeleteCard = actionCreator<{
  cardToken: string;
}>('profile.enduserProfileDeleteCard');
