import {AnyAction, Reducer} from 'redux';
import {SelectEffect, Tail} from 'redux-saga/effects';
import {SagaGenerator, select} from 'typed-redux-saga';
import {TypedUseSelectorHook, useSelector} from 'react-redux';

import {TStoreState} from '../reducers/index';

export type TGappAction<TPayload> = {
  type: string;
  payload: TPayload;
};

export type TGappActionCreator<TPayload> = ((
  payload: TPayload,
) => TGappAction<TPayload>) & {
  actionType: string;
};

export type ActionOfActionCreator<
  TActionCreator extends TGappActionCreator<any>
> = ReturnType<TActionCreator>;

export type TGappReducerBuilder<TReducerState> = {
  matchAction<TPayload>(
    actionCreator: TGappActionCreator<TPayload>,
    onAction: (
      state: TReducerState,
      action: TGappAction<TPayload>,
    ) => TReducerState,
  ): TGappReducerBuilder<TReducerState>;

  finish(): Reducer<TReducerState>;
};

export function actionCreator<TPayload>(
  actionType: string,
): TGappActionCreator<TPayload> {
  return Object.assign(
    (payload: TPayload) => {
      return {
        type: actionType,
        payload: payload,
      };
    },
    {
      actionType: actionType,
    },
  );
}

export function reducerBuilder<TReducerState>(
  initialState: TReducerState,
): TGappReducerBuilder<TReducerState> {
  const actionTypeToReducerfuncMap: Map<
    string,
    (state: TReducerState, action: TGappAction<any>) => TReducerState
  > = new Map();

  const builder: TGappReducerBuilder<TReducerState> = {
    matchAction: (actionCreator, onAction) => {
      actionTypeToReducerfuncMap.set(actionCreator.actionType, onAction);
      return builder;
    },
    finish: () => {
      return (state: TReducerState | undefined, anyAction: AnyAction) => {
        if (state) {
          if (
            typeof anyAction === 'object' &&
            typeof anyAction.type === 'string'
          ) {
            const foundMappedReducerfunc = actionTypeToReducerfuncMap.get(
              anyAction.type,
            );

            if (foundMappedReducerfunc) {
              return foundMappedReducerfunc(
                state,
                anyAction as TGappAction<any>,
              );
            }
          }

          return state;
        } else {
          return initialState;
        }
      };
    },
  };

  return builder;
}

export function typedSelect(): SagaGenerator<TStoreState, SelectEffect>;
export function typedSelect<
  Fn extends (state: TStoreState, ...args: any[]) => any
>(
  selector: Fn,
  ...args: Tail<Parameters<Fn>>
): SagaGenerator<ReturnType<Fn>, SelectEffect>;

export function typedSelect() {
  return select.apply(undefined, arguments as any);
}

export const useTypedSelector: TypedUseSelectorHook<TStoreState> = useSelector;
