import {TProfileObject} from '../api/dataTypes';
import {TOngoingForgotPassword, TOngoingRegister} from '../reducers/auth';
import {actionCreator} from '../redux/reduxUtil';

export const enduserLogin =
  actionCreator<{
    username: string;
    password: string;
  }>('auth.enduserLogin');

export const enduserLogout = actionCreator<{}>('auth.enduserLogout');

export const resetReduxStateToInitial = actionCreator<{}>(
  'auth.resetReduxStateToInitial',
);

export const setAuthToken =
  actionCreator<{
    token: string | undefined;
  }>('auth.setAuthToken');

export const setUserProfile = actionCreator<{
  userProfile?: TProfileObject;
}>('auth.setUserProfile');

export const enduserForgotPasswordInputEmail = actionCreator<{
  email: string;
}>('auth.enduserForgotPasswordInputEmail');

export const enduserForgotPasswordInputResetCodeAndPassword = actionCreator<{
  resetCode: string;
  password: string;
}>('auth.enduserForgotPasswordInputResetCodeAndPassword');

export const enduserForgotPasswordResendCode = actionCreator<{}>(
  'auth.enduserForgotPasswordResendCode',
);

export const enduserRegister = actionCreator<{
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  canSendVendorEmail: boolean;
}>('auth.enduserRegister');

export const enduserRegisterInputCode = actionCreator<{
  code: string;
}>('auth.enduserRegisterInputCode');

export const enduserRegisterResendCode = actionCreator<{}>(
  'auth.enduserRegisterResendCode',
);

export const setOngoingRegister = actionCreator<TOngoingRegister | undefined>(
  'auth.setOngoingRegister',
);

export const setOngoingForgotPassword = actionCreator<
  TOngoingForgotPassword | undefined
>('auth.setOngoingForgotPassword');
