import React from 'react';
import Moment from 'moment';
import {useDispatch} from 'react-redux';
import {Button, Segment, Icon} from 'semantic-ui-react';

import {TEventObject, TTicketObject} from '../api/dataTypes';
import {useTypedSelector} from '../redux/reduxUtil';
import {
  eventOndemandStreamFormatAvailableUntilDateString,
  eventOndemandStreamStillAvailable,
  streamEventIsFinished,
  streamEventIsLiveNow,
  streamOndemandOwned,
  streamOwned,
} from '../util';
import {enduserNavigateToStreamPlayerPageOfEvent} from '../actions/stream';
import {t, format} from '../i18n';
import {alert} from '../alert';

export function LivestreamStartButton(props: {event: TEventObject}) {
  const dispatch = useDispatch();

  const {tickets} = useTypedSelector((state) => ({
    tickets: state.ticket.tickets,
  }));

  const limits = getLivestreamStartButtonLimits(props.event, tickets);

  const playStreamOfEvent = (event: TEventObject, tickets: TTicketObject[]) => {
    if (event) {
      const limits = getLivestreamStartButtonLimits(event, tickets);

      if (limits.showPlayButton === false || limits.gotAccess === false) {
        alert({
          title: t('STREAM.PLAY_BUTTON_FAILED_ALERT_TITLE'),
          message: limits.playbuttonLabel,
        });
      } else if (limits.gotAccess && limits.showPlayButton) {
        dispatch(
          enduserNavigateToStreamPlayerPageOfEvent({event: event}),
        );
      }
    }
  };

  if (streamOwned(props.event._id, tickets) || streamOndemandOwned(props.event._id, tickets)) {
    return (
      <Segment
        color="teal"
        inverted
        className={'LivestreamStartButton'}>
        <div className="LivestreamStartButtonContent">
          <div className="LivestreamStartButtonContentInfo">
            <p>{limits.playbuttonLabel}</p>
          </div>
          <Button
            onClick={() => playStreamOfEvent(props.event, tickets)}
            icon
            primary
            basic
            inverted
            labelPosition="left"
            size="large">
            <Icon name="play" />
            {t('BUTTON_GENERIC.PLAY_VIDEO')}
          </Button>
        </div>
      </Segment>
    );
  } else {
    return null;
  }
}

type TLivestreamStartButtonLimits = {
  showPlayButton: boolean;
  playbuttonLabel: string;
  gotAccess: boolean;
};

export function getLivestreamStartButtonLimits(
  event: TEventObject,
  tickets: TTicketObject[],
): TLivestreamStartButtonLimits {
  const isLivestreaming = streamEventIsLiveNow(event);

  let showPlayButton = false;
  let playbuttonLabel = '';
  let gotAccess = false;

  if (isLivestreaming) {
    if (streamOwned(event._id, tickets)) {
      gotAccess = true;
      showPlayButton = true;
      playbuttonLabel = t('STREAM.STREAM_ONGOING');
    }
  } else if (streamOndemandOwned(event._id, tickets)) {
    gotAccess = true;

    if (eventOndemandStreamStillAvailable(event)) {
      showPlayButton = true;

      const availableUntilDateString = eventOndemandStreamFormatAvailableUntilDateString(
        event,
      );

      playbuttonLabel = format(
        'STREAM.STREAM_ONDEMAND_AVAILABLE_UNTIL',
        availableUntilDateString,
      );
    } else if (streamEventIsFinished(event)) {
      showPlayButton = false;
      playbuttonLabel = t('STREAM.STREAM_ONDEMAND_NO_LONGER_AVAILABLE');
    } else {
      const ondemandStartDate = Moment(
        event.streamInfo && event.streamInfo.liveStreamEndDate,
      ).format('DD.MM.YYYY HH:mm');

      playbuttonLabel = format('STREAM.ONDEMAND_STARTS_AT', ondemandStartDate);
    }
  } else if (streamOwned(event._id, tickets)) {
    gotAccess = true;
    const streamStartDate = Moment(
      event.streamInfo && event.streamInfo.liveStreamStartDate,
    ).format('DD.MM.YYYY HH:mm');

    playbuttonLabel = format('STREAM.STREAM_STARTS_AT', streamStartDate);
  }

  return {
    showPlayButton,
    playbuttonLabel,
    gotAccess,
  };
}
