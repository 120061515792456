import React from 'react';
import Moment from 'moment';

import {TEventObject, TTicketObject} from '../api/dataTypes';
import {useTypedSelector} from '../redux/reduxUtil';
import {
  eventLiveStreamStillAvailable,
  eventOndemandStreamStillAvailable,
  getExpirationDateFromTicket,
  getNameObject,
  getTicketSeriesById,
  ticketIsOndemandStreamTicket,
  ticketIsStreamTicket,
} from '../util';
import {t} from '../i18n';
import {Button, Icon} from 'semantic-ui-react';
import {alert} from '../alert';
import {getLivestreamStartButtonLimits} from './LivestreamStartButton';
import {useDispatch} from 'react-redux';
import {enduserNavigateToStreamPlayerPageOfEvent} from '../actions/stream';
import {LoginRequiredComplaint} from './LoginRequiredComplaint';
import {useLoggedIn} from '../redux/reduxHooks';

export function TicketsView() {
  const {tickets} = useTypedSelector((state) => ({
    tickets: state.ticket.tickets,
  }));
  const dispatch = useDispatch();
  const loggedIn = useLoggedIn();

  const playStreamOfTicket = (ticket: TTicketObject) => {
    if (ticket.event) {
      const limits = getLivestreamStartButtonLimits(ticket.event, tickets);

      if (limits.showPlayButton === false || limits.gotAccess === false) {
        alert({
          title: t('STREAM.PLAY_BUTTON_FAILED_ALERT_TITLE'),
          message: limits.playbuttonLabel,
        });
      } else if (limits.gotAccess && limits.showPlayButton) {
        dispatch(
          enduserNavigateToStreamPlayerPageOfEvent({event: ticket.event}),
        );
      }
    }
  };

  const streamTickets = tickets.filter((ticket) => {
    return ticketIsStreamTicket(ticket) || ticketIsOndemandStreamTicket(ticket);
  });

  streamTickets.sort((a, b) => {
    if (a.event && b.event && a.dateCreated && b.dateCreated) {
      const aEvent = a.event;
      const bEvent = b.event;

      if (aEvent.startDate && bEvent.startDate) {
        const aCompare = aEvent.startDate + a.dateCreated;
        const bCompare = bEvent.startDate + b.dateCreated;

        if (typeof aCompare === 'string' && typeof bCompare === 'string') {
          return aCompare.localeCompare(bCompare);
        }
      }
    }

    return 0;
  });

  const ongoingStreamTickets = streamTickets.filter((ticket) => {
    if (
      !ticket.isDeleted &&
      !isTicketHistory(ticket) &&
      isTicketOngoing(ticket)
    ) {
      return true;
    }

    return false;
  });

  const upcomingStreamTickets = streamTickets.filter((ticket) => {
    if (
      !ticket.isDeleted &&
      !isTicketHistory(ticket) &&
      !isTicketOngoing(ticket)
    ) {
      return true;
    }

    return false;
  });

  const historyStreamTickets = streamTickets
    .sort((a: TTicketObject, b: TTicketObject) => {
      if (a.event && b.event && a.dateCreated && b.dateCreated) {
        const aEvent = a.event;
        const bEvent = b.event;

        if (aEvent.startDate && bEvent.startDate) {
          const aCompare = aEvent.startDate + a.dateCreated;
          const bCompare = bEvent.startDate + b.dateCreated;

          if (typeof aCompare === 'string' && typeof bCompare === 'string') {
            return bCompare.localeCompare(aCompare);
          }
        }
      }

      return 0;
    })
    .filter((tck: TTicketObject) => {
      if (!tck.isDeleted && isTicketHistory(tck) && !isTicketOngoing(tck)) {
        return true;
      }

      return false;
    });

  if (!tickets.length) {
    return (
      <div className="TicketListingWithSubtitle">
        <p className="Subtitle">{t('TICKETS_VIEW.EMPTY_LIST')}</p>
      </div>
    );
  }

  if (!loggedIn) {
    return <LoginRequiredComplaint />;
  }

  return (
    <div className="TicketsView">
      {!!ongoingStreamTickets.length && (
        <TicketListingWithSubtitle
          subtitle={t('TICKETS_VIEW.SUBTITLE.ONGOING')}
          tickets={ongoingStreamTickets}
          listingType={'ongoing'}
          playStreamOfTicket={playStreamOfTicket}
        />
      )}
      {!!upcomingStreamTickets.length && (
        <TicketListingWithSubtitle
          subtitle={t('TICKETS_VIEW.SUBTITLE.UPCOMING')}
          tickets={upcomingStreamTickets}
          listingType={'upcoming'}
          playStreamOfTicket={playStreamOfTicket}
        />
      )}
      {!!historyStreamTickets.length && (
        <TicketListingWithSubtitle
          subtitle={t('TICKETS_VIEW.SUBTITLE.HISTORY')}
          tickets={historyStreamTickets}
          listingType={'history'}
          playStreamOfTicket={playStreamOfTicket}
        />
      )}
    </div>
  );
}

function isTicketOngoing(t: TTicketObject) {
  if (t.event) {
    const {event} = t;

    if (ticketIsStreamTicket(t) && ticketIsOndemandStreamTicket(t)) {
      return (
        eventLiveStreamStillAvailable(event) ||
        eventOndemandStreamStillAvailable(event)
      );
    } else if (ticketIsOndemandStreamTicket(t)) {
      return eventOndemandStreamStillAvailable(event);
    } else if (ticketIsStreamTicket(t)) {
      return eventLiveStreamStillAvailable(event);
    }

    return (
      Moment(event.startDate).isBefore(new Date(), 'minute') &&
      Moment(event.endDate).isAfter(new Date(), 'minute')
    );
  }

  return false;
}

function isTicketHistory(t: TTicketObject) {
  if (t.event) {
    const {event} = t;

    return Moment.utc().isAfter(getExpirationDateFromTicket(t, event));
  }

  return false;
}

function TicketListingWithSubtitle(props: {
  subtitle: string;
  tickets: TTicketObject[];
  listingType: 'ongoing' | 'upcoming' | 'history';
  playStreamOfTicket: (ticket: TTicketObject) => void;
}) {
  return (
    <div className="TicketListingWithSubtitle">
      <p className="Subtitle">{props.subtitle}</p>
      <div className="TicketListingTickets">
        {props.tickets.map((ticket) => {
          return (
            <SingleTicket
              key={ticket._id}
              ticket={ticket}
              isHistory={props.listingType === 'history'}
              onButtonPlay={() => {
                props.playStreamOfTicket(ticket);
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

function SingleTicket(props: {
  ticket: TTicketObject;
  isHistory: boolean;
  onButtonPlay: () => void;
}) {
  let series;

  if (typeof props.ticket.ticketSeries === 'string' && props.ticket.event) {
    const ticketId: string = props.ticket.ticketSeries;
    const {event}: {event?: TEventObject} = props.ticket;

    series = getTicketSeriesById(ticketId, [event]);
  }

  let date = '';
  let time = '';

  if (props.ticket.event) {
    const e = props.ticket.event;

    date = Moment((e || {}).gigStartDate).format('D.M.');
    time = Moment((e || {}).gigStartDate).format('HH:mm');
  }

  return (
    <div className="TicketsViewSingleTicket">
      <div className="TicketsViewSingleTicketLeftDatetime">
        <p className="TicketsViewSingleTicketLeftDatetimeDate">{date}</p>
        <p className="TicketsViewSingleTicketLeftDatetimeTime">{time}</p>
      </div>
      <div className="TicketsViewSingleTicketRightMain">
        <p className="TicketsViewSingleTicketRightMainName">
          {props.ticket.event ? getNameObject(props.ticket.event.names) : ''}
        </p>
        <p className="TicketsViewSingleTicketRightMainTicketseriesName">
          {series ? getNameObject(series.names) : ''}
        </p>
        {!props.isHistory && (
          <div className="TicketsViewSingleTicketRightMainButtons">
            <Button
              inverted
              primary
              icon
              labelPosition="left"
              onClick={props.onButtonPlay}>
              <Icon name="play" />
              {t('BUTTON_GENERIC.PLAY_VIDEO')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
