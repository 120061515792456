import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {Input, Button, Form, Grid, Image} from 'semantic-ui-react';
import {enduserLogin} from '../actions/auth';
import {t} from '../i18n';
import {routes} from '../routes';
import {Topbar} from './Topbar';
import {defaultCompanyLogo} from '../configs';

export function LoginPage() {
  const dispatch = useDispatch();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  return (
    <div className="AuthPageVerticalRoot">
      <Topbar />

      <div className="CenterContainer CenterContainerLoginForm">
        <Image className="AuthPageLogo" src={defaultCompanyLogo} />
        <Grid columns={1}>
          <Grid.Row>
            <Grid.Column>
              <Form>
                <Form.Field>
                  <label>{t('PAGE_LOGIN.LABEL_USERNAME')}</label>
                  <Input
                    size="large"
                    value={username}
                    placeholder={t('PAGE_LOGIN.HINT_USERNAME')}
                    onChange={(changeEvent) => {
                      setUsername(changeEvent.target.value);
                    }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>{t('PAGE_LOGIN.LABEL_PASSWORD')}</label>
                  <Input
                    size="large"
                    value={password}
                    placeholder={t('PAGE_LOGIN.HINT_PASSWORD')}
                    type={'password'}
                    onChange={(changeEvent) => {
                      setPassword(changeEvent.target.value);
                    }}
                  />
                </Form.Field>
                <Button
                  size="large"
                  inverted
                  onClick={() => {
                    dispatch(
                      enduserLogin({
                        username: username,
                        password: password,
                      }),
                    );
                  }}
                  fluid>
                  {t('PAGE_LOGIN.BUTTON_LOGIN')}
                </Button>
              </Form>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row></Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Link to={routes.register()}>
                <Button size="large" inverted fluid>
                  {t('PAGE_LOGIN.BUTTON_REGISTER')}
                </Button>
              </Link>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Link to={routes.forgotPassword()}>
                <Button size="large" inverted fluid>
                  {t('PAGE_LOGIN.BUTTON_FORGOT_PASSWORD')}
                </Button>
              </Link>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
}
