import {TProfileObject} from './dataTypes';
import {
  apiURL,
  baseURL,
  fetchGet,
  fetchPatch,
  fetchPost,
  TApiResult,
} from './baseapi';

export type TProfileResult = TApiResult<TProfileObject>;
export type TDeleteCardResult = TApiResult<{}>;

export async function getProfile(authToken: string) {
  console.log('API, getProfile, request');

  const response: TProfileResult = await fetchGet({
    url: `${baseURL}${apiURL}users/me`,
    token: authToken,
  });

  if (response.error) {
    console.log('API, getProfile, error', response);
  } else {
    console.log('API, getProfile, response', response);
  }

  return response;
}

export async function patchProfile(
  authToken: string,
  newPartialProfile: Partial<TProfileObject>,
) {
  console.log('API, patchProfile, request');

  const response: TProfileResult = await fetchPatch({
    url: `${baseURL}${apiURL}users/me`,
    token: authToken,
    json: newPartialProfile,
  });

  if (response.error) {
    console.log('API, patchProfile, error', response);
  } else {
    console.log('API, patchProfile, response', response);
  }

  return response;
}

export async function doDeleteCard(
  companySlug: string,
  authToken: string,
  cardToken: string,
) {
  console.log('API, doDeleteCard, request');

  const response: TDeleteCardResult = await fetchPost({
    url: `${baseURL}${apiURL}companies/${companySlug}/payform/delete_card`,
    token: authToken,
    json: {token: cardToken},
  });

  if (response.error) {
    console.log('API, doDeleteCard, error', response);
  } else {
    console.log('API, doDeleteCard, response', response);
  }

  return response;
}
