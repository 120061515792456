import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Button, Form, Icon, Input, Modal} from 'semantic-ui-react';

import {
  addEventToCart,
  addProductToCart,
  enduserActivateDiscount,
  refreshCartDiscount,
  removeEventFromCart,
  removeProductFromCart,
  resetDiscount,
} from '../actions/cart';
import {
  TDiscountCodeObject,
  TEventObject,
  TOrder,
  TProductObject,
  TReceiptInputObject,
  TTicketSeriesObject,
} from '../api/dataTypes';
import {format, getCurrentLanguage, t} from '../i18n';
import {useTypedSelector} from '../redux/reduxUtil';
import {getCurrency, getDiscountPrice, getNameObject} from '../util';
import {alert} from '../alert';
import {Topbar} from './Topbar';
import {
  enduserNavigateToPaymentSettingsPage,
  enduserPaymentSettingsPay,
} from '../actions/payment';
import {useLoggedIn} from '../redux/reduxHooks';

export const MAX_CART_AMOUNT = 99;

export type TCartObject = {
  id: string;
  parentId: string;
  price: number;
  count: number;
  articleTitle: string;
  title: string;
  notes: string;
  isTicket: boolean;
  isDiscounted?: boolean;
  errorMsg?: string;
  discountCode?: TDiscountCodeObject;
  discountTicket?: string;
  origPrice?: number;
};

export function CartPage() {
  const dispatch = useDispatch();
  const {cart, events, activeDiscount} = useTypedSelector((state) => ({
    cart: state.cart,
    events: state.events.events,
    activeDiscount: state.discount.activeDiscount,
  }));

  const cartObjects = cartObjectsFromCart({
    cart,
    events,
    products: [],
    supplierProducts: [],
  });

  const loggedIn = useLoggedIn();

  const [discountCodeModalShow, setDiscountCodeModalShow] = useState(false);
  const [discountCodeModalCode, setDiscountCodeModalCode] = useState('');

  const onButtonContinuePayment = () => {
    const receiptInput = receiptInputFromCartObjects(cartObjects);

    const cartTotalCost = totalCostFromCartObjects(cartObjects);

    if (receiptInput) {
      if (cartTotalCost > 0) {
        dispatch(
          enduserNavigateToPaymentSettingsPage({
            receiptInput,
          }),
        );
      } else {
        dispatch(
          enduserPaymentSettingsPay({
            paymentMethod: 'discount100',
            receiptInput: receiptInput,
          }),
        );
      }
    }
  };

  const discountCodeModalOpen = () => {
    setDiscountCodeModalCode('');
    setDiscountCodeModalShow(true);
  };

  const discountCodeModalButtonOk = () => {
    setDiscountCodeModalShow(false);

    dispatch(enduserActivateDiscount({code: discountCodeModalCode}));
  };

  const discountCodeModalButtonCancel = () => {
    setDiscountCodeModalShow(false);
  };

  const discountCodeModalButtonClear = () => {
    dispatch(enduserActivateDiscount({code: ''}));
  };

  const onAmountPlus = (cartObject: TCartObject) => {
    const totalCount = totalNumberOfCartObjects(cart);

    if (totalCount >= MAX_CART_AMOUNT) {
      alert({
        title: t('CART.ERROR_CART_FULL_TITLE'),
        message: t('CART.ERROR_CART_FULL_MESSAGE'),
      });

      return;
    }

    if (cartObject.isTicket) {
      dispatch(
        addEventToCart({
          cart: cart,
          ticketID: cartObject.id,
          ticketPrice: cartObject.price,
        }),
      );
    } else {
      let specifics;

      cart.orderLines.forEach((orderLine) => {
        if (orderLine.specifics && orderLine.product === cartObject.parentId) {
          specifics = orderLine.specifics.find(
            (spec) =>
              spec.articleId === cartObject.id &&
              spec.notes === cartObject.notes,
          );
        }
      });

      if (specifics) {
        dispatch(
          addProductToCart({
            cart: cart,
            productID: cartObject.id,
            specifics: specifics,
          }),
        );
      }
    }

    dispatch(refreshCartDiscount({}));
  };

  const onAmountMinus = (cartObject: TCartObject) => {
    // Handle the amount edit
    if (cartObject.isTicket) {
      dispatch(
        removeEventFromCart({
          cart: cart,
          ticketID: cartObject.id,
        }),
      );
    } else {
      let specIndex;

      cart.orderLines.forEach((orderLine) => {
        if (orderLine.specifics && orderLine.product === cartObject.parentId) {
          specIndex = orderLine.specifics
            .reverse()
            .findIndex(
              (spec) =>
                spec.articleId === cartObject.id &&
                spec.notes === cartObject.notes,
            );
        }
      });

      if (typeof specIndex === 'number' && specIndex >= 0) {
        dispatch(
          removeProductFromCart({
            cart: cart,
            productID: cartObject.id,
            index: specIndex,
          }),
        );
      }
    }

    if (activeDiscount && activeDiscount.code) {
      const totalCount = cart.orderLines.reduce(
        (total, data) => total + (data.count ?? 0),
        0,
      );

      if (totalCount === 1) {
        // if we are removing the last element
        dispatch(resetDiscount({}));
      } else {
        dispatch(refreshCartDiscount({}));
      }
    }
  };

  if (cartObjects.length > 0) {
    const cartTotalCost = totalCostFromCartObjects(cartObjects);
    const cartTotalCostString = getCurrency(cartTotalCost);

    const bottomContinueButtonLabel =
      cartTotalCost > 0
        ? t('CART.BOTTOM_CHOOSE_CARD')
        : t('CART.BOTTOM_BUTTON_DISCOUNT_100');

    return (
      <div className="CartPage">
        <Topbar />

        {activeDiscount && activeDiscount.code && (
          <div className="CartPageTopDiscountContainer">
            <p className="CartPageTopDiscountLabel">
              {format('CART.ACTIVE_DISCOUNT_LABEL', activeDiscount.code)}
            </p>
            <div className="CartPageTopDiscountButtonContainer">
              <Button primary icon onClick={discountCodeModalButtonClear}>
                <Icon name="remove" />
              </Button>
            </div>
          </div>
        )}
        <div className="CartPageMainScroll">
          <div className="CartPageMainScrollContent">
            {cartObjects.map((cartObject) => {
              const keyForElement =
                cartObject.discountCode && cartObject.discountCode._id
                  ? cartObject.discountCode._id +
                    cartObject.id +
                    cartObject.notes
                  : cartObject.id + cartObject.notes;

              return (
                <CartObjectElement
                  key={keyForElement}
                  cartObject={cartObject}
                  onButtonIncrement={() => {
                    onAmountPlus(cartObject);
                  }}
                  onButtonDecrement={() => {
                    onAmountMinus(cartObject);
                  }}
                />
              );
            })}
          </div>
        </div>
        <div className="CartPageMainBottom">
          <p className="CartPageMainBottomTotal">
            {format('CART.BOTTOM_TOTAL', cartTotalCostString)}
          </p>

          {activeDiscount === undefined && loggedIn && (
            <>
              <Button size="big" inverted fluid onClick={discountCodeModalOpen}>
                {t('CART.DISCOUNT_CODE_MODAL_OPEN')}
              </Button>
              <div className="CartPageMainBottomPadding" />
            </>
          )}

          <Button size="big" inverted fluid onClick={onButtonContinuePayment}>
            {bottomContinueButtonLabel}
          </Button>
        </div>
        <Modal
          size={'mini'}
          onClose={discountCodeModalButtonCancel}
          open={discountCodeModalShow}
          className="GappModalBackground">
          <Modal.Header>{t('CART.DISCOUNT_CODE_MODAL_OPEN')}</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                <Input
                  value={discountCodeModalCode}
                  placeholder={t('CART.DISCOUNT_CODE_MODAL_FIELD_CODE_LABEL')}
                  onChange={(changeEvent) => {
                    setDiscountCodeModalCode(changeEvent.target.value);
                  }}
                />
              </Form.Field>
              <Button onClick={discountCodeModalButtonOk} fluid>
                {t('BUTTON_GENERIC.BUTTON_OK')}
              </Button>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={discountCodeModalButtonCancel}>
              {t('BUTTON_GENERIC.BUTTON_CANCEL')}
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  } else {
    return (
      <div className="CartPage">
        <Topbar />
        <div className="CartPageEmptyCartContainer">
          <p className="CartPageEmptyCartMessage">{t('CART.EMPTY_CART')}</p>
        </div>
      </div>
    );
  }
}

export function receiptInputFromCartObjects(
  cartObjects: TCartObject[],
): TReceiptInputObject | undefined {
  const utcOffset = new Date().getTimezoneOffset() * -1;
  const language = getCurrentLanguage();

  const orderLines = cartObjects.map((cartObject: TCartObject) => {
    let discountData;
    const ticketData = cartObject.discountTicket
      ? {discountTicket: cartObject.discountTicket}
      : {};

    if (cartObject.discountCode) {
      discountData = {
        discountCode: cartObject.discountCode.code,
        discountItemCount: cartObject.count,
        ...ticketData,
      };
    } else {
      discountData = {...ticketData};
    }

    if (cartObject.isTicket) {
      return {
        ticketSeries: cartObject.id,
        count: cartObject.count,
        ...discountData,
      };
    }

    return {
      article: cartObject.id,
      count: cartObject.count,
      notes: cartObject.notes.length
        ? [{note: cartObject.notes, amount: cartObject.count}]
        : undefined,
      ...discountData,
    };
  });

  if (orderLines.length > 0) {
    return {
      customer: '',
      utcOffset: utcOffset,
      language: language,
      orderLines: orderLines,
      orderPoint: null,
    };
  }
}

export function cartObjectsFromCart(options: {
  cart: TOrder;
  products: TProductObject[];
  supplierProducts: TProductObject[];
  events: TEventObject[];
}): TCartObject[] {
  const objects: TCartObject[] = [];

  options.cart.orderLines.forEach((line, lineIndex) => {
    if (line.specifics && line.product) {
      const sortedSpecs = [...line.specifics];

      sortedSpecs.sort((a, b) => b.articlePrice - a.articlePrice);

      // Object is product, find the correct product && article
      sortedSpecs.forEach((spec, sindex) => {
        let price = 0;
        let origPrice = 0;
        let discount: boolean = false;
        let foundProduct = options.products.find((product: TProductObject) => {
          if (product.articles) {
            const foundArticle = product.articles.find(
              (article) => article._id === spec.articleId,
            );

            if (
              foundArticle &&
              typeof foundArticle.discountPrice === 'number' &&
              typeof foundArticle.price === 'number'
            ) {
              price = foundArticle.discountPrice;
              origPrice = foundArticle.price;
              discount = foundArticle.discountPrice < foundArticle.price;

              return true;
            }
          }

          return false;
        });

        // If we didn't find the correct prouct, search from supplierproducts
        if (!foundProduct) {
          foundProduct = options.supplierProducts.find(
            (product: TProductObject) => {
              if (product.articles) {
                const foundArticle = product.articles.find(
                  (article) => article._id === spec.articleId,
                );

                if (
                  foundArticle &&
                  typeof foundArticle.discountPrice === 'number' &&
                  typeof foundArticle.price === 'number'
                ) {
                  price = foundArticle.discountPrice;
                  origPrice = foundArticle.price;
                  discount = foundArticle.discountPrice < foundArticle.price;

                  return true;
                }
              }

              return false;
            },
          );
        }

        if (foundProduct) {
          let discountObject: TDiscountCodeObject | undefined;

          if (
            line.appliedCodeDiscount &&
            typeof line.codeDiscountedCount === 'number' &&
            sindex < line.codeDiscountedCount
          ) {
            discountObject = line.appliedCodeDiscount;
          }

          // Check if the product is already added to cart objects
          // and increase the count if it is
          const index = objects.findIndex((obj: TCartObject) => {
            if (discountObject) {
              return (
                obj.id === spec.articleId &&
                obj.notes === spec.notes &&
                obj.discountCode
              );
            }

            return (
              obj.id === spec.articleId &&
              obj.notes === spec.notes &&
              !obj.discountCode
            );
          });

          if (spec.articlePrice !== price) {
            const newSpec = {...spec, articlePrice: price};
            const newSpecifics = [...(line.specifics || [])];

            newSpecifics.splice(sindex, 1, newSpec);
          }

          if (index >= 0) {
            const foundObj = objects[index];
            const newObj = {...foundObj, count: foundObj.count + 1};

            objects.splice(index, 1, newObj);
          } else {
            objects.push({
              id: spec.articleId,
              parentId: foundProduct._id,
              title: getNameObject(foundProduct.names),
              articleTitle: spec.articleName,
              price,
              notes: spec.notes,
              count: 1,
              isTicket: false,
              isDiscounted: discount,
              origPrice,
              errorMsg: '',
              discountCode: discountObject,
              discountTicket: spec.discountTicket,
            });
          }
        }
      });
    } else if (typeof line.ticketSeries === 'string') {
      const id: string = line.ticketSeries;
      // Object is event, find the correct event && ticketseries
      // by the ticketseries id
      let articleTitle: string = '';
      let price: number = 0;
      const foundEvent = options.events.find((event: TEventObject) => {
        if (event.ticketSeries) {
          const foundTicket = event.ticketSeries.find(
            (ticket: TTicketSeriesObject) => ticket._id === line.ticketSeries,
          );

          if (foundTicket) {
            articleTitle = getNameObject(foundTicket.names);
            price =
              typeof foundTicket.ticketPrice === 'number'
                ? foundTicket.ticketPrice
                : 0;

            return true;
          }
        }

        return false;
      });

      let discountObject;
      let discountAmount = 0;
      let {count} = line;

      if (
        line.appliedCodeDiscount &&
        typeof line.codeDiscountedCount === 'number'
      ) {
        discountObject = line.appliedCodeDiscount;
        discountAmount = line.codeDiscountedCount;

        if (typeof count === 'number' && count > discountAmount) {
          count -= discountAmount;
        }
      }

      if (foundEvent) {
        if (
          typeof count === 'number' &&
          typeof line.count === 'number' &&
          count < line.count
        ) {
          objects.push({
            id,
            parentId: foundEvent._id,
            count,
            title: getNameObject(foundEvent.names),
            articleTitle,
            price,
            notes: '',
            isTicket: true,
            isDiscounted: line.isTicketDiscounted,
          });

          objects.push({
            id,
            parentId: foundEvent._id,
            count: discountAmount,
            title: getNameObject(foundEvent.names),
            articleTitle,
            price,
            notes: '',
            isTicket: true,
            discountCode: discountObject,
          });
        } else {
          objects.push({
            id,
            parentId: foundEvent._id,
            count: count || 0,
            title: getNameObject(foundEvent.names),
            articleTitle,
            price,
            notes: '',
            isTicket: true,
            discountCode: discountObject,
            isDiscounted: line.isTicketDiscounted,
          });
        }
      }
    }
  });

  objects.sort((a: TCartObject, b: TCartObject) => {
    const compareA = a.title + a.articleTitle + a.notes;
    const compareB = b.title + b.articleTitle + b.notes;

    return compareA.localeCompare(compareB);
  });

  return objects;
}

function CartObjectElement(props: {
  cartObject: TCartObject;
  onButtonIncrement: () => void;
  onButtonDecrement: () => void;
}) {
  let {price, isDiscounted} = props.cartObject;

  const {count, title, articleTitle, notes, discountCode, origPrice} =
    props.cartObject;

  if (discountCode && discountCode.discountType && discountCode.discount) {
    price = getDiscountPrice(
      price || origPrice || 0,
      discountCode.discountType,
      discountCode.discount,
    );
    isDiscounted = true;
  }

  const perItemPriceString = getCurrency(price / 100);
  const totalPriceString = getCurrency((price * count) / 100);

  const countString = format('CART.ORDERLINE_EVENT_COUNT', count);

  return (
    <div className="CartPageOrderlineTicket">
      <div className="CartPageOrderlineTicketLeft">
        <p
          className={`CartPageOrderlineTicketMoneyLabel ${
            isDiscounted ? 'discounted' : ''
          }`}>
          {perItemPriceString}
        </p>
        <p
          className={`CartPageOrderlineTicketMoneyLabel ${
            isDiscounted ? 'discounted' : ''
          }`}>
          {totalPriceString}
        </p>
        <p className="CartPageOrderlineTicketMoneyLabel">{countString}</p>
      </div>
      <div className="CartPageOrderlineTicketRight">
        <p className="CartPageOrderlineTitleLabel">{title}</p>
        <p className="CartPageOrderlineArticleTitleLabel">{articleTitle}</p>
        {notes && <p className="CartPageOrderlineNotesLabel">{notes}</p>}
        {props.cartObject.discountCode && (
          <p className="CartPageOrderlineDiscountLabel">
            {cartObjectGetDiscountText(
              props.cartObject.discountCode,
              props.cartObject.count,
            )}
          </p>
        )}
      </div>
      <div className="CartPageOrderlineTicketButtons">
        <Button.Group>
          <Button inverted basic primary onClick={props.onButtonDecrement}>
            <Icon name="minus" />
          </Button>
          <Button
            inverted
            basic
            primary
            onClick={props.onButtonIncrement}
            disabled={isDiscounted}>
            <Icon name="plus" />
          </Button>
        </Button.Group>
      </div>
    </div>
  );
}

function cartObjectGetDiscountText(
  discount: TDiscountCodeObject,
  count: number,
) {
  let ending = '';
  let discountAmount = '';

  if (discount.discountType === 'currency') {
    ending = '';

    if (discount.discount) {
      discountAmount = getCurrency((discount.discount / 100) * count);
    }
  } else if (discount.discountType === 'percent') {
    ending = '%';

    if (discount.discount) {
      discountAmount = discount.discount.toString();
    }
  }

  return format('CART.CART_OBJECT_DISCOUNT_LINE', `${discountAmount}${ending}`);
}

export const totalNumberOfCartObjects = (cart: TOrder) => {
  return cart.orderLines.reduce(
    (total, data) => total + (data.count ?? 0),
    0,
  );
}

export function totalCostFromCartObjects(cartObjects: TCartObject[]) {
  return cartObjects.reduce((acc, obj) => {
    if (
      obj.discountCode &&
      obj.discountCode.discountType &&
      obj.discountCode.discount
    ) {
      const t = obj.discountCode.discountType;
      const d = obj.discountCode.discount;

      return acc + (getDiscountPrice(obj.price, t, d) / 100) * obj.count;
    }

    return acc + (obj.price / 100) * obj.count;
  }, 0);
}
