import {TCompanyObject} from '../api/dataTypes';
import {actionCreator} from '../redux/reduxUtil';

export const enduserSetCurrentCompany = actionCreator<{
  currentCompanySlug: string;
  sourceLocation: 'loginProcess' | 'homepageSelector';
}>('company.enduserSetCurrentCompany');

export const setCompanies = actionCreator<{
  companies: TCompanyObject[];
}>('company.setCompanies');

export const setCurrentCompany = actionCreator<{
  currentCompany: TCompanyObject;
  currentCompanySlug: string;
}>('company.setCurrentCompany');
