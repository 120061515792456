import React from 'react';
import {push} from 'connected-react-router';
import {useDispatch} from 'react-redux';
import {Button, Grid} from 'semantic-ui-react';

import {t} from '../i18n';
import {routes} from '../routes';
import {PageWithTabs} from './PageWithTabs';

export function LoginRequiredComplaint(props: {
  specificComplaintMessage?: string;
}) {
  const dispatch = useDispatch();

  const onButtonLogin = () => {
    dispatch(push(routes.login()));
  };

  const onButtonRegister = () => {
    dispatch(push(routes.register()));
  };

  return (
    <div className="LoginRequiredComplaint">
      <div className="CenterContainer">
        <Grid columns={1}>
          <Grid.Row>
            <Grid.Column>
              <p>
                {props.specificComplaintMessage ??
                  t(
                    'ERROR_GENERIC.LOGIN_REQUIRED_COMPONENT_MESSAGE_GENERIC_PAGE',
                  )}
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button inverted primary onClick={onButtonLogin} fluid>
                {t('ERROR_GENERIC.LOGIN_REQUIRED_BUTTON_LOGIN')}
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button inverted primary onClick={onButtonRegister} fluid>
                {t('ERROR_GENERIC.LOGIN_REQUIRED_BUTTON_REGISTER')}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
}

export function PageWithLoginComplaint(props: {
  specificComplaintMessage?: string;
}) {
  return (
    <PageWithTabs>
      <LoginRequiredComplaint
        specificComplaintMessage={
          props.specificComplaintMessage ??
          t('ERROR_GENERIC.LOGIN_REQUIRED_COMPONENT_MESSAGE_GENERIC_PAGE')
        }
      />
    </PageWithTabs>
  );
}
