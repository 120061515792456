import {TDiscountCodeObject, TLineSpecifics, TOrder} from '../api/dataTypes';
import {actionCreator} from '../redux/reduxUtil';

export const clearCart = actionCreator<{}>('cart.clearCart');

export const editCart = actionCreator<{
  order: TOrder;
}>('cart.editCart');

export const addEventToCart = actionCreator<{
  ticketID: string;
  cart: TOrder;
  ticketPrice: number;
  count?: number;
  isTicketDiscounted?: boolean;
}>('cart.addEventToCart');

export const addProductToCart = actionCreator<{
  productID: string;
  cart: TOrder;
  specifics: TLineSpecifics;
  count?: number;
}>('cart.addProductToCart');

export const removeEventFromCart = actionCreator<{
  ticketID: string;
  cart: TOrder;
}>('cart.removeEventFromCart');

export const removeProductFromCart = actionCreator<{
  productID: string;
  cart: TOrder;
  index: number;
}>('cart.removeProductFromCart');

export const discountCart = actionCreator<{
  discount: TDiscountCodeObject;
  cart: TOrder;
}>('cart.discountCart');

export const setDiscount = actionCreator<{discount: TDiscountCodeObject}>(
  'cart.setDiscount',
);

export const resetDiscount = actionCreator<{cart?: TOrder}>(
  'cart.resetDiscount',
);

export const refreshCartDiscount = actionCreator<{}>(
  'cart.refreshCartDiscount',
);

export const enduserActivateDiscount = actionCreator<{code: string}>(
  'cart.enduserActivateDiscount',
);
