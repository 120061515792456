import {fork} from 'redux-saga/effects';
import {watchBasics} from './basics';
import {checkForExistingLogin, watchAuth} from './auth';
import {watchStream} from './stream';
import {watchCart} from './cart';
import {watchRegister} from './register';
import {watchForgotPassword} from './forgotPassword';
import {watchProfile} from './profile';

export function* rootSaga() {
  yield fork(watchBasics);
  yield fork(watchAuth);
  yield fork(watchStream);
  yield fork(watchCart);
  yield fork(watchRegister);
  yield fork(watchForgotPassword);
  yield fork(watchProfile);
  yield fork(checkForExistingLogin);
}
