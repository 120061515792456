export type TAlertModalSizeOption =
  | 'mini'
  | 'tiny'
  | 'small'
  | 'large'
  | 'fullscreen';

export type TPerAlertDataPartial = {
  title?: string;
  message?: string;
  buttons?: TAlertButton[];
  // default = true
  allowCloseByBackgroundClick?: boolean;
  onCloseByBackgroundClick?: () => void;
  modalSize?: TAlertModalSizeOption;
};

export type TPerAlertPromiseDataPartial = {
  title?: string;
  message?: string;
  buttons?: TAlertPromiseButton[];
  // default = true
  allowCloseByBackgroundClick?: boolean;
  modalSize?: TAlertModalSizeOption;
};

type TPerAlertDataFull = {
  title: string;
  message: string;
  buttons: TAlertButton[];
  allowCloseByBackgroundClick: boolean;
  onCloseByBackgroundClick?: () => void;
  modalSize?: TAlertModalSizeOption;
};

type TAlertButton = {
  label: string;
  onClick?: () => void;
};

type TAlertPromiseButton = {
  label: string;
  resultValue: string;
};

export type TAlertUiState = {
  show: boolean;
  alert: TPerAlertDataFull;
  onClose: () => void;
};

export const initialUiState: TAlertUiState = {
  show: false,
  alert: {
    title: '',
    message: '',
    buttons: [],
    allowCloseByBackgroundClick: true,
  },
  onClose: () => {},
};

type TAlertGlobalState = {
  queued_alerts: TPerAlertDataFull[];
  setUiState?: undefined | ((state: TAlertUiState) => void);
  currentUiState: TAlertUiState;
};

const alertGlobalState: TAlertGlobalState = {
  queued_alerts: [],
  currentUiState: initialUiState,
};

function showNextAlert() {
  if (alertGlobalState.queued_alerts.length > 0) {
    const nextAlertContent = alertGlobalState.queued_alerts[0];

    alertGlobalState.queued_alerts.splice(0, 1);

    if (alertGlobalState.setUiState) {
      const newUiState = {
        show: true,
        alert: nextAlertContent,
        onClose: onModalClosed,
      };

      alertGlobalState.currentUiState = newUiState;

      alertGlobalState.setUiState(newUiState);
    }
  }
}

function onModalClosed(usingButton?: boolean) {
  if (alertGlobalState.setUiState) {
    const newUiState = {
      ...alertGlobalState.currentUiState,
      show: false,
    };

    if (
      Boolean(usingButton) === false &&
      alertGlobalState.currentUiState.alert.onCloseByBackgroundClick
    ) {
      alertGlobalState.currentUiState.alert.onCloseByBackgroundClick();
    }

    alertGlobalState.currentUiState = newUiState;

    alertGlobalState.setUiState(newUiState);

    setTimeout(showNextAlert, 500);
  }
}

export function alert(newAlertPartial: TPerAlertDataPartial) {
  const newAlertFull: TPerAlertDataFull = {
    title: newAlertPartial.title || '',
    message: newAlertPartial.message || '',
    buttons:
      newAlertPartial.buttons && newAlertPartial.buttons.length > 0
        ? newAlertPartial.buttons.map((originalButton) => {
            return {
              label: originalButton.label,
              onClick: () => {
                if (originalButton.onClick) {
                  originalButton.onClick();
                }

                onModalClosed(true);
              },
            };
          })
        : [
            {
              label: 'OK',
              onClick: () => {
                onModalClosed(true);
              },
            },
          ],
    allowCloseByBackgroundClick:
      newAlertPartial.allowCloseByBackgroundClick !== undefined
        ? newAlertPartial.allowCloseByBackgroundClick
        : true,
    onCloseByBackgroundClick: newAlertPartial.onCloseByBackgroundClick,
    modalSize: newAlertPartial.modalSize,
  };

  alertGlobalState.queued_alerts.push(newAlertFull);

  if (
    alertGlobalState.currentUiState.show === false &&
    alertGlobalState.queued_alerts.length === 1
  ) {
    showNextAlert();
  }
}

export function setUiComponent(setUiState?: (state: TAlertUiState) => void) {
  alertGlobalState.setUiState = setUiState;
}

// Returns the resultValue of the button pressed.
// Returns undefined if the alert is canceled by background press.
export function alertPromise(
  newAlertPromisePartial: TPerAlertPromiseDataPartial,
): Promise<string | undefined> {
  let alreadyResolved = false;

  return new Promise((resolve, _reject) => {
    alert({
      ...newAlertPromisePartial,
      buttons: (newAlertPromisePartial.buttons || []).map((promiseButton) => {
        return {
          label: promiseButton.label,
          onClick: () => {
            if (!alreadyResolved) {
              alreadyResolved = true;
              resolve(promiseButton.resultValue);
            }
          },
        };
      }),
      onCloseByBackgroundClick: () => {
        if (!alreadyResolved) {
          alreadyResolved = true;
          resolve(undefined);
        }
      },
      modalSize: newAlertPromisePartial.modalSize,
    });
  });
}
