import React, {useState} from 'react';
import {useParams} from 'react-router';
import {push} from 'connected-react-router';
import Moment from 'moment';
import Linkify from 'react-linkify';

import {routes} from '../routes';
import {useTypedSelector} from '../redux/reduxUtil';
import {Button, Icon, Modal} from 'semantic-ui-react';
import {useDispatch} from 'react-redux';
import {getCurrency, getEventURL, getNameObject} from '../util';
import {TEventObject, TTicketSeriesObject} from '../api/dataTypes';
import {t} from '../i18n';
import {addEventToCart} from '../actions/cart';
import {alert} from '../alert';
import {Topbar} from './Topbar';
import {defaultEventImage} from '../configs';
import {LivestreamStartButton} from './LivestreamStartButton';
import {SocialMediaRow, TSocialMediaRowProps} from './SocialMediaRow';
import {totalNumberOfCartObjects, MAX_CART_AMOUNT} from './CartPage';

type TAddToCartModalProps = {
  ticketseries: TTicketSeriesObject;
  productCount: number;
};

export function EventPage() {
  const dispatch = useDispatch();

  const [addToCartModalShow, setAddToCartModalShow] = useState(false);
  const [addToCartModalProps, setAddToCartModalProps] =
    useState<TAddToCartModalProps | undefined>(undefined);
  const [cartFeedbackModalShow, setCartFeedbackModalShow] = useState(false);

  const urlParams = useParams<{event: string}>();

  const eventId = urlParams.event || '';

  const {event, cart} = useTypedSelector((state) => ({
    event: state.events.events.find((event) => event._id === eventId),
    cart: state.cart,
  }));

  const onFeedbackButtonOk = () => {
    dispatch(push(routes.cart()));
  };

  if (event) {
    const eventImageUrl = getEventURL(event.urls);
    const usingDefaultImage = !Boolean(eventImageUrl);
    const imageUrl = usingDefaultImage ? defaultEventImage : eventImageUrl;
    const eventName = getNameObject(event.names);
    const eventDescription = getNameObject(event.descriptions);

    const dateDate = Moment(event.gigStartDate).format('D.M.');
    const dateWeekday = Moment(event.gigStartDate).format('ddd');
    const dateTime = Moment(event.gigStartDate).format('HH:mm');

    const longDescription = getNameObject(event.longDescriptions);

    const socialMediaRowProps = socialMediaRowPropsFromEvent(event);

    const socialMediaFirstOtherUrl = socialMediaRowProps.otherUrls
      ? socialMediaRowProps.otherUrls[0]
      : undefined;

    if (socialMediaFirstOtherUrl && socialMediaRowProps.otherUrls) {
      if (socialMediaRowProps.otherUrls.length > 1) {
        socialMediaRowProps.otherUrls = socialMediaRowProps.otherUrls.slice(1);
      } else {
        socialMediaRowProps.otherUrls = undefined;
      }
    }

    return (
      <div className="EventPage">
        <Topbar />
        <div className="MainContainer">
          <div className="EventPageContainer">
            <div className="EventElement">
              <div
                className={
                  usingDefaultImage
                    ? 'EventElementBannerBorderImageContainer'
                    : 'EventElementBannerImageContainer'
                }>
                <img className="EventElementMainImage" src={imageUrl} alt="" />
              </div>
              <div className="EventElementBottomHorizontal">
                <div className="EventElementBottomLeft">
                  <p className="EventElementBottomLeftDate">{dateDate}</p>
                  <p className="EventElementBottomLeftWeekday">{dateWeekday}</p>
                  <p className="EventElementBottomLeftHour">{dateTime}</p>
                </div>
                <div className="EventElementBottomLeft">
                  <p className="EventElementBottomLongName">{eventName}</p>
                  <p className="EventElementBottomDescription">
                    {eventDescription}
                  </p>
                </div>
              </div>
            </div>

            {event.ticketSeries && event.ticketSeries.length > 0 && (
              <>
                <p className="EventPageTicketseriesContainerTitle">
                  {t('EVENT_PAGE.TICKETSERIES_CONTAINER_TITLE')}
                </p>
                <div className="EventPageTicketseriesContainer">
                  {event.ticketSeries &&
                    event.ticketSeries.map((ticketseries) => {
                      return (
                        <EventPageTicketseriesButton
                          key={ticketseries._id}
                          ticketseries={ticketseries}
                          onClick={() => {
                            setAddToCartModalProps({
                              ticketseries: ticketseries,
                              productCount: 1,
                            });
                            setAddToCartModalShow(true);
                          }}
                        />
                      );
                    })}
                </div>
              </>
            )}

            <LivestreamStartButton event={event} />

            {socialMediaFirstOtherUrl && (
              <SocialMediaRow
                otherUrls={[socialMediaFirstOtherUrl]}
                className="ExtraImportant"
              />
            )}

            <Linkify>
              <p className="EventPageLongDescription">{longDescription}</p>
            </Linkify>

            <SocialMediaRow facebookUrl={socialMediaRowProps.facebookUrl} />
            <SocialMediaRow {...socialMediaRowProps} facebookUrl={undefined} />

            <Modal
              size={'mini'}
              onClose={() => {
                setAddToCartModalShow(false);
              }}
              open={addToCartModalShow}
              className="GappModalBackground">
              <Modal.Header>
                {t('EVENT_PAGE.ADD_TO_CART_MODAL_TITLE')}
              </Modal.Header>
              <Modal.Content>
                {addToCartModalProps && (
                  <EventPageAddToCartModal
                    eventName={eventName}
                    ticketseries={addToCartModalProps.ticketseries}
                    productCount={addToCartModalProps.productCount}
                    setProductCount={(count) => {
                      setAddToCartModalProps({
                        ticketseries: addToCartModalProps.ticketseries,
                        productCount: Math.max(1, count),
                      });
                    }}
                  />
                )}
              </Modal.Content>
              <Modal.Actions>
                <Button
                  onClick={() => {
                    if (addToCartModalProps) {
                      const totalCount = addToCartModalProps.productCount + totalNumberOfCartObjects(cart);

                      setAddToCartModalShow(false);

                      if (totalCount > MAX_CART_AMOUNT) {
                        alert({
                          title: t('CART.ERROR_CART_FULL_TITLE'),
                          message: t('CART.ERROR_CART_FULL_MESSAGE'),
                        });
                      } else {
                        setCartFeedbackModalShow(true);

                        dispatch(
                          addEventToCart({
                            cart: cart,
                            ticketID: addToCartModalProps.ticketseries._id,
                            ticketPrice:
                              addToCartModalProps.ticketseries.ticketPrice ?? 0,
                            count: addToCartModalProps.productCount,
                            isTicketDiscounted: false,
                          }),
                        );
                      }
                    }
                  }}>
                  {t('EVENT_PAGE.ADD_TO_CART_MODAL_BUTTON_OK')}
                </Button>
                <Button
                  onClick={() => {
                    setAddToCartModalShow(false);
                  }}>
                  {t('EVENT_PAGE.ADD_TO_CART_MODAL_BUTTON_CANCEL')}
                </Button>
              </Modal.Actions>
            </Modal>
            <Modal
              size={'mini'}
              onClose={() => {
                setCartFeedbackModalShow(false);
              }}
              open={cartFeedbackModalShow}
              className="GappModalBackground">
              <Modal.Header>
                {t('EVENT_PAGE.CART_FEEDBACK_MODAL_TITLE')}
              </Modal.Header>
              <Modal.Content>
                {addToCartModalProps && (
                  <EventPageAddToCartModal
                    eventName={eventName}
                    ticketseries={addToCartModalProps.ticketseries}
                    productCount={addToCartModalProps.productCount}
                    setProductCount={(count) => {
                      setAddToCartModalProps({
                        ticketseries: addToCartModalProps.ticketseries,
                        productCount: Math.max(1, count),
                      });
                    }}
                    showButtons={cartFeedbackModalShow}
                  />
                )}
              </Modal.Content>
              <Modal.Actions>
                <Button
                  onClick={() => {
                    if (addToCartModalProps) {
                      setCartFeedbackModalShow(false);
                      onFeedbackButtonOk();
                    }
                  }}>
                  {t('EVENT_PAGE.CART_FEEDBACK_MODAL_BUTTON_SHOW_CART')}
                </Button>
                <Button
                  onClick={() => {
                    setCartFeedbackModalShow(false);
                  }}>
                  {t('EVENT_PAGE.CART_FEEDBACK_MODAL_BUTTON_CONTINUE')}
                </Button>
              </Modal.Actions>
            </Modal>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="EventPage">
        <Topbar />
      </div>
    );
  }
}

export function EventPageTicketseriesButton(props: {
  ticketseries: TTicketSeriesObject;
  onClick: () => void;
}) {
  const label = getNameObject(props.ticketseries.names);
  const priceString = getCurrency(
    (props.ticketseries.discountPrice || props.ticketseries.ticketPrice || 0) /
      100,
  );
  const labelFull = `${label}\n${priceString}`;

  return (
    <Button
      basic
      inverted
      primary
      size="big"
      className="TicketButton"
      onClick={props.onClick}>
      {labelFull}
    </Button>
  );
}

export function EventPageAddToCartModal(props: {
  eventName: string;
  ticketseries: TTicketSeriesObject;
  productCount: number;
  setProductCount: (count: number) => void;
  showButtons?: Boolean;
}) {
  const ticketseriesName = getNameObject(props.ticketseries.names);
  const perItemPrice =
    props.ticketseries.discountPrice || props.ticketseries.ticketPrice || 0;
  const priceString = getCurrency((perItemPrice * props.productCount) / 100);

  return (
    <div className="EventPageAddToCartModalContentVertical">
      <div className="EventPageAddToCartModalContentTop">
        <p className="EventPageAddToCartModalContentLabel">{priceString}</p>
        <p className="EventPageAddToCartModalContentLabel">{props.eventName}</p>
      </div>

      <div className="EventPageAddToCartModalDivider" />

      <div className="EventPageAddToCartModalContentBottom">
        <p className="EventPageAddToCartModalContentLabel">
          {`${ticketseriesName} x ${props.productCount}`}
        </p>
        {!props.showButtons &&
          <Button.Group>
            <Button
              onClick={() => {
                props.setProductCount(props.productCount - 1);
              }}>
              <Icon name="minus" />
            </Button>
            <Button
              onClick={() => {
                props.setProductCount(props.productCount + 1);
              }}>
              <Icon name="plus" />
            </Button>
          </Button.Group>
        }
      </div>
    </div>
  );
}

function socialMediaRowPropsFromEvent(
  event: TEventObject,
): TSocialMediaRowProps {
  const result: TSocialMediaRowProps = {};

  if (event.urls) {
    for (const url of event.urls) {
      if (url.name) {
        const name = url.name.toLowerCase();

        switch (name) {
          case 'facebook':
            /*
              If we want to open in App, we need to provide pageID
              For iOS: fb://profile/PAGEID
              For Android: fb://page/PAGEID
            */
            result.facebookUrl = url.url;
            break;
          case 'instagram':
            result.instagramUrl = url.url;
            break;
          case 'spotify':
            result.spotifyUrl = url.url;
            break;
          case 'web':
          case 'website':
          case 'homepage':
            result.homepageUrl = url.url;
            break;
          case 'youtube':
            result.youtubeUrl = url.url;
            break;
          case 'twitter':
            result.twitterUrl = url.url;
            break;
          case 'eventurl':
            // ignore eventurl
            break;
          default:
            if (url) {
              if (!result.otherUrls) {
                result.otherUrls = [];
              }

              result.otherUrls.push(url);
            }

            break;
        }
      }
    }
  }

  return result;
}
